export const noop = () => {};
export function on(obj, ...args) {
    if (obj && obj.addEventListener) {
        obj.addEventListener(...args);
    }
}
export function off(obj, ...args) {
    if (obj && obj.removeEventListener) {
        obj.removeEventListener(...args);
    }
}
export const isBrowser = typeof window !== 'undefined';
export const isNavigator = typeof navigator !== 'undefined';

export const errRedirectPage= (err)=>{ 
    console.error(err);
    if(process.env.NODE_ENV!=='development'){
         window.location.assign(`https://common.hkjc.com/maint/generic.html`);
    }else{
        return ;
    }
}

export const obj2cookie = (obj) => JSON.stringify(obj).replace(/\"/gi, '');
export const cookie2obj = (cookie) => JSON.parse(cookie?.replace(/([\w\-]+):/gi, '"$1":'));

export const checkIsSameDay = (date1, date2) => {
    if (!date1 || !date2) {
        return false;
    }

    return (
        (date1.getFullYear() === date2.getFullYear()) &&
        (date1.getMonth() === date2.getMonth()) &&
        (date1.getDate() === date2.getDate())
    );
};