import { isDimBetLine, getBetLineCombSt, isRefundOrEliminated } from '../useBetSlipPush/common';
import moment from 'moment';

export const setMatchQueryDataToBetSlip = (results, betBasketsRef, paras) =>{
    results?.data?.matches.forEach(match =>{
        betBasketsRef.current.forEach(curBasket =>{
            curBasket.forEach(item =>{
                if(item?.queryIds?.includes(match.id)){
                    if(item.betObj.betType == 'ALUP'){
                        updateAllUpMatch(item, match, paras)
                    }else{
                        updateSingleMatch(item, match, paras)
                    }
                }
            })
        })
    })
}

const updateSingleMatch = (betLine, match, paras) => {
    const oldCombObj = {...betLine.betObj.combs[0]}
    const oldPool = {...oldCombObj.currentPool}
    const newPool = match.foPools?.find(pool => pool.id == oldCombObj.poolId) 
    const newLine = newPool?.lines?.find(l => l.lineId == oldCombObj.lineId) || {}
    const newCombination = newLine?.combinations?.find(c => c.combId == betLine.betObj.combs[0].combId) || {}

    const isNormalStatus = newLine.status == "AVAILABLE" && ["AVAILABLE", 'UNAVAILABLE'].includes(newCombination.status)
    
    if(['TQL','CHP'].includes(oldCombObj.betType) && !newPool){
        return
    }
    if(!window.fbConfig.InplayPools.includes(oldCombObj.betType) && paras?.inplayOnly){
        return
    }
    const isInteractiveServiceAvailable = ['TQL','CHP'].includes(oldCombObj.betType) ? betLine.betObj.tournInfo.isInteractiveServiceAvailable : match.isInteractiveServiceAvailable 

    let sellStatus= getBetLineCombSt({pool: newPool, comb: newCombination, isNormalStatus, isInteractiveServiceAvailable});

    let selectionSt = isRefundOrEliminated(sellStatus, newCombination, newPool?.status)

    const isSameOdds = !newCombination?.currentOdds || (newCombination?.currentOdds == oldCombObj.oddsVal)
    let oddsUpdTs =  moment()
    betLine.betObj.combs.forEach(item =>{
        let oldSellStatus = item.sellStatus
        const isPreEvent = !betLine.betObj.matchInfo || betLine.betObj.matchInfo?.status == "PREEVENT";
        const hideOddsChanged = isDimBetLine(oldSellStatus) && !(oldSellStatus == 'SUSPENDED' && isPreEvent)
        item.currentPool = newPool
        item.showDash = isDimBetLine(sellStatus) && !(sellStatus == 'SUSPENDED' && isPreEvent)
        item.oldOdds = isSameOdds ? item.oldOdds :  oldCombObj.oddsVal
        item.oddsVal = isSameOdds ? item.oddsVal :  newCombination?.currentOdds
        item.oddsUpdTs = hideOddsChanged ? null :  isSameOdds ? item.oddsUpdTs : oddsUpdTs
        item.sellStatus =  selectionSt || sellStatus
        item.isStopSell = isDimBetLine(sellStatus)
    })
    betLine.betObj.sellStatus = sellStatus
    betLine.betObj.isStopSell = isDimBetLine(sellStatus)
    betLine.betObj.matchInfo = match
    betLine.betObj.inplayDelay = match.inplayDelay
}

const updateAllUpMatch = (betLine, match, paras) => {
    let betLineSellStatus = 'SELLING'
    let inplayDelay = false
    betLine.betObj.betList.forEach(betItem =>{
        if(betItem.matchId === match.id && (window.fbConfig.InplayPools.includes(betItem.betType) || !paras?.inplayOnly)){
            betItem.combs.forEach(item => {
              const newPool = match.foPools.find(pool => pool.id == item.poolId)
              const newLine = newPool?.lines?.find(l => l.lineId ==  item.lineId) || {}
                const newCombination = newLine?.combinations?.find(c => c.combId == item.combId) || {}
                const isSameOdds = !newCombination?.currentOdds || (newCombination?.currentOdds == item.oddsVal)
                const isNormalStatus =   newLine.status == "AVAILABLE" && ["AVAILABLE", 'UNAVAILABLE'].includes(newCombination.status)
                const isInteractiveServiceAvailable = match.isInteractiveServiceAvailable 
                let sellStatus= getBetLineCombSt({pool: newPool, comb: newCombination, isNormalStatus, isInteractiveServiceAvailable});
                let selectionSt = isRefundOrEliminated(sellStatus, newCombination, newPool.status)
                
                let oddsUpdTs =  moment()
                let oldSellStatus = item.sellStatus
                const hideOddsChanged = isDimBetLine(oldSellStatus) && !(oldSellStatus == 'SUSPENDED' && betItem.matchInfo.status == "PREEVENT")
                if(sellStatus != 'SELLING' ) betLineSellStatus = betLineSellStatus == 'CLOSED' ? betLineSellStatus : sellStatus
                item.currentPool = newPool
                item.showDash = isDimBetLine(sellStatus) && !(sellStatus == 'SUSPENDED' && betItem.matchInfo.status == "PREEVENT")
                item.oldOdds = isSameOdds ? item.oldOdds :  item.oddsVal
                item.oddsVal = isSameOdds ? item.oddsVal :  newCombination.currentOdds
                item.oddsUpdTs = hideOddsChanged ? null :  isSameOdds ? item.oddsUpdTs : oddsUpdTs
                item.sellStatus = selectionSt || sellStatus
                item.isStopSell = isDimBetLine(sellStatus)
            })
        
            betItem.matchInfo = match
            betItem.inplayDelay = match.inplayDelay
        }else{
            betItem.combs.forEach(item =>{
                const newLine = item?.currentPool?.lines.find(l => l.lineId ==  item.lineId) || {}
                const newCombination = newLine?.combinations?.find(c => c.combId == item.combId) || {}
                const isNormalStatus = newLine?.status == "AVAILABLE" && ["AVAILABLE", 'UNAVAILABLE'].includes(newCombination.status)
                const isInteractiveServiceAvailable =betItem.matchInfo.isInteractiveServiceAvailable
                let sellStatus=getBetLineCombSt({pool: item.currentPool, comb: newCombination, isNormalStatus, isInteractiveServiceAvailable});
                if(sellStatus != 'SELLING' ) betLineSellStatus = betLineSellStatus == 'CLOSED' ? betLineSellStatus : sellStatus
                let selectionSt = isRefundOrEliminated(sellStatus, newCombination, item?.currentPool?.status)
                item.sellStatus = selectionSt || sellStatus
                item.isStopSell = isDimBetLine(sellStatus)
            })
        }
        inplayDelay = inplayDelay || betItem.inplayDelay
    })
    betLine.betObj.inplayDelay = inplayDelay
    betLine.betObj.sellStatus = betLineSellStatus
    betLine.betObj.isStopSell = isDimBetLine(betLineSellStatus)
}

export const setTournQueryDataToBetSlip = (results, betBasketsRef, tournIds) =>{
    tournIds?.forEach(tournId =>{
        const tournament = results?.data?.tournaments?.find(i => i.id == tournId)
        betBasketsRef.current.forEach(curBasket =>{
            curBasket.forEach(item =>{
                if(item?.queryIds?.includes(tournId)){
                    const isAllup =  item.betObj.betType === 'ALUP'
                    if(isAllup){
                        updateTournData(item.betObj.betList[0], tournament,item)
                    }else{
                        updateTournData(item.betObj, tournament, item)
                    }
                }
            })
        })
    })
}

const updateTournData = (betObj, tournament, betLine) =>{
    let betLineSellStatus = 'SELLING'
    betObj?.combs?.forEach(item =>{
        const oldCombObj = {...item}
        let newPool = tournament?.foPools?.find(pool => pool.id === item.poolId)
        let newLine = newPool?.lines?.find(line => line.lineId === item.lineId) || {}
        let newCombination = newLine?.combinations?.find(comb => comb.combId === item.combId) || {}

        const isNormalStatus = newLine.status == "AVAILABLE" && ["AVAILABLE", 'UNAVAILABLE'].includes(newCombination.status)
        const isInteractiveServiceAvailable =  tournament?.isInteractiveServiceAvailable

        let sellStatus= getBetLineCombSt({pool: newPool, comb: newCombination, isNormalStatus, isInteractiveServiceAvailable});

        if(['TQL','CHP'].includes(oldCombObj.betType) && newPool ){
            betLine.betObj.matchInfo = newPool?.match ?
                betLine?.betObj?.matchInfo ? {...betLine.betObj.matchInfo,  ...newPool.match} : newPool.match 
            : null; // tag / untag
        }

        let selectionSt = isRefundOrEliminated(sellStatus, newCombination, newPool?.status)
        if(sellStatus != 'SELLING' ) betLineSellStatus = betLineSellStatus == 'CLOSED' ? betLineSellStatus : sellStatus

        const isSameOdds = !newCombination?.currentOdds || (newCombination?.currentOdds == item.oddsVal)
        let oddsUpdTs =  moment()
        let oldSellStatus = item.sellStatus
        const isPreEvent = !betLine.betObj.matchInfo || betLine.betObj.matchInfo?.status == "PREEVENT";
        const hideOddsChanged = isDimBetLine(oldSellStatus) && !(oldSellStatus == 'SUSPENDED' && isPreEvent)
        item.currentPool = newPool || item.currentPool 
        item.showDash = isDimBetLine(sellStatus) && !(sellStatus == 'SUSPENDED' && isPreEvent)
        item.oldOdds = isSameOdds ? item.oldOdds :  oldCombObj.oddsVal
        item.oddsVal = isSameOdds ? item.oddsVal :  newCombination?.currentOdds
        item.oddsUpdTs = hideOddsChanged ? null :  isSameOdds ? item.oddsUpdTs : oddsUpdTs
        item.sellStatus =  selectionSt || sellStatus
        item.isStopSell = isDimBetLine(sellStatus)
    })
    betObj.tournInfo = tournament || betObj.tournInfo

    betLine.betObj.sellStatus = betLineSellStatus
    betLine.betObj.isStopSell = isDimBetLine(betLineSellStatus)
}