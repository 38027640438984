import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isTournamentPage, isFeaturedMatch, getQueryDateRange,  setTournamentPageTabs,
    isPmPoolResultPage } from '../../Football/Common/Common';
import useGetMatches from '../../Football/Data/useGetMatches';
import { updateMixAllUpOFMMatches } from '../../Football/Data/useGetMatchList/common';
import { dateAdd, formatDateObj, scrollTo } from '../../Common/GlobalFunc';
import { getDisplayMatches } from '../../Football/Data/useGetMatchList/common';
import { useShowAlertDialog } from '../../Common/CommonHooks';

export const useFootballPageFunc = (contentRef, setContent) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { loadMatchQuery, loadMatchResultQuery } = useGetMatches(contentRef, setContent);
    const { getAlertShowMode } = useShowAlertDialog();

    const handleSelectionChange = (_type, _val, isOther) => {
        let allOddsPage = contentRef.current.config.AllOddsPages.includes(contentRef.current.page);
        if (_type != contentRef.current.selection.type || _val != contentRef.current.selection.value || contentRef.current.selection.isAllupDetailBack) {
            switch (_type) {
                case 'SEARCH':
                    if ( contentRef.current.config.MixAllUpDetailPages.includes(contentRef.current.page)) {
                        contentRef.current.page = 'MIXALLUP';
                        contentRef.current.pageConfig = window.fbPageConfig[contentRef.current.page];
                        contentRef.current.isAllUpDtls = false;
                        contentRef.current.isLoading = true;
                        if(contentRef.current.selection.value.length > 0 && contentRef.current.selection.value[0] != 'OTHER' ){
                            loadMatchQuery({ page: contentRef.current.page, matchIds: contentRef.current.selection.value })
                        }else{
                            loadMatchQuery({ page: contentRef.current.page, startIndex: 1, endIndex: contentRef.current.config.MaxPaginationLimit })
                        }
                        if(contentRef.current.selection.isAllupDetailBack){
                            contentRef.current.selection.value = _val ? _val : [];
                            contentRef.current.selection.isAllupDetailBack = false;
                            setContent({...contentRef.current})
                        }
                        return
                    }
                    contentRef.current.selection.type = _type;
                    contentRef.current.selection.value = _val ? _val : contentRef.current.selection.value;
                    setContent({ ...contentRef.current });
                    break
                case 'DATE':
                    let dateFrom = _val[0];
                    let dateTo = dateAdd(dateFrom, 1);
                    contentRef.current.selection.type = _type;
                    contentRef.current.selection.value = [dateFrom];
                    contentRef.current.isLoading = true;
                    contentRef.current.pageNum = 1;
                    if (allOddsPage) {
                        let m = contentRef.current.listData.matches.filter(x => x.matchDate.substring(0, 10) === _val[0])[0];
                        let url = `/${i18n.language}/football/${contentRef.current.page.toLowerCase()}/${m.id}`;
                        navigate(url, { state: { matchId: m.id }, replace: true });
                        contentRef.current.isLoading = false;
                        loadMatchQuery({ page: contentRef.current.page, matchIds: [m.id] });
                    }
                    else if ( contentRef.current.config.MixAllUpDetailPages.includes(contentRef.current.page)) {
                        contentRef.current.isLoading = true;
                        contentRef.current.page = 'MIXALLUP';
                        contentRef.current.pageConfig = window.fbPageConfig[contentRef.current.page];
                        contentRef.current.isAllUpDtls = false;
                        [dateFrom, dateTo] = getQueryDateRange(contentRef.current.listData.matches, contentRef.current.offset, contentRef.current);
                        loadMatchQuery({ page: contentRef.current.page, dateFrom: dateFrom, dateTo: dateTo });
                    }
                    else {
                        dateTo = isOther ?
                            contentRef.current.selection.tabs[contentRef.current.selection.tabs.length - 1].date :
                            dateAdd(dateFrom, 1);
                        loadMatchQuery({ page: contentRef.current.page, dateFrom: dateFrom, dateTo: dateTo });
                    }
                    let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
                    contentRef.current.listData = { matches : matchList };
                    setContent({ ...contentRef.current });
                    break;
                case 'TOURN':
                    let isDifferentType = contentRef.current.selection.type !== _type;
                    let tournAll = contentRef.current.selection.tabs.every(i => i.tournIds.some(item => _val.includes(item)))
                    contentRef.current.selection.type = _type;
                    contentRef.current.selection.tournAll = tournAll;
                    contentRef.current.selection.value = [..._val];
                    contentRef.current.pageNum = 1;

                    if (isTournamentPage(contentRef.current.page)) {
                        let selectedFrist = !_val?.[0]
                        setTournamentPageTabs(contentRef.current.tournData.tournaments, contentRef.current, selectedFrist)
                        setContent({ ...contentRef.current });
                        break;
                    }

                    contentRef.current.isLoading = true;

                    if (allOddsPage) {
                        if(!contentRef.current.selection.value.includes(contentRef.current?.matchData?.matches?.[0]?.tournament.id)){
                            let m = contentRef.current.listData.matches.filter(x => _val.includes(x.tournament.id))[0];
                            let url = `/${i18n.language}/football/${contentRef.current.page.toLowerCase()}/${m.id}`;
                            navigate(url, { state: { matchId: m.id }, replace: true });
                            loadMatchQuery({ page: contentRef.current.page, matchIds: [m.id] });
                        }else{
                            contentRef.current.isLoading = false
                        }
                        setContent({ ...contentRef.current });
                    }
                    else if (contentRef.current.config.MixAllUpPages.includes(contentRef.current.page)
                        || contentRef.current.config.MixAllUpDetailPages.includes(contentRef.current.page)) {
                        if ( isDifferentType ) {
                            let selectedTournIds = getMixAllUpSelectedTournIds();
                            if ( selectedTournIds.length > 0 ) {
                                contentRef.current.selection.value = selectedTournIds;
                            }
                        }
                        contentRef.current.matchData = { matches: contentRef.current.listData.matches.filter(x => contentRef.current.selection.value.includes(x.tournament.id)) };
                        contentRef.current.isLoading = false;
                        contentRef.current.page = 'MIXALLUP';
                        contentRef.current.pageConfig = window.fbPageConfig[contentRef.current.page];
                        contentRef.current.isAllUpDtls = false;
                        contentRef.current.update = Math.random();
                        let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
                        contentRef.current.listData = { matches : matchList };
                        setContent({ ...contentRef.current });
                    }
                    else {
                        // if(openTournIdsCache){

                        //     tournIdsCacheRef.current = [..._val]
                        // }else{
                        //    setOpenTournIdsCache(true)
                        loadMatchQuery({ page: contentRef.current.page, tournIds: _val });
                        let matchList = getDisplayMatches(contentRef.current.rawMatchList, contentRef.current.page, contentRef.current.offset);
                        contentRef.current.listData = { matches : matchList };
                        setContent({ ...contentRef.current });
                        //}
                    }
                    break;
                case 'ALL':
                    contentRef.current.selection.type = _type;
                    contentRef.current.selection.value = [];
                    contentRef.current.pageNum = 1;
                    contentRef.current.isLoading = true;
                    if (isTournamentPage(contentRef.current.page)) {
                        contentRef.current.isLoading = false
                        setTournamentPageTabs(contentRef.current.tournData.tournaments, contentRef.current)
                        setContent({ ...contentRef.current });
                        break;
                    } else if (contentRef.current.config.MixAllUpPages.includes(contentRef.current.page)|| contentRef.current.config.MixAllUpDetailPages.includes(contentRef.current.page)) {

                        contentRef.current.matchData = { matches: contentRef.current.listData.matches };
                        contentRef.current.isLoading = false;
                        contentRef.current.page = 'MIXALLUP';
                        contentRef.current.pageConfig = window.fbPageConfig[contentRef.current.page];
                        contentRef.current.isAllUpDtls = false;
                        contentRef.current.update = Math.random();
                        setContent({ ...contentRef.current });
                        break
                    }

                    loadMatchQuery({ page: contentRef.current.page, startIndex: 1, endIndex: contentRef.current.config?.MaxPaginationLimit });
                    break;
                case 'OFM': // For MixAllUp Only
                    updateMixAllUpOFMMatches(contentRef, setContent)
                    break;
            }
        }
    }

    const handleAllupNextBtnClick = () => {
        let noOfSelection = contentRef.current.oddsSelection.filter(x => x.checked).length
        if (noOfSelection < 2) {
            getAlertShowMode(t('LB_FB_INSUFFICIENT_SELECTIONS'))
        } else if (noOfSelection > 8) {
            getAlertShowMode(t('LB_FB_MIX_MAX_SELECTION_ALT'))
        } else {
            let allupSelectionList = contentRef.current.oddsSelection.filter(x => x.checked == true).map((v) => {
                return v.id.split("_")[1];
            })

            contentRef.current.allupSelection = allupSelectionList;
            contentRef.current.isAllUpDtls = true;
            contentRef.current.isLoading = true;
            contentRef.current.page = 'MIXALLUP_DETAIL';
            contentRef.current.pageNum = 1
            contentRef.current.pageConfig = window.fbPageConfig[contentRef.current.page];
            loadMatchQuery({ page: contentRef.current.page, matchIds: contentRef.current.allupSelection });
        }
    }

    const handleOnClickSearchResult = (startDt, endDt, type, teamId) => {
        contentRef.current.isSearching = true;
        scrollTo(0, 0);
        setContent({...contentRef.current});
        if ( isPmPoolResultPage(contentRef.current.page) ) {
            let pg = contentRef.current.page==="DHCRES" ? "DHC" : "HFM";
            if ( type==="LAST10" ) {
            //    pmResultsData.refetch({ startDate: null, endDate: null, oddsTypes: [pg]});
            }
            else  {
            //    pmResultsData.refetch({ startDate: formatDateObj(startDt)[0], endDate: formatDateObj(endDt)[0], oddsTypes: [pg]});
            }
        }
        else {
            const pageLimit = contentRef.current.config.MaxSearchResultPaginationLimit;
            //console.log(teamId)
            loadMatchResultQuery({ startDate: formatDateObj(startDt)[0], endDate: formatDateObj(endDt)[0], startIndex: 1 , endIndex:  pageLimit, teamId})
        }
    }

    const getMixAllUpSelectedTournIds = () => {
        let tournIds = [];
        contentRef.current.oddsSelection.forEach(x=> {
            let id = x.id.replace('MIXALLUP_', '');
            let match = contentRef.current.listData.matches.filter(y=> y.id===id)[0];
            match?.tournament?.id && tournIds.push(match.tournament.id);
        });
        return [...new Set(tournIds)]
    }

    return  {
        handleSelectionChange,
        handleAllupNextBtnClick,
        handleOnClickSearchResult
    };
}