import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import CashOutFootball from '../../Home/CashOut/CashOutFootball';
import CashOutImportantNotice from '../../Home/CashOut/CashOutImportantNotice'
import { CashOutMsgDialog, CashOutAllBtnDialog } from '../../Home/CashOut/CashOutBase';
import { useWindowSize } from '../../Common/CommonHooks';
import { ConfigContext } from '../../Common/ConfigHelper';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { isLogonHigh } from '../../BetSlip/LoginHooks';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const CashOutTable = ({ match }) => {

    const context = useContext(FootballContext);
    const { betslipMenu } = useContext(ConfigContext)
    const globalContext = useContext(GlobalContext);
    const { setCashoutInProcess } = globalContext;
    const { t } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const [expand, setExpand] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [showAllBtnDialog, setShowAllBtnDialog] = useState(false)
    const cashoutData = globalContext.esHook.getEsData()?.filter(x=> x.match.id===match.id) || [];
    const esValues = globalContext.esHook.esValues || [];
    const CashOutLoadingSeconds = betslipMenu?.CashOutLoadingSeconds
    const [startLoadTime, setStartLoadtime] = useState('')
    const timer = useRef(null);

    useEffect(() => {
        if (startLoadTime) {
            setCashoutInProcess(true);
            disDomFn('add')
            setIsLoading(true)
            timer.current !== null && clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setIsLoading(false)
                setStartLoadtime('')
                setCashoutInProcess(false);
                disDomFn('minus')
            }, CashOutLoadingSeconds * 1000);
        }
        return () => {
            timer.current !== null && clearTimeout(timer.current);
        }
    }, [startLoadTime]);

    const disDomFn = (type) => {
        const mobileDisDomArr = ['.fMenuTR-mobile', '.important-notice', '.date-competition-selection', '.match-dropdown-container']
        const desktopDisDomArr = ['.betslip-menu', '.logout', '.leftMenuMain', '.fMenuTR', '.topInfoMenu', '.important-notice', '.topMenu', '.footer', '.date-competition-selection', '.match-dropdown-container', '.refresh-icon']
        const disDomArr = isMobileSize ? mobileDisDomArr : desktopDisDomArr;
        for (let i = 0; i < disDomArr.length; i++) {
            toggleClassN(document.querySelector(`${disDomArr[i]}`), type)
        }
    }

    const toggleClassN = (ele, type) => {
        if (!ele) { return console.error('error', ele); }
        const className = `cashoutInProcess`
        let classString = ele.className
        const nameIndex = classString.indexOf(className)
        if (nameIndex < 0 && type == 'add') {
            classString += ' ' + className
        } else if (nameIndex >= 0 && type == 'minus') {
            classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
        }
        ele.className = classString
    }

    const recalcLoadingTime = () => {
        const dateNow = Date.now();
        setStartLoadtime(dateNow)
    }

    const handleClick = () => {
        setExpand(!expand);
    }

    const handleClickCashOutAll = () => {
        setShowAllBtnDialog(true)
        glassboxCustomEvent("Cash_Out_All")
    }

    const onConfirmDialog = () => {
        setShowAllBtnDialog(false)
        glassboxCustomEvent("Cash_Out_All_OK")
    }

    const onCancelDialog = () => {
        setShowAllBtnDialog(false)
        glassboxCustomEvent("Cash_Out_All_Cancel")
    }

    const todoNumFn = () => {
        return cashoutData.length;
    }

    const Header = () => {
        return !isMobileSize ? <div className='cashOutHead'>
            <div className='left' onClick={handleClick}>
                <div className={`couponBtn`} >
                    <ArrowIcon id='cashout-btnExpand' direction='down' change={expand} changeDirection='up' size={21} />
                </div>
                <span>{t('LB_CASHOUT')}</span>
                <div className='cashOutHeadIcon'>
                    {todoNumFn() > 0 ?
                        <div className='cashOutHeadNum'>{todoNumFn()}</div>
                        : null}
                </div>
            </div>
        </div> : <div onClick={handleClick} className={`resultsTitlemobile  ${expand ? 'MIPOpen' : 'MIPClose'}`}>
            <div className='df'>
                <div className='cashOutHeadIcon'>
                    {todoNumFn() > 0 ?
                        <div className='cashOutHeadNum'>{todoNumFn()}</div>
                        : null}
                </div>
                <span>{t('LB_CASHOUT')}</span>
            </div>
            <div className='m-arrow-area'><ArrowIcon  className='hueRotate' direction='down' change={expand} changeDirection='up' size={20} /></div>
        </div>;
    }

    const props = { isAllOddsPage: true,
        matches: context.content.matchData.matches,
        esStates: globalContext.esHook.esStates || {},
        esSettleds: globalContext.esHook.esSettleds || [],
        recalcLoadingTime }

    return isLogonHigh() && cashoutData.length > 0 ? <div className="cashOutAllOdds">
        <Header />
        {expand ? <>
            <CashOutImportantNotice handleClickCashOutAll={handleClickCashOutAll} isLoading={isLoading} tabShowData={cashoutData} />
            <div className='cashOutMain'>
                {cashoutData.map(item => {
                    let esValue = esValues.filter(x=> x.betId===item.uniqueTicketId)[0];
                    return <CashOutFootball {...props} data={item} esValue={esValue} key={`${item.accountTranNo}`} />
                })}
            </div>
        </> : null}
        <CashOutMsgDialog />
        <CashOutAllBtnDialog showAllBtnDialog={showAllBtnDialog} onCancelDialog={onCancelDialog} onConfirmDialog={onConfirmDialog} />
    </div> : null;
}
export default CashOutTable;