import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect,useState } from 'react';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import AddSlipBtn from './AddSlipBtn';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: "3px",
        backgroundColor: "#D8D8D8"
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        autoHeight
        autoHeightMin={0}
        autoHeightMax={550}
        {...props}
    />
);

const TTQDropdownBtn=(props)=>{
    const { t } = useTranslation();
    const {title,items,onClickFunc,curSel}=props;
    const curVal=items.find((x,index) => index == curSel).val;
    return <>
        <DropdownButton
            as={ButtonGroup}
            key={`jkcAllup`}
            id={`jkcAllup`}
            variant="Formulas"
            className="dropdown-allup-formulas jkcAllup rc-tt-dropdown"
            title={<><span title={curVal}>{curVal}</span><ArrowIcon direction='down' size={24}/></>}
            align="end"
            disabled={items.length <= 0}
        >
            <Dropdown.Item className='rc-jtc-dropdown-header' eventKey="jkcAllupsub-header" value={t(props.title)} key="jkcAllupsub-header" >{props.title}</Dropdown.Item>
                <CustomScrollbars >
                    <div className="rc-jtc-dropdown-body">
                        {items.map((_cItem, _cIndex) => {
                            return <Dropdown.Item value={_cIndex} className="dropdown-item rc-jtc-dropdown-item" onClick={onClickFunc}>{_cItem.val}</Dropdown.Item>
                        })}
                    </div>
                </CustomScrollbars>
            </DropdownButton>
        </>
}

const TTQuickPick = (props) => {
    const { t, i18n } = useTranslation();
    const [ttqpSel, setTTQPSel] = useState(0);

    let ttqpMenuItem = [{ val: t('LB_RC_ODDS_TTRAN1'), chance: 2 },
    { val: t('LB_RC_ODDS_TTRAN2'), chance: 64 },
    { val: t('LB_RC_ODDS_TTRAN3'), chance: 27 },
    { val: t('LB_RC_ODDS_TTRAN4'), chance: 4 }];

    const handleClickTTQPMenu = (event) => {
        let value = event.target.getAttribute("value");
        setTTQPSel(value);
    }

    function TTQPDropdown(props) {
        return (props.show ?
            <div className="rc-tt-dropdown-menu">
                <div className="rc-tt-dropdown-header">{props.title}</div>
                <CustomScrollbars >
                    <div className="rc-tt-dropdown-body">
                        {ttqpMenuItem.map((_cItem, _cIndex) => {
                            return <a value={_cIndex} className="dropdown-item rc-tt-dropdown-item" onClick={props.onClickFunc}>{_cItem.val}</a>;
                        })}
                    </div>
                </CustomScrollbars>
            </div> : null);
    }

  return (
    <div className={`radio-row${props.mobile}`}>
        <div className="method-title">{t('LB_RC_ODDS_TTQP_COMB')}</div>
        <div style={{ position:"relative" }}>
            <TTQDropdownBtn title={t('LB_RC_ODDS_TTQP')} items={ttqpMenuItem} onClickFunc={(e) => { handleClickTTQPMenu(e ) }} curSel={ttqpSel} />
        </div>
        <div className="ttqp-row">{ttqpMenuItem[ttqpSel].chance} {t('LB_RC_ODDS_CHANCE')}</div>
        <div className="ttqp-btn" ><label htmlFor="ttqp">{t('LB_RC_ODDS_TTQP')}</label></div>
        <div className='ttqp-button cta_hr' onClick={(e) => { props.handleRandomTTQP(ttqpSel) }}><div id="ttqp" className="ttqp-icon" ></div></div>
        <div className="separator"></div>
        <div className="ttqp-addslip"><AddSlipBtn /></div>
    </div>);
}
export default TTQuickPick