import { ClearBetRaces, ClearBetByBetTypeAndRaces } from "../../Common/RacingBetline";
import { isStartSellpmPool } from "../../Common/Common";
export const parseSelSta = ( content, message, topicInfo) => {
    // also need to cater Top, Banker case
    let pmPools = content.meeting.pmPools.filter(x => x.oddsType.indexOf(topicInfo.oddsType)>=0 && x.leg.races[0]==topicInfo.raceNo);
    let poolInv = content.meeting.poolInvs.filter(x => x.oddsType == topicInfo.oddsType && x.leg.races[0] == topicInfo.raceNo)[0];

    if(message != null){
        if(message.sellList != null){
            if(poolInv != null) {
                poolInv.sellStatus = parseSellStat(message);
            }
            pmPools.forEach(pmPool => {
                pmPool.sellStatus = parseSellStat(message);
            });
        }
        if(message.st != null){
            if(poolInv != null) {
                poolInv.status = parsePoolStat(message);
            }
            pmPools.forEach(pmPool => {
                pmPool.status = parsePoolStat(message);
            });
        }
        if(poolInv != null) {
            if(!isStartSellpmPool(poolInv)) {
                poolInv.leg.races.forEach(raceNo =>{
                    ClearBetByBetTypeAndRaces(poolInv.oddsType ,raceNo);
                })
            }
        }
    }
}

const parseSellStat = (message)  => {
    let ab1 = message.sellList.filter(x => x.tote=='Ab1' && x.crsTyp=='On')[0];
    let ab2 = message.sellList.filter(x => x.tote=='Ab2' && x.crsTyp=='On')[0];
    return (ab1?.st=="StartSell" || ab2?.st=="StartSell") ? "START_SELL" : "STOP_SELL";
}

const parsePoolStat = (message)  => {
    switch ( message.st ) {
        case "StartSell":
            return "START_SELL";
        case "StopSell":
            return "STOP_SELL";
    }
    return message.st.toUpperCase();
}