import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { useBetSlip } from '../BetSlipHooks';
import { BetLineConfirmationHeader, isParentNode } from '../BetSlipBase';
import { useExpend } from './useExpend';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';

const M6BetLineTemplate = ({ betLineData, panelAllupObj, isShowPreviewInfo, isShowConfirmation }) => {
    const { i18n, t } = useTranslation();
    const { isExpended, handleClickExpendBox } = useExpend({ isShowPreviewInfo, isShowConfirmation });
    const initDefaultUnitBet = sessionStorage.getItem('Mk6DefaultUnitBetAmount')?Number(sessionStorage.getItem('Mk6DefaultUnitBetAmount')):10;
    const initPartialUnitBet = sessionStorage.getItem('Mk6PartialUnitBetAmount')?Number(sessionStorage.getItem('Mk6PartialUnitBetAmount')):5;
    const [unitBet, setUnitBet] = useState(initDefaultUnitBet);
    const { removeBetLinesItem, updateBetLinespData } = useBetSlip();
    const betObj = betLineData.betObj;
    const nextDraw = betObj.nextDraw;
    const chance = betObj.chance;
    const isSnowball = betObj.pageName.includes('SNOWBALL');
    let drawNo = String(nextDraw.no).padStart(3, '0');
    if (isSnowball) {
        drawNo = nextDraw.snowballCode;
    }
    let drawId = `${nextDraw?.year.substring(2, 4)}/${drawNo}`;
    const unitBetSelect = [
        { name: t('LB_M6_MULT_PARBET').replace('{0}', initPartialUnitBet), value: initPartialUnitBet },
        { name: t('LB_M6_MULT_UNITBET').replace('{0}', initDefaultUnitBet), value: initDefaultUnitBet }
    ];
    useEffect(() => {
        setUnitBet(betLineData.unitBet);
    }, []);

    // useEffect(()=>{
    //     if(isShowConfirmation || isShowPreviewInfo){
    //         setIsExpended(true)
    //     }else{
    //         setIsExpended(false)
    //     }
    // },[isShowPreviewInfo,isShowConfirmation])

    const getBetLineStr = () => {
        let bankerArr = [];
        let legArr = [...betObj.numList];
        let betStr = '';
        if (betObj.bankerList && betObj.bankerList.length > 0) {
            bankerArr = [...betObj.bankerList];
            bankerArr.map((item, index) => {
                if (index !== 0) {
                    betStr = betStr + '+ ';
                }
                betStr = betStr + item + ' ';
            });
            betStr = betStr + '> ';
        }
        legArr.map((item, index) => {
            if (index !== 0) {
                betStr = betStr + '+ ';
            }
            betStr = betStr + item + ' ';
        });
        return betStr;
    };

    const getBetTotal = () => {
        const multidraw = betObj.multidraw;
        const total = multidraw * chance * unitBet;
        return total.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };

    const handeleDeleteBetLineData = () => {
        if (isShowPreviewInfo) {
            const flag = window.confirm(t('LB_BS_REMOVE_BETLINE_MSG1'));
            if (flag) {
                removeBetLinesItem(betLineData.id);
            }

            return;
        }
        removeBetLinesItem(betLineData.id);
        glassboxCustomEvent("Remove_Betline");
    };

    const handleChangeSelect = (value) => {
        setUnitBet(value);
        betLineData.unitBet = value;
        const newBetLineData = {
            ...betLineData
        };
        updateBetLinespData(newBetLineData);
    };

    const UnitBetDropdown = () => {
        return (
            <div className={`unitbet-input ${chance === 1?'':'ddNoRSpace'}`}>
                <span>$</span>
                <DropdownButton
                    as={ButtonGroup}
                    key={`slunitBet`}
                    id={`slunitBet`}
                    title={
                        <>
                            {unitBet}
                            {chance === 1 ? '' : <ArrowIcon direction='down' size={24}/>}
                        </>
                    }
                    align="end"
                    disabled={chance === 1}
                >
                    <Dropdown.Item
                        eventKey="slunitBetsub-header"
                        value={t('LB_M6_BETSLIP_SELECTAMOUNT')}
                        key="slunitBetsub-header"
                    >
                        {t('LB_M6_BETSLIP_SELECTAMOUNT')}
                    </Dropdown.Item>
                    {unitBetSelect.map((item, index) => {
                        return (
                            <Dropdown.Item
                                className={unitBet === item.value ? 'active' : ''}
                                value={item.value}
                                onClick={() => handleChangeSelect(item.value)}
                                key={item.value}
                            >
                                {item.name}
                            </Dropdown.Item>
                        );
                    })}
                </DropdownButton>
            </div>
        );
    };

    const ignoreClassList = ['unitbet-input', 'switch-btn-icon'];
    const isUnknown = !betLineData.resultCode && betLineData.resultCode != 0;
    const sendBetStatus = betLineData.resultCode === 0 ? 'accepted' : !betLineData.resultCode ? 'unknown' : 'rejected';

    const collapseBetType = isSnowball ? t('LB_BS_MARKSIX_SB') : t('LB_M6_MK6_S');

    const CollapseBetLine = () => {
        return (
            <div
                onClick={(e) => handleClickExpendBox(e, ignoreClassList)}
                className="bet-line fb-bet-line m6-bet-line bet-line-collapse"
            >
                <div className="title">
                    <div>
                        {collapseBetType}{' '}
                        {betObj.multidraw !== 1 ? ` ${betObj.multidraw}${t('LB_M6_BETSLIP_DRAW_S')}` : ''}
                    </div>
                    <div onClick={handeleDeleteBetLineData} className="switch-btn-icon open-btn-icon"></div>
                </div>
                <div className="bet-type-col ">
                    <div className="collapse-betline">{getBetLineStr()}</div>
                    <UnitBetDropdown />
                </div>
            </div>
        );
    };

    return (
        <>
            {!isExpended ? (
                <CollapseBetLine />
            ) : (
                <div
                    className={`bet-line fb-bet-line m6-bet-line ${ panelAllupObj?.subTypeList.length > 0 ? 'pointer-events--none' : ''}`}
                    onClick={(e) => handleClickExpendBox(e, ignoreClassList)}
                >
                    <BetLineConfirmationHeader
                        isShow={isShowConfirmation}
                        status={sendBetStatus}
                        code={betLineData.resultCode}
                        txNo={betLineData.txNo}
                        resultMsg={betLineData.resultMsg}
                        resultMsgChi={betLineData.resultMsgChi}
                    />
                    <div className="title">
                        <div>
                            {t('LB_FMENU_MARKSIX')}
                            <span> {drawId} </span>{' '}
                            {betObj.multidraw !== 1 ? ` ${betObj.multidraw} ${t('LB_M6_BETSLIP_DRAW')}` : ''}
                        </div>
                        {!isShowConfirmation && !isShowPreviewInfo && (
                            <div onClick={handeleDeleteBetLineData} className="switch-btn-icon open-btn-icon"></div>
                        )}
                    </div>
                    <div className="content">
                        <div>{getBetLineStr()}</div>
                        <div className="subText">
                            <span>
                                {unitBet == initPartialUnitBet ? `(${t('LB_M6_BETSLIP_PARTIALUNIT')})` : ''}
                                {unitBet == initPartialUnitBet && ( (betObj.pageName.indexOf('RANDOM') > -1 && betObj.random !== false) || betObj.random) ? ` | ` : ''}
                                {(betObj.pageName.indexOf('RANDOM') > -1 && betObj.random !== false) || betObj.random
                                    ? `(${t('LB_BS_RANDOMNO')})`
                                    : ''}
                            </span>
                            {isShowPreviewInfo && (
                                <span className="font15">${isUnknown && isShowConfirmation ? '-' : unitBet}</span>
                            )}
                        </div>
                    </div>
                    {isShowPreviewInfo ? (
                        <div className="m6-preview-amount">
                            <span>{t('LB_RBC_AMOUNT')} :</span>
                            <span className="font15">
                                ${isUnknown && isShowConfirmation ? '-' : getBetTotal() + '.00'}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <div className="bet-type-col">
                                <div>&nbsp;</div>
                                <UnitBetDropdown />
                            </div>
                            <div className="amount-col">
                                <div className="left">
                                    <div>
                                        <div>{t('LB_RC_NO_OF_BETS')}:</div> <span>{betObj.chance}</span>
                                    </div>
                                </div>
                                <div className="right">
                                    <span>{t('LB_BS_BET_TOTAL')}:</span>
                                    <div>${getBetTotal()}.00</div>
                                </div>
                            </div>
                        </div>
                    )}
                    {panelAllupObj?.subTypeList.length > 0 && <div className="alup-dim"></div>}
                </div>
            )}
        </>
    );
};

export default M6BetLineTemplate;
