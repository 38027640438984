import React, { useEffect, useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../Common/CommonHooks';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { isLocalMeeting } from '../../Common/home-common';
import { useBetSlip } from '../BetSlipHooks';
import { ConfigContext } from '../../Common/ConfigHelper';
import { GetChannelPara } from '../../Common/ChannelParaFunc';
import { getDefaultRacingUnitBet } from '../../Racing/Common/Common';
import { BetLineConfirmationHeader } from '../BetSlipBase';
import { getHRBankAndLegStr } from '../BetlinesValidFunc';
import { useExpend } from './useExpend';
import { textFormatAmountFn, flexiFontSize } from '../../Common/GlobalFunc';
import CheckBox from '../../Common/ui-components/CheckBox';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { unitBetText } from '../../Racing/Common/Common';
import { getPersonalSettings } from '../../Home/PersonalSettings/config';

const getSubType = (betObj, t, shortName) => {
    switch (betObj.betType) {
        case 'FCT':
        case 'TCE':
        case 'QTT':
            if (shortName) {
                return '(' + t('LB_SHORT_STYPE_' + betObj.subType) + ')';
            }
            return '- ' + t('LB_STYPE_' + betObj.subType);
        default:
            return '';
    }
};

const newBetTypeText = (betType, t, isRandom = false) => {
    let text = 'LB_RACE_';
    let newTypeArr = ['W-P', 'QQP', 'CWA', 'CWAALUP', 'CWAALLALUP', 'CWB', 'CWC'];
    if (newTypeArr.includes(betType)) {
        text += 'BET_';
    }
    if (['TT'].includes(betType) && isRandom) {
        text += 'BET_QP_';
    }
    return t(`${text + betType}`);
};

const betTypeShortName = (betType, t, isRandom = false) => {
    let text = 'LB_';
    if (['CWA', 'CWB', 'CWC'].includes(betType)) {
        return t(`${text}CWIN`);
    }
    if (['IWN'].includes(betType)) {
        return t(`${text}IWN_SNAME`);
    }
    return t(`${text + betType}_S`);
};

const dateToDateString = (date) => {
    if (!date) return '';
    const newDate = new Date(date);
    const dateStr = newDate
        .toLocaleDateString('zh-HK')
        .split('/')
        .map((i) => {
            if (i < 10) {
                return '0' + i;
            }
            return i;
        })
        .join('/');
    return ' ' + dateStr;
};

const getHRBetLineStr = (supItem, interval, curIndex = 0, isAllUp = false, isCWIN, betObj, addInterval, t) => {
    let betStr = '';
    const plusSign = '+';
    if (supItem.betType) {
        if (curIndex === 0 || isAllUp) {
            let bType = supItem.betType.length === 2 ? supItem.betType.split('').join('-') : supItem.betType;
            if (bType == 'SixUP') bType = '6UP';
            betStr += t(`LB_${bType}_S`) + interval;
        }
        if (getSubType(supItem, t)) {
            betStr += t(`LB_SHORTS_STYPE_${supItem.subType}`) + interval;
        }
    }
    if ((!isAllUp && !isCWIN && curIndex === 0) || isAllUp) {
        const raceno = supItem.raceno || supItem.raceNo || betObj.raceno;
        betStr += raceno + '*';
    }
    if (isCWIN) {
        betStr += supItem.composite + plusSign;
    } else {
        betStr += getHRBankAndLegStr(supItem, addInterval);
    }
    return betStr;
};

export const HRBetLineTemplate = ({
    betLineData,
    panelAllupObj,
    panelAllupCheckFn,
    setIsShowError,
    isShowPreviewInfo,
    isShowConfirmation
}) => {
    const { i18n, t } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const { isExpended, handleClickExpendBox } = useExpend({ isShowPreviewInfo, isShowConfirmation });
    const [checkedAllUp, setCheckedAllUp] = useState(false);
    const [isDim, setIsDim] = useState(false);
    const { removeBetLinesItem, updateBetLinespData, updateResendBetLine, GetXPoolAllUpEnabled} = useBetSlip();
    const [isResendBetLine, setIsResendBetLine] = useState(false);
    const configContext = useContext(ConfigContext);
    const racingConfig = configContext?.racing;
    const { Allup3LegPools } = racingConfig;
    const betObj = betLineData.betObj;
    const isMultiRace =
        [...racingConfig?.MultiRacePage, ...racingConfig?.ALUP_PAGE, 'CWAALLALUP'].includes(betObj.betType) ||
        betObj.allupFormula;
    const isAllUp = [...racingConfig?.ALUP_PAGE, 'CWAALLALUP'].includes(betObj.betType) || betObj.allupFormula;
    const isCWIN = ['CWA', 'CWB', 'CWC'].includes(betObj.betType);
    const hideTopRaceNo =
        [...racingConfig?.MultiRacePage, 'JKC', 'TNC', ...racingConfig?.ALUP_PAGE, 'CWAALLALUP']?.includes(
            betObj.betType
        ) || isAllUp;

    const [calState, setCalState] = useState({
        unitBet: betObj.unitBet != null ? betObj.unitBet : getDefaultRacingUnitBet(betObj.betType),
        betTotal:
            betObj.betTotal ||
            (betObj.unitBet != null ? betObj.unitBet : getDefaultRacingUnitBet(betObj.betType)) * betObj.noOfBets,
        flexibet: betObj.isFlexi
    });
    const refUnitBet = useRef();
    const refTotalBet = useRef();
    const unitBetSelect = [
        { name: t('LB_RC_UNIT_EACH_BET_MIN'), value: 'U' },
        { name: t('LB_RC_UNIT_BETLINE_MIN'), value: 'B' }
    ];
    const [betUnit, setBetUnit] = useState('U');

    useEffect(() => {
        if (betLineData.betObj.isFlexi) {
            setBetUnit('B');
        } else {
            setBetUnit('U');
        }
    }, [betLineData.betObj.isFlexi]);

    useEffect(() => {
        let bool = false;
        if (panelAllupObj?.subTypeList.length > 0) {
            if (!panelAllupObj.subTypeList.includes(betObj.betType)) {
                bool = true;
            }
            const { allupCheckList, allupMaxLeg, uniqueMatchIdList } = panelAllupObj;
            if (betObj.meetingId !== allupCheckList[0].matchId) {
                bool = true;
            }
            for (let i = 0; i < allupCheckList.length; i++) {
                const element = allupCheckList[i];
                if (element.id !== betObj.id && element.raceno == betObj.raceno) {
                    bool = true;
                    break;
                }
                if(element.betType != betObj.betType && (!GetXPoolAllUpEnabled(element.betType) || !GetXPoolAllUpEnabled(betObj.betType))){
                    bool = true;
                    break;
                }
            }
            let uniqueMatchIdCheckList = uniqueMatchIdList;
            if (uniqueMatchIdCheckList.length >= 3 && !bool) {
                if (allupMaxLeg === 3) {
                    bool = !uniqueMatchIdCheckList.includes(betObj.raceno) ? true : false;
                } else {
                    bool = Allup3LegPools.includes(betObj.betType) ? true : false;
                    if (uniqueMatchIdCheckList.length >= 6 && !uniqueMatchIdCheckList.includes(betObj.raceno)) {
                        bool = true;
                    }
                }
            }
        }
        setIsDim(bool);
        if (panelAllupObj?.subTypeList.length === 0 && checkedAllUp) {
            setCheckedAllUp(false);
        }
    }, [panelAllupObj]);

    useEffect(() => {
        setIsResendBetLine(false);
        if (isShowConfirmation) {
            const isAutoAcceptance = betLineData.isAutoAcceptance;
            const showResendControlBtn = betLineData.showResendControlBtn;
            setIsResendBetLine(isAutoAcceptance || showResendControlBtn);
            betObj.unitBet = betLineData.unitBet;
            if (!betObj.isFlexi) {
                betObj.betTotal = betLineData.unitBet * betObj.noOfBets;
            }
            setCalState({
                unitBet: betLineData.unitBet,
                betTotal: betObj.isFlexi ? betObj.betTotal : betLineData.unitBet * betObj.noOfBets,
                flexibet: betObj.isFlexi
            });
        }
    }, [isShowConfirmation, betLineData]);

    const updateResendBetStatus = (val) => {
        const newBetLineData = {
            ...betLineData,
            ...val
        };
        updateResendBetLine(newBetLineData);
    };

    const handleClickResendBetBtn = (val) => {
        updateResendBetStatus({ isCounterOffer: val });
    };

    const onInputunitBet = (e) => {
        let value = e.target.value;
        if(isMobileSize && e.target.getAttribute("data-init") == 1) {
            if(e.nativeEvent.data !== null) {
                value = e.nativeEvent.data;
            }
            e.target.setAttribute("data-init",0);
            e.target.setAttribute('maxLength', 10)
        }
        if (/\D/g.test(value)) {
            value = value.replace(/\D/g, '');
        }

        if (value <= 0) {
            calState.unitBet = '';
        } /* else if (value * betObj.noOfBets > 99999999) {
            setIsShowError(new Boolean(true));
            e.target.blur();
        } */ else {
            value = Number(value);
            calState.unitBet = value;
            calState.betTotal = betObj.noOfBets * calState.unitBet;
        }
        if(!betObj.fixRandom){
            calState.flexibet = false;
            betObj.isRandom = false;
        }
        setCalState({ ...calState });
    };

    const onInputtotalBet = (e) => {
        let value = e.target.value;

        if(isMobileSize && e.target.getAttribute("data-init") == 1) {
            if(e.nativeEvent.data !== null) {
                value = e.nativeEvent.data;
            }
            e.target.setAttribute("data-init",0);
            e.target.setAttribute('maxLength', 10)
        }

        if (/\D/g.test(value)) {
            value = value.replace(/\D/g, '');
        }

        if (value <= 0) {
            calState.betTotal = '';
        } else {
            value = Number(value);
            calState.betTotal = value;
            const unitBet = calState.betTotal / betObj.noOfBets;
            calState.unitBet =
                unitBet >= 0.01
                    ? Math.floor(unitBet * 100) / 100
                    : Math.floor(unitBet * 1000) / 1000 || Math.floor(unitBet * 10000) / 10000 || 0.0001;
        }
        calState.flexibet = true;
        // betObj.isRandom = false;
        setCalState({ ...calState });
    };

    const getMinUnitBet=()=>{
        const minBet = window.racingConfig?.AllPoolsMinBet?.[betObj.betType] || 10;
        return minBet;
    }

    const onBlurunitBet = () => {
        if (!calState.unitBet || (!betObj.useFlexiUi && calState.unitBet < getMinUnitBet()) || calState.unitBet > Number(GetChannelPara('MaxBetUnit'))) {
            setIsShowError(new Boolean(true));
            calState.unitBet = betObj.unitBet;
            calState.betTotal = betObj.betTotal;
            calState.flexibet = betObj.isFlexi;
            setCalState({ ...calState });
        } else {
            betObj.unitBet = calState.unitBet;
            betObj.betTotal = calState.betTotal;
            betObj.isFlexi = calState.flexibet;
        }

        const newBetLineData = {
            ...betLineData,
            unitBet: betObj.unitBet
        };
        updateBetLinespData(newBetLineData);
    };

    const onBlurtotalBet = () => {
        if (calState.betTotal <= 0) {
            setIsShowError(new Boolean(true));
            calState.unitBet = betObj.unitBet;
            calState.betTotal = betObj.betTotal;
            calState.flexibet = betObj.isFlexi;
            setCalState({ ...calState });
        } else {
            betObj.unitBet = calState.unitBet;
            betObj.betTotal = calState.betTotal;
            betObj.isFlexi = calState.flexibet;
        }

        const newBetLineData = {
            ...betLineData,
            unitBet: betObj.unitBet
        };
        updateBetLinespData(newBetLineData);
    };

    const handeleDeleteBetLineData = () => {
        if (isShowPreviewInfo) {
            const flag = window.confirm(t('LB_BS_REMOVE_BETLINE_MSG1'));
            if (flag) {
                removeBetLinesItem(betLineData.id);
            }

            return;
        }
        removeBetLinesItem(betLineData.id);
        glassboxCustomEvent('Remove_Betline');
    };

    const handleClickAllUp = () => {
        if (isDim) return;
        setCheckedAllUp(!checkedAllUp);
        panelAllupCheckFn(betLineData);
    };

    const updateDeleteButton = (isChecked) => {
        if (isChecked === true) {
            return 'switch-btn-icon open-btn-icon disabled';
        } else {
            return 'switch-btn-icon open-btn-icon';
        }
    };

    const onFocusBetTextbox = (e) => {
        // if ( isMobileSize ) {
        //     e.target.select();
        // }
        // e.target.value = '';
        if ( isMobileSize ) {
            e.preventDefault();
            e.target.setAttribute("data-init",1);
            e.target.removeAttribute('maxLength')
            e.target.setSelectionRange(10, 10)
            setTimeout(function(){
                e.target.setSelectionRange(10, 10)
            });
        }
    };

    const onClickBetTextbox = (e) => {
        if ( isMobileSize ) {
            e.target.setSelectionRange(10, 10)
            setTimeout(function(){
                e.target.setSelectionRange(10, 10)
            });
        }
    };

    const instNoName = ['JKC', 'TNC'].includes(betObj.betType) ? betObj?.currentPool?.instNo : '';

    const allupLabelFn = () => {
        const { betList } = betObj;
        const firstBetType = betList[0].betType;
        const isCrossPoolAllup = betList.find((item) => {
            return item.betType !== firstBetType;
        });
        const text = isCrossPoolAllup ? 'LB_RBC_ALUP' : 'LB_ALLUP';
        return text;
    };

    const getQTT_M_QP = () => {
        if (betObj?.betType === 'QTT' && betObj?.subType === 'M' && betObj.isRandom) {
            return t('LB_RACE_BET_QTT_M_QP');
        }
        return '';
    };

    const isLocalMtg = isLocalMeeting(betObj.venueid);

    let sendBetStatus = betLineData?.resultCode === 0 ? 'accepted' : !betLineData?.resultCode ? 'unknown' : 'rejected';
    if (betLineData?.resultCode === 0 && betLineData?.showResendControlBtn) {
        sendBetStatus = 'rejected';
    }
    const isUnknown = !betLineData.resultCode && betLineData.resultCode != 0;
    const ignoreClassList = ['unitbet-input', 'switch-btn-icon', 'betline-checkbox', 'totalbet-input'];

    const deleteButton = !isShowConfirmation && !isShowPreviewInfo && (
        <div id="Remove_Betline" onClick={handeleDeleteBetLineData} className={updateDeleteButton(checkedAllUp)}></div>
    );
    let raceNo = '';
    if (!hideTopRaceNo) {
        raceNo = (
            <>
                {' '}
                <span>|</span> {t('LB_RC_ODDS_TABLE_RACENO')} {!isLocalMtg ? t('LB_' + betObj.venueid) + ' - ' : ''}
                {betObj.raceno}{' '}
            </>
        );
    }
    if (!isExpended && racingConfig?.MultiRacePage.includes(betObj.betType)) {
        raceNo = (
            <>
                {' '}
                <span>|</span> {t('LB_RC_ODDS_TABLE_RACENO')} {!isLocalMtg ? t('LB_' + betObj.venueid) + ' - ' : ''}
                {betObj.betList[0].raceNo}
            </>
        );
    }
    const allUpCheckBox = betLineData.showAllUpCheckBox && (
        <div className="betline-checkbox" onClick={handleClickAllUp}>
            <CheckBox checked={checkedAllUp} disabled={isDim} />
            <div>{t('LB_ALLUP')}</div>
        </div>
    );

    const betLineStr = () => {
        let text = '';
        const isFOBet = ['JKC', 'TNC'].includes(betObj.betType);
        if (isFOBet) {
            let tmp = [];
            betObj.combs.forEach((comb) => {
                tmp.push(`${comb.combId}@${comb.currentOdds}`);
            });
            text += tmp.join(' + ');
        } else {
            if (isMultiRace || isAllUp || isCWIN) {
                const { betList } = betObj;
                const plusSign = '+';
                if (isCWIN) {
                    text += `${t(`LB_${betObj.betType}_SNAME`)} `;
                }
                betList.map((item, index) => {
                    if (isAllUp || (isCWIN && index === 0)) {
                        text += getHRBetLineStr(item, ' ', index, isAllUp, isCWIN, betObj, 'addInterval', t);
                    }
                    if (isMultiRace && !isAllUp) {
                        text += getHRBankAndLegStr(item, 'addInterval');
                    }
                    if (isAllUp || isMultiRace) {
                        if (index === betList.length - 1) {
                            text = text.substring(0, text.length - 1);
                        } else {
                            text = text.substring(0, text.length - 1) + '/';
                        }
                    } else if (isCWIN && index === 0) {
                        text = text.substring(0, text.length - 1) + '/...';
                    }
                });
            } else {
                text = getHRBankAndLegStr(betObj, 'addInterval');
            }
        }
        return text;
    };

    const handleChangeSelect = (value) => {
        setBetUnit(value);

        // calState.flexibet = value === 'B' ? true : false;
        setCalState({ ...calState });
        // betObj.isFlexi = value === 'B' ? true : false;
        const newBetLineData = {
            ...betLineData,
            isFlexi: betObj.isFlexi,
            betObj: {
                ...betObj,
                isRandom: false
            }
        };
        updateBetLinespData(newBetLineData);
    };

    const UnitBetDropdown = () => {
        return (
            <div className="unitbet-input ddNoRSpace">
                <DropdownButton
                    as={ButtonGroup}
                    key={`slunitBet`}
                    id={`slunitBet`}
                    title={
                        <>
                            {t(`LB_RC_UNIT_EACH_BET_${betUnit}`)}$<ArrowIcon direction="down" size={24} />
                        </>
                    }
                    align="end"
                >
                    <Dropdown.Item
                        eventKey="slunitBetsub-header"
                        value={t('LB_M6_BETSLIP_SELECTAMOUNT')}
                        key="slunitBetsub-header"
                    >
                        {t('LB_M6_BETSLIP_SELECTAMOUNT')}
                    </Dropdown.Item>
                    {unitBetSelect.map((item, index) => {
                        return (
                            <Dropdown.Item
                                className={betUnit === item.value ? 'active' : ''}
                                value={item.value}
                                onClick={() => handleChangeSelect(item.value)}
                                key={index}
                            >
                                {item.name}
                            </Dropdown.Item>
                        );
                    })}
                </DropdownButton>
            </div>
        );
    };
    const disable = (panelAllupObj?.uniqueMatchIdList?.length >= 1 && betObj.betType == 'ALUP') || isDim;
    const isFlexibet = betObj.useFlexiUi && calState.flexibet;
    const unitBetStr = unitBetText(calState.unitBet, calState.flexibet);
    const betTotalStr = textFormatAmountFn(calState.betTotal, 0, false);
    return (
        <>
            {isExpended ? (
                <div
                    className={`bet-line rc-bet-line ${disable ? 'pointer-events--none' : ''}`}
                    onClick={(e) => handleClickExpendBox(e, ignoreClassList)}
                >
                    <BetLineConfirmationHeader
                        isShow={isShowConfirmation}
                        status={sendBetStatus}
                        isCounterOffer={betLineData?.showResendControlBtn}
                        code={betLineData?.resultCode}
                        txNo={betLineData.txNo}
                        resultMsg={betLineData.resultMsg}
                        resultMsgChi={betLineData.resultMsgChi}
                    />
                    <div className="title">
                        <div>
                            {!isLocalMtg ? t('LB_BS_OVERSEAS') : ''} {t('LB_' + betObj.venueid)}{' '}
                            {t(`LB_${betObj.dayid}`)} {raceNo}
                        </div>
                        {deleteButton}
                    </div>
                    {isShowPreviewInfo && isResendBetLine ? (
                        <HRCounterOfferTempLate
                            betLineData={betLineData}
                            isResendBetLine={isResendBetLine}
                            handleClickResendBetBtn={handleClickResendBetBtn}
                        />
                    ) : (
                        <div className={`content ${isShowPreviewInfo ? 'preview-content' : ''}`}>
                            {!isMultiRace && !isCWIN ? (
                                <>
                                    {betObj.bankers?.map((subBankers) => {
                                        if (subBankers.length > 0) {
                                            return [
                                                subBankers?.map((runner, index) => {
                                                    return (
                                                        <span>
                                                            {runner.no}{' '}
                                                            {i18n.language === 'en' ? runner.name_en : runner.name_ch}{' '}
                                                            {index === subBankers.length - 1 ? '' : ' + '}
                                                        </span>
                                                    );
                                                }),
                                                <div className="banker">{t('LB_BS_BANKER_WITH')}</div>
                                            ];
                                        }
                                    })}
                                    {betObj.combs.map((runner, index) => {
                                        if (!runner) return;
                                        const connector = betObj.betType == 'IWN' ? ' # ' : ' + ';
                                        return (
                                            <>
                                                <span>
                                                    {runner.no || runner.number}{' '}
                                                    {i18n.language === 'en' ? runner.name_en : runner.name_ch}
                                                </span>
                                                {['JKC', 'TNC'].includes(betObj.betType) ? (
                                                    <>
                                                        @{runner.currentOdds} <br />
                                                    </>
                                                ) : index === betObj.combs.length - 1 ? (
                                                    ''
                                                ) : (
                                                    connector
                                                )}
                                            </>
                                        );
                                    })}
                                </>
                            ) : (
                                <HRMultiRaceTemplate
                                    betObj={betObj}
                                    isCWIN={isCWIN}
                                    isAllUp={isAllUp}
                                    isLocalMtg={isLocalMtg}
                                />
                            )}
                            <div className="subText">
                                {betObj.isRandom ? `(${t('LB_BS_RANDOMNO')})` : ''}{' '}
                                {isFlexibet ? `(${t('LB_BS_FLEXI_BET')})` : ''}
                            </div>
                            {isShowPreviewInfo && (
                                <div className="hr-preview-unitbet">
                                    $
                                    {isUnknown && isShowConfirmation
                                        ? '-'
                                        : isFlexibet
                                        ? textFormatAmountFn(betObj.betTotal || betObj.noOfBets * calState.unitBet)
                                        : textFormatAmountFn(betObj.unitBet, 2)}
                                </div>
                            )}
                        </div>
                    )}
                    {isShowPreviewInfo ? (
                        <div>
                            <div className="hr-preview-bettype">
                                {isAllUp
                                    ? `${t(allupLabelFn())} ${betObj.allupFormula?.replace('x', t('LB_FB_CAL_CROSS'))}`
                                    : `${newBetTypeText(betObj.betType, t, betObj.isRandom)} ${getSubType(
                                          betObj,
                                          t
                                      )} ${getQTT_M_QP()}`}
                                {instNoName}
                            </div>
                            <div className="fb-preview-amount">
                                <span>{t('LB_RBC_AMOUNT')} :</span>
                                <span className="font15">
                                    $
                                    {isUnknown && isShowConfirmation
                                        ? '-'
                                        : textFormatAmountFn(betObj.betTotal || betObj.noOfBets * calState.unitBet)}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="bet-type-col">
                                <div>
                                    {isAllUp
                                        ? `${t(allupLabelFn())} ${betObj.allupFormula?.replace(
                                              'x',
                                              t('LB_FB_CAL_CROSS')
                                          )}`
                                        : `${newBetTypeText(betObj.betType, t, betObj.isRandom)} ${getSubType(
                                              betObj,
                                              t
                                          )} ${getQTT_M_QP()}`}
                                    {instNoName}
                                </div>
                                <div className={`unitbet-input ${checkedAllUp ? 'opacity25' : ''}`}>
                                    {betObj.useFlexiUi ? <div className="talignR">{t('LB_RC_UNIT_BET')}:</div> : ''}
                                    {betObj.useFlexiUi ? <span className="flexi">$</span> : <span>$</span>}
                                    <input
                                        maxLength="10"
                                        type="text"
                                        inputMode="numeric"
                                        ref={refUnitBet}
                                        style={{ fontSize: flexiFontSize(unitBetStr) }}
                                        value={unitBetStr}
                                        onInput={onInputunitBet}
                                        onBlur={onBlurunitBet}
                                        onFocus={onFocusBetTextbox}
                                        disabled={checkedAllUp}
                                    />
                                </div>
                            </div>
                            <div className="amount-col">
                                <div className="left">
                                    <div>
                                        <div className={betLineData.showAllUpCheckBox ? 'noOfBets35px' : ''}>
                                            {t('LB_RC_NO_OF_BETS')}:
                                        </div>{' '}
                                        <span>{betObj.noOfBets}</span>
                                    </div>
                                    {allUpCheckBox}
                                </div>
                                <div className={`right ${checkedAllUp ? 'opacity25' : ''}`}>
                                    <span>{t('LB_BS_BET_TOTAL')}:</span>
                                    {betObj.useFlexiUi ? (
                                        <div className={`totalbet-input`}>
                                            <span>$</span>
                                            <input
                                                maxLength="10"
                                                type="text"
                                                inputMode="numeric"
                                                ref={textFormatAmountFn(refTotalBet, 0, false)}
                                                style={{ fontSize: flexiFontSize(betTotalStr) }}
                                                value={betTotalStr}
                                                onInput={onInputtotalBet}
                                                onBlur={onBlurtotalBet}
                                                onFocus={onFocusBetTextbox}
                                                disabled={checkedAllUp}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={`${
                                                textFormatAmountFn(calState.unitBet * betObj.noOfBets)?.length > 12
                                                    ? 'dividend12px'
                                                    : ''
                                            }`}
                                        >
                                            ${textFormatAmountFn(calState.unitBet * betObj.noOfBets)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {disable && <div className="alup-dim"></div>}
                </div>
            ) : (
                <div
                    className={`bet-line rc-bet-line  bet-line-collapse ${disable ? 'pointer-events--none' : ''}`}
                    onClick={(e) => handleClickExpendBox(e, ignoreClassList)}
                >
                    {/* <div>isExpended is false</div> */}
                    <div className="title">
                        <div>
                            {isAllUp
                                ? `${t(`${allupLabelFn()}`)} ${betObj.allupFormula?.replace('x', t('LB_FB_CAL_CROSS'))}`
                                : `${betTypeShortName(betObj.betType, t, betObj.isRandom)} ${getSubType(
                                      betObj,
                                      t,
                                      'shortName'
                                  )}`}
                            {instNoName}
                            {['JKC', 'TNC'].includes(betObj.betType) &&
                                dateToDateString(betObj?.currentPool?.expStopDateTime)}
                            {raceNo}
                        </div>
                        {deleteButton}
                    </div>
                    <div className="bet-type-col small-bet-type-col">
                        <div className="collapse-content-l">
                            {allUpCheckBox}
                            <div className="collapse-betline">{betLineStr()}</div>
                        </div>
                        <div className="collapse-content-r">
                            {betObj.useFlexiUi && <UnitBetDropdown />}
                            <div className={`unitbet-input ${checkedAllUp ? 'opacity25' : ''}`}>
                                <span>$</span>
                                {betUnit === 'U' ? (
                                    <input
                                        maxLength="10"
                                        type="text"
                                        inputMode="numeric"
                                        ref={refUnitBet}
                                        style={{ fontSize: flexiFontSize(unitBetStr) }}
                                        value={unitBetStr}
                                        onInput={onInputunitBet}
                                        onBlur={onBlurunitBet}
                                        onFocus={onFocusBetTextbox}
                                        onClick={onClickBetTextbox}
                                        disabled={checkedAllUp}
                                    />
                                ) : (
                                    <input
                                        maxLength="10"
                                        type="text"
                                        inputMode="numeric"
                                        ref={refTotalBet}
                                        style={{ fontSize: flexiFontSize(betTotalStr) }}
                                        value={betTotalStr}
                                        onInput={onInputtotalBet}
                                        onBlur={onBlurtotalBet}
                                        onFocus={onFocusBetTextbox}
                                        disabled={checkedAllUp}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {disable && <div className="alup-dim"></div>}
                </div>
            )}
        </>
    );
};

export const HRMultiRaceTemplate = ({ betObj, isCWIN, isAllUp, isLocalMtg, isCurrentSessionRecords }) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            {betObj.betList.map((betItem, betIndex) => {
                return (
                    <>
                        <div>
                            {isCWIN ? (
                                <div className="composite-name banker">
                                    {t('LB_RC_CWIN_COMPOSITE')} {betItem.composite} (
                                    {i18n.language === 'en' ? betItem.name_en : betItem.name_ch}):
                                </div>
                            ) : (
                                <div className="mult-item-name banker">
                                    {isCurrentSessionRecords ? (
                                        <>{t('LB_RC_SEL_S').replace('{0}', betItem.raceNo)}</>
                                    ) : (
                                        <>
                                            {t('LB_RC_ODDS_TABLE_RACENO')}{' '}
                                            {!isLocalMtg ? t('LB_' + betObj.venueid) + ' - ' : ''}
                                            {betItem.raceNo}{' '}
                                        </>
                                    )}
                                    {isAllUp ? '| ' + newBetTypeText(betItem.betType, t) + getSubType(betItem, t) : ''}
                                </div>
                            )}
                            {betItem.bankers?.map((subBankers) => {
                                if (subBankers.length > 0) {
                                    return [
                                        subBankers?.map((runner, index) => {
                                            return (
                                                <span>
                                                    {runner.no}{' '}
                                                    {i18n.language === 'en' ? runner.name_en : runner.name_ch}{' '}
                                                    {index === subBankers.length - 1 ? '' : ' + '}
                                                </span>
                                            );
                                        }),
                                        <div className="banker">{t('LB_BS_BANKER_WITH')}</div>
                                    ];
                                }
                            })}
                            {betItem.combs.map((runner, index) => {
                                if (['CWA'].includes(betItem.betType) && isAllUp) {
                                    // CWA混合過關下
                                    return (
                                        <div>
                                            <div className="composite-name banker">
                                                {t('LB_RC_CWIN_COMPOSITE')} {runner.composite} (
                                                {i18n.language === 'en' ? runner.name_en : runner.name_ch}):
                                            </div>
                                            {runner.combs.map((subRunner, index) => {
                                                return (
                                                    <span>
                                                        {subRunner.no}{' '}
                                                        {i18n.language === 'en' ? subRunner.name_en : subRunner.name_ch}{' '}
                                                        {index === runner.combs.length - 1 ? '' : ' + '}
                                                    </span>
                                                );
                                            })}
                                            {index === betItem.combs.length - 1 ? null : <div> + </div>}
                                        </div>
                                    );
                                }
                                return (
                                    <>
                                        <span>
                                            {runner.no} {i18n.language === 'en' ? runner.name_en : runner.name_ch}{' '}
                                            {index === betItem.combs.length - 1 ? '' : ' + '}
                                        </span>
                                    </>
                                );
                            })}
                            {isCWIN ? betIndex === betObj.betList.length - 1 ? null : <div> + </div> : null}
                        </div>
                        {betObj.betType === 'TT' && betIndex === 2 && (
                            <div>
                                <br />
                                <br />
                            </div>
                        )}
                    </>
                );
            })}
        </>
    );
};

export default HRBetLineTemplate;

const HRCounterOfferTempLate = ({ betLineData, isResendBetLine, handleClickResendBetBtn }) => {
    // console.log('HRCounter', betLineData, isResendBetLine);
    const { i18n, t } = useTranslation();
    const betObj = betLineData.betObj;
    const isAutoAcceptance = betLineData.isAutoAcceptance;
    const counterOfferAmountChange = betLineData.counterOfferAmountChange;
    const showResendControlBtn = betLineData?.showResendControlBtn;
    const counterOfferOddsChange = betLineData?.counterOfferOddsChange;
    const oldUnitBet = betLineData.oldUnitBet;
    const unitBet = betLineData.unitBet;
    const amountUp = Number(unitBet) > Number(oldUnitBet);
    const amountChanged = oldUnitBet != undefined && unitBet != oldUnitBet;
    let resendMsg = counterOfferAmountChange ? t('LB_BS_RESENDBET_AMOUNT_CHANGED') : t('LB_BS_RESENDBET_ODDS_CHANGED');
    if (counterOfferOddsChange && counterOfferAmountChange) {
        resendMsg = t('LB_BS_RESENDBET_AMOUNTANDODDS_CHANGED');
    }

    return (
        <>
            <div className="content COContent">
                <div className="betLineContentTop">
                    <div className="left">
                        {betObj.combs.map((runner, index) => {
                            if (!runner) return;
                            const connector = betObj.betType == 'IWN' ? ' # ' : ' + ';
                            const newOdds = runner.currentOdds;
                            const oldOdds = runner.oldOddsVal;
                            const oddsUp = Number(newOdds) > Number(oldOdds);
                            const oddsChanged = oldOdds != undefined && newOdds != oldOdds;

                            // console.log(
                            //     'runner',
                            //     runner,
                            //     'newOdds:' + newOdds,
                            //     'oldOdds:' + oldOdds,
                            //     'oddsUp:' + oddsUp,
                            //     'oddsChange:' + oddsChanged
                            // );

                            let oddsContent = '';
                            if (oddsChanged && (isAutoAcceptance || counterOfferOddsChange)) {
                                oddsContent = (
                                    <>
                                        {oldOdds} {t('LB_BS_RESENDBET_CHANGED_TO')}{' '}
                                        <span className={`comb_newodds ${oddsUp ? 'oddsUp' : 'oddsDown'}`}>
                                            {newOdds}
                                            <span></span>
                                        </span>
                                    </>
                                );
                            } else {
                                oddsContent = newOdds;
                            }

                            return (
                                <>
                                    {['JKC', 'TNC'].includes(betObj.betType) ? (
                                        <div key={runner.no || runner.number} className={`comb_${index}`}>
                                            <span>
                                                {runner.no || runner.number}{' '}
                                                {i18n.language === 'en' ? runner.name_en : runner.name_ch}
                                            </span>
                                            @{oddsContent}
                                        </div>
                                    ) : index === betObj.combs.length - 1 ? (
                                        ''
                                    ) : (
                                        connector
                                    )}
                                </>
                            );
                        })}
                    </div>
                    <div className="right">
                        {counterOfferAmountChange && isResendBetLine && amountChanged ? (
                            <>
                                <div className="coAmountChangeText">
                                    ${textFormatAmountFn(oldUnitBet)}
                                    <wbr /> {t('LB_BS_RESENDBET_CHANGED_TO')}
                                </div>
                                <div
                                    className={`fb-preview-bet ${
                                        textFormatAmountFn(unitBet)?.length > 11 ? 'dividend10px' : ''
                                    } ${amountUp ? 'amountUp' : 'amountDown'}`}
                                >
                                    <span className={`${amountUp ? 'comb_newodds oddsUp' : 'comb_newodds oddsDown'}`}>
                                        ${textFormatAmountFn(unitBet)}
                                        <span></span>
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                ${textFormatAmountFn(unitBet)}
                                <span></span>
                            </>
                        )}
                    </div>
                </div>
                {showResendControlBtn && isResendBetLine && (
                    <div className="resendBetBtnContent">
                        <div>{resendMsg}</div>
                        <div>
                            <div
                                className={betLineData.isCounterOffer ? '' : 'active'}
                                onClick={() => handleClickResendBetBtn(false)}
                            >
                                {t('LB_BS_RESENDBET_REJECT')}
                            </div>
                            <div
                                className={betLineData.isCounterOffer ? 'active' : ''}
                                onClick={() => handleClickResendBetBtn(true)}
                            >
                                {t('LB_BS_RESENDBET_ACCEPT')}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
