import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { setMyFavCookie, MyFavouritesContext } from './MyFavourites'
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { MarksixContext } from '../../Home/MarksixPage';
import { isStartSell, isSnowballDraw } from './Common';
import { RrawResultContext } from '../Page/CheckDrawResult';
import {useBetSlip, AddM6SlipFunc} from '../../BetSlip/BetSlipHooks'
import { getPersonalSettings } from '../../Home/PersonalSettings/config'
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import { useWindowSize } from '../../Common/CommonHooks';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import Sticky from 'react-stickynode';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { isPmuEoD, eodMessage } from '../../Common/GlobalFunc';
import i18n from '../../../i18n';

const selectOption = [
  { label: '---', value: '1' },
  { label: '5', value: '5' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '30', value: '30' },
  { label: '---', value: 1 },
]

const AmountDisplay = ({ unitBet, selectedData = [], tableType, activeType, clear, className, onChangeCheckTable, onChangeDraw }) => {
  const { t } = useTranslation()
  const { addToBetSlip, betLines } = useBetSlip()
  const context = useContext(MarksixContext);
  const { isMobileSize } = useWindowSize();
  const pageName = context.pageName;
  const isSell = isStartSell(context.nextDraw);
  const isSB = isSnowballDraw(context.nextDraw);
  const isSBPage=pageName.includes('SNOWBALL');
  const [chance, setChance] = useState(0)
  const [multDraw, setMultDraw] = useState(() => {
    return !isSBPage && parseInt(getPersonalSettings('m6MulDrow'), 10) || 1
  })
  const [totalAmount, setTotalAmount] = useState(0)
  const [canAddToSlip, setCanAddToSlip] = useState(true)
  const [isCheckdrawresultPage, setIsCheckdrawresultPage] = useState(false)
  const { updateMyFavourites, curpageFavCheckArr, setCurpageFavCheckArr, setFavInitCheckState } = useContext(MyFavouritesContext)
  const { checkRrawNumber, setCheckRrawNumber } = useContext(RrawResultContext)

  useEffect(() => {
    onChangeDraw && onChangeDraw(multDraw)
  }, [])

  useEffect(() => {
    let num = 0
    let totalAM = 0
    let canSave = true
    if (tableType === 'single') {
      selectedData.forEach(item => {
        if (item.numList.length >= 6) {
          num++
          totalAM += item.unitBet
        }
        else if (item.numList.length > 0) canSave = false
        else if (item.numList.length === 0 && item.isFav) canSave = false
      })
    }
    else if (tableType === 'multiple') {
      if (selectedData?.[0]?.numList.length >= 6) {
        num = selectedData[0].chance
        totalAM += unitBet * selectedData[0].chance
      }
    }
    else if (['random', 'banker', 'checkdrawresult'].includes(tableType)) {
      selectedData.forEach(item => {
        const cha = item.chance || 0
        const unitB = item.unitBet || unitBet
        num += cha
        totalAM += cha * unitB
      })
    }
    if (num === 0) canSave = false
    setCanAddToSlip(canSave)
    setChance(num)
    if (num > 0) { totalAM = totalAM * multDraw }
    setTotalAmount(totalAM)
    if (tableType === 'checkdrawresult') {
      setIsCheckdrawresultPage(true)
    }else{
      setIsCheckdrawresultPage(false)
    }

  }, [selectedData, tableType, unitBet])

  const handleClickClear = () => {
    let isEmpty = true
    selectedData.forEach(i =>{
      if(i.numList.length > 0 ){
        isEmpty = false
      }
    })
    if(isEmpty) return
    const confirm =  window.confirm(t('LB_M6_MSG_CLEARALL'))
    if(confirm){
      clear()
      if(isCheckdrawresultPage){
        onChangeCheckTable(false)
        cancelFavCheck()
      }
    }
  }

  const cancelFavCheck = () => {
    if(!checkRrawNumber.checked) return ;
    const dateNow = Date.now();
    setFavInitCheckState(dateNow)
    setTimeout(() => {
        setFavInitCheckState('')
    }, 300);
  }

  const addToSlipvalidation = (needAlert = true,mobileIgnore = false) => {
    let favCheckedList = isMobileSize?curpageFavCheckArr.filter(item => item.checked):[];
    if (!isSell && !mobileIgnore) {
      if (needAlert) {
        if (isPmuEoD() && eodMessage()?.length > 0) {
            window.alert(eodMessage())  
          } else {
            window.alert(t('LB_M6_MSG_NOT_DIFINED'))
          }
        } 
        return false
    }
    if (!canAddToSlip ) {
        if(favCheckedList.length>0){
          if(!needAlert){
            return true
          }
          const isCanAdd= ((!canAddToSlip) && (selectedData[0]?.bankerList?.length > 0 || selectedData[0]?.numList?.length > 0 )) ? false:true;
          if(!isCanAdd){
            window.alert(t('LB_M6_MSG_INSU_SELECTIONS'))
          }
          return isCanAdd
        }else{
          needAlert && window.alert(t('LB_M6_MSG_INSU_SELECTIONS'))
          return false
        }
        
    }
    if (selectedData[0]?.bankerList?.length > 0 && (selectedData[0].bankerList.length + selectedData[0].numList.length) < 7 ) {
        if(favCheckedList.length>0){
          if(!needAlert){
            return true
          }
          window.alert(t('LB_M6_MSG_INSU_SELECTIONS'))
          return false
        }else{
          needAlert  && window.alert(t('LB_M6_MSG_INSU_SELECTIONS'))
          return false
        }
    }
    for (let i = 0; i < selectedData.length; i++) {
      if (selectedData[i].numList.length >= 49 && !mobileIgnore) {
        needAlert && window.alert(t('LB_M6_MSG_SELECT_NUMBERS_MULT_49'))
        return false
      }
    }
    return true
  }

  const globalContext = useContext(GlobalContext);
  useEffect(() => {
    globalContext.setBottomMenuAddSlipButtonDisable(!addToSlipvalidation(false,true))
  })

  const handleClickAddToSlip = () => {
      if(!addToSlipvalidation(true)) return false
    let favCheckedList = isMobileSize?curpageFavCheckArr.filter(item => item.checked):[];
    if(favCheckedList.length>0){
      favCheckedList=favCheckedList.map(item=>{
          return {...item,random:false,checked:false};
      })
      const dateNow = Date.now();
      setFavInitCheckState(dateNow)
      setTimeout(() => {
          setFavInitCheckState('')
      }, 300);
    }
    if(isCheckdrawresultPage){
      cancelFavCheck()
    }
    const betList=AddM6SlipFunc([...selectedData,...favCheckedList],context)
    addToBetSlip(betList) && clear()
    if(isCheckdrawresultPage){
      onChangeCheckTable(false)
    }
    glassboxCustomEvent("Add_to_Slip");
  }

  const handleClickCheck = () => {
    if (!canAddToSlip) return window.alert(t('LB_M6_MSG_INSU_SELECTIONS'))
    if (selectedData[0]?.bankerList?.length > 0 && (selectedData[0].bankerList.length + selectedData[0].numList.length) < 7) {
      return window.alert(t('LB_M6_MSG_INSU_SELECTIONS'))
    }
    onChangeCheckTable(true)
  }

  const onChangeMultDraw = (multDraw) => {
    setMultDraw(multDraw)
    onChangeDraw(multDraw)
    if (totalAmount - 0 > 0) {
      const totalAM = chance * unitBet * multDraw
      setTotalAmount(totalAM)
    }

  }

  const moreOptions = () => {
    const opts = []
    for (let i = 5; i <= 30; i++) {
      opts.push(<Dropdown.Item key={'down' + i}  onClick={()=>onChangeMultDraw(i)} active={multDraw === i} value={i}>{i}</Dropdown.Item>)
    }
    return opts
  }

  return <div className={`amount-display-table ${className}${isSB ? ' amount-display-table-sb' : ''}`}>
    {/* {activeType !== 'SINGLE' ? null : <div>
      <div>{t('LB_M6_SINGLE_TABLE_NUITBET')}</div>
      <div className='m6_draw_val_H'>$ {unitBet}</div>
    </div>} */}
    <div>
      <div>{t('LB_M6_SINGLE_TABLE_CHANCE')}</div>
      <div className='m6_draw_val_H'>{chance.toLocaleString()}</div>
    </div>
    {!isCheckdrawresultPage &&
    <div>
      <div>{t('LB_M6_SINGLE_TABLE_TOTALAMOUNT')}</div>
      <div className='m6_draw_val_H'>{'$ ' + totalAmount.toLocaleString()}</div>
    </div>
    }
    {isSB && isSBPage ? <div className='amount-display-multdraw'></div> : <div className='amount-display-multdraw'>
      {!isCheckdrawresultPage && sessionStorage.getItem('MK6MultipleDraw') == '1' && <>
      <div>{t('LB_M6_SINGLE_TABLE_MULTDRAW')}</div>
      <div>
        <DropdownButton
        title={<><div className='msDDText'>{multDraw == 1 ? '---' : multDraw}</div><ArrowIcon direction='down' size={24}/></>}
        align="start"
        value={multDraw} >
          {selectOption.map((item, index) => {
            return <Dropdown.Item value={item.value} onClick={()=>onChangeMultDraw(item.value)} active={multDraw === item.value} key={'top' + item.value}>{item.label}</Dropdown.Item>
          })}
          {moreOptions()}
        </DropdownButton>
      </div>
      </>}
    </div>}
    <div className='amount-display-btn'>
      {isCheckdrawresultPage?
      <>
      <div><button className='cta_m6' title={t('LB_M6_SINGLE_TABLE_RESET')} onClick={handleClickClear}>{t('LB_M6_SINGLE_TABLE_RESET')}</button></div>
      <div><button className='check-btn cta_m6' title={t('LB_M6_SINGLE_TABLE_CHECK')} onClick={handleClickCheck}>{t('LB_M6_SINGLE_TABLE_CHECK')}</button></div>
      </>:
      <div><button className='cta_m6' title={t('LB_M6_SINGLE_TABLE_RESET')} onClick={handleClickClear}>{t('LB_M6_SINGLE_TABLE_RESET')}</button></div>
      }
      <div><button className='AddToSlip' title={t('LB_ADDTOSLIP')} onClick={handleClickAddToSlip}>{t('LB_ADDTOSLIP')}</button></div>
    </div>
  </div>
}

export default AmountDisplay

export const MobileAmountDisplay = (props)=>{
  const isActiveTop=(param)=>{
    const {status}=param;
    const headStickyDom=document.querySelector('.marksix-header-info')
    if(!headStickyDom) return;
    if(status){
      headStickyDom.style.boxShadow='unset';
    }else{
      headStickyDom.style.boxShadow='';
    }
  }
  return <Sticky innerZ={998} top={60} onStateChange={(e)=>isActiveTop(e)}>
    <AmountDisplay {...props}/>
  </Sticky>
}