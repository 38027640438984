import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { ResultHeader } from '../Common/ResultsBase';
import { TournImage, FlagTournIcon } from '../Common/MatchBase';
import { sortSeqIgnoreCase } from '../Common/Common';
import { GlobalContext } from '../../../contexts/GlobalContext';

const CHPRES = () => {
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const tournaments = context.content.tournData?.tournaments || [];
    const globalContext = useContext(GlobalContext);
    const tournOddsMenuGqlVars = globalContext.fbMenuGqlVars;

    let offset = tournOddsMenuGqlVars?.data?.timeOffset?.fb || 0;
    let mSecDisplayDays = 24*60*60*1000*context.content.config.MaxTournResultDays;
    let displayDt = globalContext?.sessionServerTimeStamp + (offset*1000) - mSecDisplayDays;
    let tourns = tournaments.filter(x=> new Date(x.foPools[0]?.updateAt).getTime() > displayDt).sort(sortSeqIgnoreCase);

    return <>
        <ResultHeader />
        {tourns.length==0 ? 
        <div className='nopool'>
            <div className='nopoolmsg'>
                {t('LB_FB_CHPRES_NORESULT')}
            </div>
        </div>
        :
        <section className="CHPRES">
            <div className='tourn-result-header'>
                {t('LB_FB_TITLE_CHPRES')}
            </div>
            <div className='chpres-table head'>
                <div className='tourn-header event-id'>{t('LB_FB_EVENT_ID')}</div>
                <div className='flag-cell'>
                    <FlagTournIcon />
                </div>
                <div className='tourn-header team-name'>{t('LB_FB_CHPRES_TOURNNAME')}</div>
                <div className='tourn-header team-name'>{t('LB_FB_CHPRES_CHAMPION')}</div>
            </div>
            {tourns.map((tourn, idx) => {
                let cssName = idx%2==0 ? 'odd' : 'sec';
                let teamTxt = null;
                let isRefund = tourn.foPools[0]?.status.indexOf("REFUND")>=0;
                if (isRefund) {
                    teamTxt = <span className="refund-for-all">{t('LB_FB_REFUND_FOR_ALL')}</span>;
                }
                else {
                    let combs = tourn.foPools[0]?.lines[0]?.combinations?.filter(x=> x.status=="WIN").sort((a,b) => a.winOrd - b.winOrd);
                    for (let comb of combs) {
                        teamTxt = <>
                            {teamTxt}
                            <div>{`${comb.selections[0]?.str} ${comb.selections[0]["name_" + i18n.language]}`}</div>
                        </> 
                    }
                }
                return <div className={`chpres-table ${cssName}`}>
                    <div className={`event-id tourn-cell`}><span>{tourn.frontEndId}</span></div>
                    <div className={`flag-cell tourn-cell cell-border`}><TournImage code={tourn.code} name={tourn["name_" + i18n.language]} /></div>
                    <div className={`team-name tourn-cell cell-border`}>{tourn["name_" + i18n.language]}</div>
                    <div className={`team-name tourn-cell cell-border`}>{teamTxt}</div>
                </div>
            })}
        </section>
        }
        </>
}
export default CHPRES;