import { useTranslation } from 'react-i18next';
import './index.scss'
import { useEffect } from 'react';

export const allMatchTimeTypes = [{
    key: 'ALL',
    ch: '所有',
    en: 'All'
}, {
    key: 'FH',
    ch: '半場',
    en: 'First Half'
},{
    key: 'FT',
    ch: '全場',
    en: 'Full Time'
}, {
    key: 'CORNER',
    ch: '角球',
    en:'Corner'
},{
    key: 'PLAYER',
    ch: '球員',
    en:'Player'
},{
    key: 'SGA',
    ch: '同場過關',
    en:'SGA'
}]

export const configKeyMap = {
    "FH": "FirstHalfPools",
    "FT": "FullTimePools",
    "CORNER": "CornerPools",
    "PLAYER": "PlayerPools",
    "SGA": "SGAPools"
}

export const FBMatchTypeTab = ({allMatchTimeTypes, selected, onChange, match, selectedOddsType}) => {
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const tabStatus = [
        {
            type: "ALL",
            isShow: true
        },
        {
            type: "FT",
            isShow: getCurrentTypePools( "FT", match, selectedOddsType).length > 0
        },
        {
            type: "FH",
            isShow: getCurrentTypePools("FH", match, selectedOddsType).length > 0
        },
        {
            type: "CORNER",
            isShow: getCurrentTypePools("CORNER", match, selectedOddsType).length > 0
        },
        {
            type: "PLAYER",
            isShow: getCurrentTypePools("PLAYER", match, selectedOddsType).length > 0
        },
        {
            type: "SGA",
            isShow: getCurrentTypePools("SGA", match, selectedOddsType).length > 0
        }
    ]
    const showTab = tabStatus.filter(i => i.isShow).length >= 3
    useEffect(()=>{
        const showTab = tabStatus.filter(i => i.isShow).length >= 3
        if(!showTab){
            onChange('ALL')
        }
    },[match.foPools, selectedOddsType])
    return showTab ? <div className='FBMatchTypeTabContainer'>
        {
        allMatchTimeTypes.map((Item) => {
            let showCurTab = tabStatus.find(i => i.type == Item?.key )?.isShow
            if(showCurTab){
                return <div className={`FBMatchTypeTab ${selected === Item?.key ? 'active' : '' }`} onClick={() => onChange(Item?.key)}>{Item?.[lang]}</div>
            }
            return null
        })
        }
    </div> : null
}

export const getCurrentTypePools = ( type, match, selectedOddsType) =>{
    
    const currentTypePools = window.fbConfig?.[configKeyMap[type]]?.filter(betType => !selectedOddsType || selectedOddsType?.includes(betType)) || []
    return match?.foPools?.filter(pool => currentTypePools.includes(pool.oddsType) ) || []
}
