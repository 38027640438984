import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { isSsoLogin, setSsoLogout } from '../../../BetSlip/SsoHook';
import useBroadcastChannel from '../../../Common/hooks/useBroadcastChannel';
import { extendSession_IB } from '../../../BetSlip/BetSlipIBAPI';
import { isESInplayDelay } from '../../../Football/Common/MatchBase';
import { getFootballTopics } from './common';
import { isLogonHigh } from '../../../BetSlip/LoginIBHooks';

const useFootballPush = (contentRef, setContent) => {
    const { t } = useTranslation();
    const globalContext = useContext(GlobalContext);
    const msgCacheRef = useRef({})
    const { isPushing, resubscribeTopic, pubTopic, clientId, getCustTokenFunc, pubCacheTopic } = globalContext.mqttClient;
    const { copyContentRef, getEsData } = globalContext.esHook;
    const enableOddsPushRef = useRef(globalContext.globalState.commonCtrl.enableOddsPushButton)
    enableOddsPushRef.current = globalContext.globalState.commonCtrl.enableOddsPushButton && window.globalConfig.FB_ODDS_PUSH
    const ssoLogoutBoardcast = useBroadcastChannel('ssoLogoutNow');

    useEffect(()=> {
        if ( isPushing ) {
            resubscribe(contentRef);
        }
    }, [isPushing, isLogonHigh()])

    const resubscribe = (contentRef, disabledCache) => {
        const topics = getFootballTopics(contentRef, !enableOddsPushRef.current)
        if ( ['CASHOUT'].includes(contentRef.current.page) || enableOddsPushRef.current ) {
            copyContentRef(contentRef);
            if(topics.length >0 && !disabledCache && enableOddsPushRef.current){
                pubFBCacheTopic()
            }
            resubscribeTopic(topics);
        }
    }

    const pubFBCacheTopic =() =>{
        const topics = [
            {
                recoveryTopic: 'hk/d/evnt/sport/evt/01/crd/fb/tourn',
                requestAllIndicator: 0
            },
            {
                recoveryTopic: 'hk/d/evnt/sport/evt/01/upd/fb/tourn/*',
                requestAllIndicator: 0
            },
            {
                recoveryTopic: 'hk/d/evnt/sport/evt/01/crd/fb/match/*',
                requestAllIndicator: 0
            },
            {
                recoveryTopic: 'hk/d/evnt/sport/evt/01/upd/fb/*/*/*',
                requestAllIndicator: 0
            },
            {
                recoveryTopic: 'hk/d/prdt/wager/evt/01/crd/fb/fo/pool/*/*/*',
                requestAllIndicator: 0
            },
            {
                recoveryTopic: 'hk/d/prdt/wager/evt/01/upd/fb/fo/pool/*/*/*/*',
                requestAllIndicator: 0
            },
            {
                recoveryTopic: 'hk/d/prdt/wager/evt/01/crd/fb/fo/pool/*/*',
                requestAllIndicator: 0
            },
            {
                recoveryTopic: 'hk/d/prdt/wager/evt/01/upd/fb/fo/pool/*/*/*',
                requestAllIndicator: 0
            }
        ]
        pubCacheTopic(topics)
        // pubCacheTopic("hk/d/evnt/sport/evt/01/crd/fb/tourn", 0);
        // pubCacheTopic("hk/d/evnt/sport/evt/01/upd/fb/tourn/*", 0);
        // pubCacheTopic("hk/d/evnt/sport/evt/01/crd/fb/match/*", 0);
        // pubCacheTopic("hk/d/evnt/sport/evt/01/upd/fb/*/*/*", 0);
        // pubCacheTopic("hk/d/prdt/wager/evt/01/crd/fb/fo/pool/*/*/*", 0);
        // pubCacheTopic("hk/d/prdt/wager/evt/01/upd/fb/fo/pool/*/*/*/*", 0);
        // pubCacheTopic("hk/d/prdt/wager/evt/01/crd/fb/fo/pool/*/*", 0);
        // pubCacheTopic("hk/d/prdt/wager/evt/01/upd/fb/fo/pool/*/*/*", 0);
    }

    const pubESInit = () => {
        let openBets = getEsData();
        if ( openBets.length > 0 ) {
            let firstBetId = openBets[0].uniqueTicketId;
            let betIds = openBets.map(x => x.uniqueTicketId);
            let topicRequest = contentRef.current.config.esTopics["requestPrefix"].replace('{0}', sessionStorage.getItem('account')).replace('{1}', firstBetId);
            pubTopic(topicRequest + '/init', contentRef.current.config.esTopics["requestMessage"].replace('{0}', getCustTokenFunc()).replace('{1}', JSON.stringify(betIds)), topicRequest,clientId);
        }
    }

    const getCashoutMsg = (_betId, _esVal, _ipFlag, _txnId, _betline) => {
        var msg = {
            collationId: 1,
            jwt: getCustTokenFunc(),
            accountNumber: sessionStorage.getItem('account'),
            betId: _betId,
            earlySettleAmount: _esVal,
            earlySettleDevice: "JCBW",
            inplayFlag: _ipFlag,
            transactionId: _txnId,
            clientId: sessionStorage.getItem('session_id'),
            betline: _betline
        }
        return JSON.stringify(msg);
    }

    const pubESValue = async (content, item, submittedValue) => {
        if (!isSsoLogin()) {
            setSsoLogout();
            ssoLogoutBoardcast?.postMessage('ssoLogoutNow');
            window.alert(t('LB_BS_SSO_SIGN_OUT_PASSIVE'));
            return;
        }

        const resArr = await extendSession_IB();
        const result = resArr?.ExtendSessionTRResult?.find((i) => i.Key == 'success')?.Value;
        if (result == 0) {
            setSsoLogout();
            ssoLogoutBoardcast?.postMessage('ssoLogoutNow');
            window.alert(t('IB_ERROR_120'));
            return;
        }

        let inplay = isESInplayDelay(item);
        let topicRequest = content.config.esTopics[inplay ? "cashoutInplayPrefix" : "cashoutNormalPrefix"].replace('{0}', sessionStorage.getItem('account')).replace('{1}', item.uniqueTicketId);
        pubTopic(topicRequest + '/acpt',
            getCashoutMsg(item.uniqueTicketId, submittedValue, inplay, item.accountTranNo, item.betline),
            topicRequest,clientId);
    }

    return {
        resubscribe,
        pubESInit,
        pubESValue,
        msgCache: msgCacheRef.current
    };
}

export default useFootballPush