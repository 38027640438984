import React, { useEffect, useState, useContext } from 'react';
import FootballTitle from '../Common/FootballTitle';
import { useTranslation } from 'react-i18next';
import { filterMatchData, MatchInfo } from './ResultsBase';
import { FootballContext } from '../../Home/FootballPage';
import { useLazyQuery } from '@apollo/client';
import { getMatchResultDetailsQuery } from '../Data/DataQuery';
import { MatchDropdown, getNumberSuffix } from './MatchBase';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { isPoolPayoutOrRefund } from './Common';
import { isCRSOtherSels } from './CRSTable';

const SPCTable = ({ foPools }) => {
    const { t, i18n } = useTranslation();
    const mspPools = foPools.filter((x) => 'MSP' === x.oddsType);
    mspPools.sort((x, y) => {
        return x.instNo - y.instNo;
    });
    return (
        mspPools.length > 0 && (
            <div className="fb-result-detail-spc">
                <header className="normal-header">{t('LB_FB_RESULT_SPC_TITLE')}</header>
                <div className="results-spc-table">
                    <div>
                        <div>{t('LB_FB_RESULT_SPC_NO')}</div>
                        <div className="item-name">{t('LB_FB_RESULT_SPC_ITEM')}</div>
                        <div>{t('LB_FB_RESULT_SPC_RESULTS')}</div>
                    </div>
                    {mspPools.map((foPool, index) => {
                        const combs = foPool.lines[0]?.combinations?.filter((x) => x.status == 'WIN').sort((a,b) => a.winOrd - b.winOrd);
                        const refundCombs = foPool.lines[0]?.combinations?.filter(
                            (x) => x.status.indexOf('REFUND') >= 0
                        );
                        return (
                            <div className={index % 2 ? '' : 'sec'}>
                                <div>{foPool.instNo}</div>
                                <div className="item-name">{foPool['name_' + i18n.language]}</div>
                                {foPool.status.indexOf('REFUND') >= 0 ? (
                                    <div>
                                        <span className="lb-suspended">{t('LB_FB_RFD')}</span>
                                    </div>
                                ) : (
                                    <div>
                                        {combs.map((comb) => {
                                            let sel = comb.selections[0];
                                            return (
                                                <div>
                                                    ({sel.str}) {sel['name_' + i18n.language]}
                                                </div>
                                            );
                                        })}
                                        {refundCombs.map((comb) => {
                                            let sel = comb.selections[0];
                                            return (
                                                <div>
                                                    ({sel.str}) {sel['name_' + i18n.language]}{' '}
                                                    <span className="lb-suspended">{t('LB_FB_RFD')}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        )
    );
};

const getSGAResult = (t, foPool, comb) => {
    if (foPool.status.indexOf('REFUND') >= 0) return t('LB_FB_RESULT_SGA_REFUND');
    if (comb.status === 'WIN') return t('LB_FB_RESULT_SGA_WIN');
    if (comb.status === 'LOSE') return t('LB_FB_RESULT_SGA_LOSE');
    return '';
};

const SGATable = ({ foPools }) => {
    const { t, i18n } = useTranslation();
    const mspPools = foPools.filter((x) => 'SGA' === x.oddsType);
    mspPools.sort((x, y) => {
        return x.instNo - y.instNo;
    });
    return (
        mspPools.length > 0 && (
            <div className="fb-result-detail-spc">
                <header className="normal-header">{t('LB_FB_RESULT_SGA_TITLE')}</header>
                <div className="results-spc-table">
                    <div>
                        <div>{t('LB_FB_RESULT_SGA_NO')}</div>
                        <div className="item-name">{t('LB_FB_RESULT_SGA_ITEM')}</div>
                        <div>{t('LB_FB_RESULT_SGA_RESULTS')}</div>
                    </div>
                    {mspPools.map((foPool, index) => {
                        const comb = foPool.lines?.[0]?.combinations[0];
                        const isWin = comb.status === 'WIN';
                        const isLose = comb.status === 'LOSE';
                        const sel = comb?.selections[0];
                        return (
                            <div className={index % 2 ? '' : 'sec'}>
                                <div className="right-border">{foPool.instNo}</div>
                                <div className="item-name right-border">{sel['name_' + i18n.language]}</div>
                                <div>{getSGAResult(t, foPool, comb)}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        )
    );
};

const DetailRemark = () => {
    const { t } = useTranslation();
    return (
        <div className="footer-remark">
            <div>{t('LB_FB_RESULT_DETAIL_REMARK1')}</div>
            <div>{t('LB_FB_RESULT_DETAIL_REMARK2')}</div>
            <div>{t('LB_FB_RESULT_DETAIL_REMARK3')}</div>
            <div>{t('LB_FB_RESULT_DETAIL_REMARK4')}</div>
        </div>
    );
};

const NTSTable = ({ foPools, betTypes, classIdx, hName, aName }) => {
    const { t, i18n } = useTranslation();

    const ntsPools = foPools.filter((x) => betTypes.includes(x.oddsType));
    ntsPools.sort((x, y) => {
        return x.instNo - y.instNo;
    });
    const leftTitle = t('LB_FB_RESULT_' + betTypes[0]);
    const rightTitle = t('LB_FB_RESULT_SCORING_TEAM');
    return (
        ntsPools.length > 0 && (
            <div className={`results-nts ${classIdx % 2 ? '' : 'sec'}`}>
                <div className="results-nts-table">
                    <div>{leftTitle}</div>
                    <div>{rightTitle}</div>
                    {ntsPools.map((foPool) => {
                        let ngoal = `${foPool.instNo}${i18n.language == 'en' ? getNumberSuffix(foPool.instNo) : ''}`;
                        const combs = foPool.lines[0]?.combinations?.filter((x) => x.status == 'WIN').sort((a,b) => a.winOrd - b.winOrd);
                        return (
                            <>
                                <div>{t('LB_FB_N_GOAL').replace('{0}', ngoal)}</div>
                                <div>
                                    <GetPoolResultValue
                                        betType={foPool.oddsType}
                                        foPool={foPool}
                                        combs={combs}
                                        hName={hName}
                                        aName={aName}
                                    />
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        )
    );
};

const MatchResultTable = ({ isET, match, foPools }) => {
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const detailCfg = context.content.config.ResultDetailResultTable;
    const betTypeTitle = isET ? t('LB_FB_RESULT_ET_BETTYPE') : t('LB_BET_TYPE');
    const leftData = isET ? detailCfg.eLeft : detailCfg.left;
    const rightData = isET ? detailCfg.eRight : detailCfg.right;
    const ntsPools = isET ? ['ENT', 'ETS'] : ['NTS'];
    const showLeft = foPools.filter((x) => leftData.includes(x.oddsType)).length > 0;
    const showRight = foPools.filter((x) => [...rightData, ...ntsPools].includes(x.oddsType)).length > 0;

    const { hName, aName } = filterMatchData(match, t, i18n);

    let leftRowNumber = 0;
    let rightRowNumber = 0;
    return (
        <div className="matchResultTable">
            <div>
                <div className="resultTableLeft">
                    {showLeft > 0 && (
                        <>
                            <header className="matchResultTable-header">
                                <div>{betTypeTitle}</div>
                                <div>{t('LB_FB_TITLE_RESULTS_MR')}</div>
                            </header>
                            {leftData.map((item) => {
                                const foPool = foPools.filter((x) => item === x.oddsType)[0];
                                if (foPool != null) {
                                    const combs = foPool.lines[0]?.combinations?.filter((x) => x.status == 'WIN').sort((a,b) => a.winOrd - b.winOrd);
                                    leftRowNumber++;
                                    return foPool.status.indexOf('REFUND') >= 0 || combs.length > 0 ? (
                                        <>
                                            {
                                                <div className={leftRowNumber % 2 ? '' : 'sec'}>
                                                    <div className="right-border">{t(`LB_FB_RESULT_${item}`)}</div>
                                                    <div>
                                                        <GetPoolResultValue
                                                            betType={item}
                                                            foPool={foPool}
                                                            combs={combs}
                                                            hName={hName}
                                                            aName={aName}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    ) : null;
                                }
                            })}
                        </>
                    )}
                </div>
                {showRight > 0 && (
                    <div className="resultTableRight">
                        <header className="matchResultTable-header mobile-hide">
                            <div>{betTypeTitle}</div>
                            <div>{t('LB_FB_TITLE_RESULTS_MR')}</div>
                        </header>
                        {rightData.map((item) => {
                            const foPool = foPools.filter((x) => item === x.oddsType)[0];
                            if (foPool != null) {
                                const combs = foPool.lines[0]?.combinations?.filter((x) => x.status == 'WIN').sort((a,b) => a.winOrd - b.winOrd);
                                rightRowNumber++;
                                return foPool.status.indexOf('REFUND') >= 0 || combs.length > 0 ? (
                                    <>
                                        {
                                            <div className={rightRowNumber % 2 ? '' : 'sec'}>
                                                <div className="right-border">{t(`LB_FB_TITLE_${item}`)}</div>
                                                <div>
                                                    <GetPoolResultValue
                                                        betType={item}
                                                        foPool={foPool}
                                                        combs={combs}
                                                        hName={hName}
                                                        aName={aName}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </>
                                ) : null;
                            }
                            return <></>;
                        })}
                        <NTSTable
                            foPools={foPools}
                            betTypes={ntsPools}
                            hName={hName}
                            aName={aName}
                            classIdx={rightRowNumber}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const GetPoolResultValue = ({ betType, foPool, combs, hName, aName }) => {
    const { t, i18n } = useTranslation();
    const combs_sorted = Array.isArray(combs) ? [...combs] : [];
    combs_sorted.sort((a, b) => a.winOrd - b.winOrd);

    if (foPool == null) return null;
    if (foPool.status.indexOf('REFUND') >= 0) return t('LB_FB_RFD');
    if (combs_sorted == null || combs_sorted.length == 0) return null;

    switch (betType) {
        case 'HAD':
        case 'EHA':
        case 'FHA':
        case 'OOE':
            return combs_sorted.map((comb) => <div>{t(`LB_FB_RESULTS_WIN_${comb.str}`)}</div>);
        case 'CRS':
        case 'FCS':
        case 'ECS':
            return combs_sorted.map((comb) => {
                if (isCRSOtherSels(comb?.selections[0]?.str)) {
                    return <div>{t('LB_BS_ADDTOSLIP_FB_' + comb.selections[0].str)}</div>;
                }
                const [hScore, aScore] = comb.str.split(':');
                return <div>{`${parseInt(hScore)} : ${parseInt(aScore)}`}</div>;
            });
        case 'HFT':
            return combs_sorted.map((comb) => {
                const [h, f] = comb.str.split(':');
                return <div>{`${t(`LB_FB_${h}_S`)}-${t(`LB_FB_${f}_S`)}`}</div>;
            });
        case 'FGS':
            return (
                <>
                    {combs_sorted.map((comb) => {
                        let sel = comb.selections[0];
                        return (
                            <div>
                                {sel.str} {sel['name_' + i18n.language]}
                            </div>
                        );
                    })}
                </>
            );
        case 'FTS':
        case 'NTS':
        case 'ETS':
        case 'ENT':
            return combs_sorted.map((comb) =>
                comb.str === 'H' ? (
                    <div>{`1 ${hName}`}</div>
                ) : comb.str === 'A' ? (
                    <div>{`2 ${aName}`}</div>
                ) : (
                    <div>{`N ${t('LB_FB_RESULT_NO_GOALS')}`}</div>
                )
            );
        case 'TQL':
            return combs_sorted.map((comb) =>
                comb.str === 'H' ? <div>{`${hName}`}</div> : comb.str === 'A' ? <div>{`${aName}`}</div> : ''
            );
    }
    return combs_sorted.map((comb) => <div>{comb.str}</div>);
};

const ResultDetail = ({ match, matchList, onClickBackBtn, onClickResultDropdown }) => {
    const { t, i18n } = useTranslation();
    const context = useContext(FootballContext);
    const detailCfg = context.content.config.ResultDetailResultTable;
    const pageLimit = context.content.config.MaxSearchResultPaginationLimit;
    const [displayState, setDisplayState] = useState({
        foPools: null,
        showHalfCorner: false,
        refundHalfCorner: false,
        showCorner: false,
        refundCorner: false,
        showCornerET: false,
        refundCornerET: false,
        showTable: false,
        showTableET: false
    });
    const [apiErr, setApiErr] = useState(false);
    const [isCanRefresh, setIsCanRefresh] = useState(true);

    useEffect(() => {
        const standardPageScrollbars = document.querySelector('.StandardPage-scrollbars > :first-child');
        if(standardPageScrollbars) standardPageScrollbars.scrollTop = 0;
        loadQuery();
        const refreshEle = document.getElementById('root');
        const listener = (e) => {
            if (isCanRefresh && e.target.className === `refreshIcon`) {
                setIsCanRefresh(false);
                loadQuery();
            }
            e.stopPropagation();
        };
        refreshEle.addEventListener('mousedown', listener);
        refreshEle.addEventListener('touchstart', listener);
        return () => {
            refreshEle.removeEventListener('mousedown', listener);
            refreshEle.removeEventListener('touchstart', listener);
        };
    }, []);

    const [loadQuery, dtlsGqlVars] = useLazyQuery(getMatchResultDetailsQuery(), {
        variables: { matchId: match.id },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setApiErr(false);
            setIsCanRefresh(true);
            let m = data.matches[0];
            if (m != null) {
                displayState.additionalResults = m.additionalResults;
                displayState.foPools = m.foPools;
                let fchPool = m.foPools.filter((x) => x.oddsType == 'FCH')[0];
                displayState.showHalfCorner = fchPool != null;
                displayState.refundHalfCorner = fchPool?.status.indexOf('REFUND') >= 0;
                let chlPool = m.foPools.filter((x) => x.oddsType == 'CHL')[0];
                displayState.showCorner = chlPool != null;
                displayState.refundCorner = chlPool?.status.indexOf('REFUND') >= 0;
                let echPool = m.foPools.filter((x) => x.oddsType == 'ECH')[0];
                displayState.showCornerET = echPool != null;
                displayState.refundCornerET = echPool?.status.indexOf('REFUND') >= 0;
                let displayPools = [...detailCfg.left, ...detailCfg.right, 'NTS'];
                displayState.showTable =
                    m.foPools.filter((x) => displayPools.includes(x.oddsType) && isPoolPayoutOrRefund(x)).length > 0;
                let displayPoolsET = [...detailCfg.eLeft, ...detailCfg.eRight, 'ENT', 'ETS'];
                displayState.showTableET =
                    m.foPools.filter((x) => displayPoolsET.includes(x.oddsType) && isPoolPayoutOrRefund(x)).length > 0;

                setDisplayState({ ...displayState });
            }
        },
        onError: (err) => {
            setIsCanRefresh(true);
            setApiErr(true);
        }
    });

    let {
        halfTime,
        fullTime,
        extraTime,
        halfCorner,
        fullCorner,
        extraCorner,
        abandonedScore,
        abandonedCornerResult,
        hasHalfTimeCorner,
        hasFullTimeCorner,
        hasExtraTimeCorner,
        hasAbnCornerResult,
        isVoid
    } = filterMatchData(match, t, i18n);

    const Results = ({ isET, title, isABD }) => {
        const showCorner = !isET ? displayState.showHalfCorner || displayState.showCorner : displayState.showCornerET;
        hasHalfTimeCorner = (hasHalfTimeCorner && displayState.showHalfCorner) || displayState.refundHalfCorner;
        hasFullTimeCorner = (hasFullTimeCorner && displayState.showCorner) || displayState.refundCorner;
        hasExtraTimeCorner = (hasExtraTimeCorner && displayState.showCornerET) || displayState.refundCornerET;
        function additionalResultsDivs(stageId, isCorner = false) {
            const output = displayState?.additionalResults?.map((resSet) => {
                if (!isCorner) {
                    // goal result
                    const res = resSet.results.find(
                        (item) => item.stageId == stageId && item.resultType == 1 && !item.mask
                    );
                    return res ? <div>{res.homeResult + ' : ' + res.awayResult}</div> : <></>;
                } else {
                    // corner result
                    const res = resSet.results.find(
                        (item) => item.stageId == stageId && item.resultType == 2 && !item.mask
                    );
                    return res ? <div>{Number(res.homeResult) + Number(res.awayResult)}</div> : <></>;
                }
            });
            return output;
        }

        return (
            <div className="results-detail-results">
                <header className="normal-header">{title}</header>
                <div className="score-row">
                    {!isET && !isABD && (
                        <>
                            <div className="right-border">{t('LB_FB_RESULT_HTSR')}</div>
                            <div className="multiple-results">
                                <div>{halfTime}</div>
                                {additionalResultsDivs(3)}
                            </div>
                            <div className="mobile-hide right-border">{t('LB_FB_RESULT_FTSR')}</div>
                            <div className="mobile-hide multiple-results">
                                <div>{fullTime}</div>
                                {additionalResultsDivs(5)}
                            </div>
                        </>
                    )}
                    {isET && !isABD && (
                        <>
                            <div className="right-border">{t('LB_FB_RESULT_ETSR')}</div>
                            <div className="multiple-results">
                                <div>{extraTime}</div>
                                {additionalResultsDivs(9)}
                            </div>
                            <div className="mobile-hide"></div>
                            <div className="mobile-hide"></div>
                        </>
                    )}
                    {isABD && (
                        <>
                            <div className="right-border">{t('LB_FB_RESULT_SCORE_RESULT')}</div>
                            <div className="multiple-results">
                                <div>{abandonedScore}</div>
                                {additionalResultsDivs(100)}
                            </div>
                            <div className="mobile-hide"></div>
                            <div className="mobile-hide"></div>
                        </>
                    )}
                </div>
                {!isET && !isABD && (
                    <div className="mobile-show score-row sec">
                        <div className="right-border">{t('LB_FB_RESULT_FTSR')}</div>
                        <div className="multiple-results">
                            <div>{fullTime}</div>
                            {additionalResultsDivs(5)}
                        </div>
                    </div>
                )}

                {/* showCorner &&  SQ0248-3269*/(hasHalfTimeCorner || hasFullTimeCorner || hasExtraTimeCorner || isABD) ? (
                    isABD ? (
                        hasAbnCornerResult ? (
                            <div className="corner-row">
                                <div className="right-border">{t('LB_FB_RESULT_CORNER_RESULT')}</div>
                                <div className="multiple-results">
                                    <div>{abandonedCornerResult}</div>
                                    {additionalResultsDivs(100, true)}
                                </div>
                                <div className="mobile-hide"></div>
                                <div className="mobile-hide"></div>
                            </div>
                        ) : null
                    ) : hasHalfTimeCorner || hasFullTimeCorner || hasExtraTimeCorner ? (
                        !isET && (hasHalfTimeCorner || hasFullTimeCorner) ? (
                            <>
                                <div className={`corner-row ${hasHalfTimeCorner ? 'hasHalfTimeCorner' : ''}`}>
                                    {hasHalfTimeCorner ? (
                                        <>
                                            <div className="right-border">{t('LB_FB_RESULT_HALF_CORNER_RESULT')}</div>

                                            <div className="multiple-results">
                                                {displayState.refundHalfCorner ? (
                                                    <div>{t('LB_FB_RFD')}</div>
                                                ) : (
                                                    <div>
                                                        <div>{halfCorner}</div>
                                                        {additionalResultsDivs(3, true)}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ) : null}
                                    {hasFullTimeCorner ? (
                                        <>
                                            {
                                                !hasHalfTimeCorner && <><div className='mobile-hide'></div><div className='mobile-hide'></div></>
                                            }
                                            <div className={`right-border ${hasHalfTimeCorner ? 'mobile-hide' : ''}`}>
                                                {t('LB_FB_RESULT_TOTAL_CORNER_TAKEN')}
                                            </div>
                                            <div className={`${hasHalfTimeCorner ? 'mobile-hide' : ''}`}>
                                                <div className="multiple-results">
                                                    {displayState.refundCorner ? (
                                                        <div>{t('LB_FB_RFD')}</div>
                                                    ) : (
                                                        <>
                                                            <div>{fullCorner}</div>
                                                            {additionalResultsDivs(5, true)}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                                {hasHalfTimeCorner && hasFullTimeCorner ? (
                                    <div className="mobile-show corner-row">
                                        <div className="right-border">{t('LB_FB_RESULT_TOTAL_CORNER_TAKEN')}</div>

                                        <div className="multiple-results">
                                            {displayState.refundCorner ? (
                                                <div>{t('LB_FB_RFD')}</div>
                                            ) : (
                                                <>
                                                    <div>{fullCorner}</div>
                                                    {additionalResultsDivs(5, true)}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        ) : isET && hasExtraTimeCorner ? (
                            <div className="corner-row">
                                <div className="right-border">{t('LB_FB_RESULT_ET_TOTAL_CORNER_TAKEN')}</div>
                                <div>
                                    <div className="multiple-results">
                                        {displayState.refundCornerET ? (
                                            <div>{t('LB_FB_RFD')}</div>
                                        ) : (
                                            <>
                                                <div>{extraCorner}</div>
                                                {additionalResultsDivs(9, true)}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="mobile-hide"></div>
                                <div className="mobile-hide"></div>
                            </div>
                        ) : null
                    ) : null
                ) : null}
            </div>
        );
    };

    if (apiErr) {
        return <div className="noInfoTip">{t('LB_RC_JTC_NOINFO')}</div>;
    }
    const showAbandonET = fullTime != '-' && (abandonedScore !== '-' || abandonedCornerResult !== '-')
    return (
        <div className="fb-result-detail-container">
            <header className="fb-result-detail-header">
                <FootballTitle title={t('LB_FB_RESULT_RESULTS_DETAILS')} isPoolDefined={true} />
            </header>

            <div className="mobile-top-title">
                <ArrowIcon className="hueRotate" onClick={onClickBackBtn} direction="left" size={24} />
                {t('LB_FB_RESULT_RESULTS_DETAILS')}
            </div>

            <div className="top-header-container">
                <div className="back-btn" onClick={onClickBackBtn}>
                    <ArrowIcon className="" direction="left" size={24} />
                    <span>{t('LB_FB_PRE_PAGE')}</span>
                </div>
                <div className="ddNoRSpace">
                    <MatchDropdown
                        singleMatch={match}
                        matchList={matchList}
                        onClickResultDropdown={onClickResultDropdown}
                    />
                </div>
            </div>
            <div className="fb-result-detail-full-time">
                <MatchInfo match={match} isResultPage={true} />
                <Results title={t('LB_FB_TITLE_RESULTS')} />
                {fullTime == '-' && (abandonedScore !== '-' || abandonedCornerResult !== '-') && (
                    <div>
                        <Results isABD={true} title={t(`LB_FB_RESULT_VOID_MATCH_TABLE_TITLE`)} isET={false} />
                    </div>
                )}
                {displayState.showTable && <MatchResultTable match={match} foPools={displayState.foPools} />}
            </div>
            {(displayState.showTableET || extraTime != '-' || showAbandonET) && (
                <div className="fb-result-detail-extra-time">
                    {(extraTime != '-' || showAbandonET ||  displayState.showTableET) && <Results isET title={t('LB_FB_RESULT_ET_TITLE')} /> }
                    {showAbandonET && (
                        <div>
                            <Results isABD={true} title={t(`LB_FB_RESULT_VOID_MATCH_TABLE_TITLE_ET`)} isET={true} />
                        </div>
                    )}
                    {displayState.showTableET && <MatchResultTable isET match={match} foPools={displayState.foPools} />}
                </div>
            )}
            {displayState.foPools && <SPCTable foPools={displayState.foPools} />}
            {displayState.foPools && <SGATable foPools={displayState.foPools} />}
            {isVoid && <DetailRemark />}
        </div>
    );
};

export default ResultDetail;
