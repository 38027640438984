import React, { useEffect, useState, memo, useCallback, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SingleLineUpMarquee from '../Common/ui-components/SingleLineUpMarquee';
import importantIcon from '../../info/Include/images/sms_failed.svg';
import { NewWindow } from '../Common/home-common';
import { getImportantNoticesProduct, getMsgInboxProducts } from '../Home/Data/DataQuery';
import { useImportantNoticeQuery, useFootballRemarksQuery } from '../Home/Data/DataRefreshHooks';
import { GlobalContext } from '../../contexts/GlobalContext';
import { RacingContext } from '../Home/RacingPage';
import { filterChangeItems } from '../Racing/Common/Common';

const ImportantNotice = ({ enable, product }) => {
    const { t, i18n } = useTranslation();
    const globalContext = useContext(GlobalContext);
    const racingContext = useContext(RacingContext);
    const lang = i18n.language;
    const localeLang = lang === 'ch' ? 'zh-hk' : 'en-us';
    const [notice, setNotice] = useState([]);
    const [loadImportantNotices, importantNoticesLoading, importantNoticesData, remarksLoading, remarksData] =
        useImportantNoticeQuery();

    useEffect(() => {
        if (enable && lang && product) {
            loadImportantNotices(getMsgInboxProducts(getImportantNoticesProduct(product)));
        }
    }, [lang, product]);

    // for importantNotice rerender
    useEffect(() => {
        let newNotices = [];
        if (!importantNoticesLoading && importantNoticesData) {
            let msgs = importantNoticesData.filter(x=> x.categories.includes(getImportantNoticesProduct(product)));
            msgs.sort((x, y) => {
                return moment(y.dt) - moment(x.dt);
            });
            let notices = msgs.reduce((array, remark) => {
                if(remark?.locations?.includes("YellowBanner")){
                    let msg = remark.contents.filter((x) => x.lang === localeLang)[0];
                    array.push(msg?.content || '')
                }
                return array
            },[]);
            if (notices) {
                newNotices = [...notices];
            }
        }

        if (!remarksLoading && remarksData) {
            let nowDt = moment().add(remarksData.timeOffset?.fb, "seconds");
            let remarks = remarksData.matches?.filter(match => {
                return moment(match.kickOffTime).add(1, 'days') > nowDt;
            }).map((match) => {
                let msg = match.adminOperation.remark.cont.filter((x) => x.lang === localeLang)[0];
                return msg?.content || '';
            });
            if (remarks) {
                newNotices = [...newNotices, ...remarks];
            }
        }

        if (!importantNoticesLoading || !remarksLoading) {
            setNotice([...newNotices]);
        }

    }, [importantNoticesData, importantNoticesLoading, remarksLoading, remarksData, localeLang]);

    const getProduckCTAClassName = () => {
        const product = globalContext?.globalState?.product
        switch(product){
            case 'racing':
                return 'cta_hr'
            case 'football':
                return 'cta_fb'
            case 'marksix':
                return 'cta_m6'
            default :
                return 'cta_brand'
        }
    }


    const moreClickHandle = useCallback(() => {
        if (lang && product) {
            const w = Math.min(640, window.innerWidth);
            const h = 506;
            NewWindow(
                `/${lang}/msgInbox${product === 'ewin' ? '' : '/' + product}`,
                String(product) || 'ImprtantNotice',
                w,
                h,
                1,
                1
            );
        }
    }, [lang, product]);

    const importantNoticeRef = useRef();
    const timer = useRef(null);
    useEffect(() => {
        const handleResizeHandle = () => {
            timer.current !== null && clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                const width = document.getElementById('betslip-container')?.getBoundingClientRect()?.left;
                if (importantNoticeRef.current && notice && notice.length > 0 && width) {
                    importantNoticeRef.current.style.width = width + 'px';
                    importantNoticeRef.current.style.position = 'absolute';
                    importantNoticeRef.current.style.left = '0';
                }
            }, 250);
        };
        handleResizeHandle();
        window.addEventListener('resize', handleResizeHandle);
        return () => window.removeEventListener('resize', handleResizeHandle);
    }, [notice]);

    const haveInboxMsg = () => {
        let msgCnt = 0;
        switch(product){
            case 'racing':
                msgCnt = importantNoticesData?.filter(x=> x.categories.some(y => ["GL", "HR"].includes(y)) && x?.locations?.includes("MessageBox")).length;
                let chgCnt = racingContext.content.meeting.changeHistories.filter(x=> filterChangeItems(x)).length;
                return msgCnt + chgCnt > 0;
            case 'football':
                msgCnt = importantNoticesData?.filter(x=> x.categories.some(y => ["GL", "FB"].includes(y)) && x?.locations?.includes("MessageBox")).length;
                let nowDt = moment().add(remarksData?.timeOffset?.fb, "seconds");
                let rmkCnt = remarksData?.matches?.filter(match => {
                    return moment(match.kickOffTime).add(1, 'days') > nowDt;
                }).length;
                return  msgCnt + rmkCnt > 0;
            case 'marksix':
                return importantNoticesData?.filter(x=> x.categories.some(y => ["GL", "LY"].includes(y)) && x?.locations?.includes("MessageBox")).length > 0;
            default :
                return importantNoticesData?.filter(x=> x.categories.some(y => ["GL"].includes(y)) && x?.locations?.includes("MessageBox")).length > 0;
        }
    }

    if (!enable || notice.length == 0) {
        return <div></div>;
    } else {
        return (
            <div style={{ height: 32 }}>
                <div
                    ref={importantNoticeRef}
                    className={`important-notice show ${notice.length > 0 ? '' : 'collapsing'}`}
                >
                    <div className="icon">
                        <img src={importantIcon} />
                    </div>
                    <div className="message">
                        <SingleLineUpMarquee data={notice} />
                    </div>
                    {haveInboxMsg() && <div className={`more ${getProduckCTAClassName()} ${product}`} onClick={moreClickHandle}>
                        {t('LB_BMENU_DETAILS')}
                    </div>}
                </div>
            </div>
        );
    }
};
export default memo(ImportantNotice);
