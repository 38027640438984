import React, {useState, useRef, useEffect, useCallback, useContext, createContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import ImportantNotice from '../Header/ImportantNotice';
import { GlobalContext } from '../../contexts/GlobalContext';
import { useQuery } from '@apollo/client';
import { getEwinHomeMarksixBlockQuery } from '../Marksix/Data/DataQuery';
import { getRacingBlockQuery } from '../Racing/Data/DataQuery';
import NewsSlider from '../Common/ui-components/NewsSlider';
import NewsBanner from '../Common/ui-components/NewsBanner';

import { useWindowSize } from '../Common/CommonHooks';
import EwinHomeRacingBlock from '../Racing/Common/EwinHomeRacingBlock';
import EwinHomeTTQuickPick from '../Racing/Common/EwinHomeTTQuickPick';
import EwinHomeFootballBlock from '../Football/Common/EwinHomeFootballBlock';
import EwinHomeSGATable from '../Football/Common/EwinHomeSGATable';
import EwinHomeMarksixBlock from '../Marksix/Common/EwinHomeMarksixBlock';
import EwinHomeMarksixQuickPick from '../Marksix/Common/EwinHomeMarksixQuickPick';
import EwinHomeUsefulLink from '../Common/EwinHomeUsefulLink';
import { useEwinUsefulLinksQuery } from '../Home/Data/SitecoreDataHooks';
import RefreshTime from '../Common/ui-components/RefreshTime';
import { errRedirectPage } from '../Common/utils';
import useResizeObserver from '../Common/hooks/useResizeObserver';

export const LandingContext = createContext();

const LandingPage = () => {
    const { t, i18n } = useTranslation();
    const windowSize = useWindowSize();
    const globalContext = useContext(GlobalContext)
    const { loading : usefulLinksLoading, getUsefulLinksItems, getRacingEosMesssage } = useEwinUsefulLinksQuery();
    const curProduct = 'ewin';
    const allowReload = useRef(true);
    const [ updateContent, setUpdateContent ] = useState(0);

    useEffect(()=>{
        globalContext.updateGlobalState({ product: curProduct, betType: 'HOME' });
    },[window.location.pathname])

    const racingData = useQuery(getRacingBlockQuery(), {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError:err=>{
            //errRedirectPage(err);
        }
    });

    const marksixData = useQuery(getEwinHomeMarksixBlockQuery(), {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError:err=>{
            //errRedirectPage(err);
        }
    });

    const onClickRefreshButton = () => {
        if(!document.querySelector('.refreshIcon')?.classList.contains('rotate')) {
            document.querySelector('.refreshIcon')?.classList?.add('rotate')
            setTimeout(() => {
                document.querySelector('.refreshIcon')?.classList?.remove('rotate')
            }, 1200)
        }
        if ( !allowReload.current )
            return;
        setUpdateContent(Math.random());
        allowReload.current = false;
        setTimeout(()=> {
            allowReload.current = true;
        }, 30000)
    }

    // 下面處理大屏幕首頁内容不夠時，減少betslip高度，一屏可以展示全部内容處理邏輯
    const handleBetslipHeight = () => {
        const ewinMainOuterHeight = document.querySelector('.ewinMainOuter')?.offsetHeight
        const footerHeight = document.querySelector('.footer')?.offsetHeight
        if(ewinMainOuterHeight && footerHeight) {
            const ewinMainOuterBottomHeight = window.innerHeight - ewinMainOuterHeight - 32 - 44  // 32 fMenuTR, 44 topInfoMenu
            if(ewinMainOuterBottomHeight > 15) { // 15 gap
                const height = Math.min(ewinMainOuterBottomHeight-15, footerHeight)
                if(document.querySelector('#betslip-container')){
                    document.querySelector('#betslip-container').style.height = `calc(100vh - ${height}px - 91px)`
                }
                if(document.querySelector('#divBetSlipNormal')){
                    document.querySelector('#divBetSlipNormal').style.height = `calc(100vh - ${height}px - 47px)`
                }
            } else {
                if(document.querySelector('#betslip-container')){
                    document.querySelector('#betslip-container').style.height = null
                }
                if(document.querySelector('#divBetSlipNormal')){
                    document.querySelector('#divBetSlipNormal').style.height = null
                }
            }
        }
    }

    const ewinMainOuterHeight = document.querySelector('.ewinMainOuter')?.offsetHeight
    const footerHeight = document.querySelector('.footer')?.offsetHeight
    useEffect(()=>{
        handleBetslipHeight()
        return () => {
            if(document.querySelector('#betslip-container')){
                document.querySelector('#betslip-container').style.height = null
            }
            if(document.querySelector('#divBetSlipNormal')){
                document.querySelector('#divBetSlipNormal').style.height = null
            }
        }
    },[ewinMainOuterHeight, footerHeight])

    useResizeObserver('.ewinMainOuter', handleBetslipHeight)
    useResizeObserver('.footer', handleBetslipHeight)
    // end

    const HomeBlock = useCallback(() => {
        return <>
            <Helmet>
                <title>{t('LB_EWIN_TITLE')}</title>
                <meta name="description" content={t('LB_EWIN_DESC')} />
            </Helmet>
            {/* <RefreshTime product="ewin" onClickRefreshButton={onClickRefreshButton} /> */}
            <div className="ewinInfoGrid">
                <div><EwinHomeRacingBlock /></div>
                <div><EwinHomeTTQuickPick getRacingEosMesssage={getRacingEosMesssage} /></div>
                <div>{!usefulLinksLoading && <EwinHomeUsefulLink linkData={getUsefulLinksItems(window.sitecoreVars.RACING)} product="HR" />}</div>

                <div><EwinHomeFootballBlock /></div>
                <div><EwinHomeSGATable /></div>
                <div>{!usefulLinksLoading && <EwinHomeUsefulLink linkData={getUsefulLinksItems(window.sitecoreVars.FOOTBALL)} product="FB" />}</div>

                <div><EwinHomeMarksixBlock /></div>
                <div><EwinHomeMarksixQuickPick /></div>
                <div>{!usefulLinksLoading && <EwinHomeUsefulLink linkData={getUsefulLinksItems(window.sitecoreVars.MARKSIX)} product="LY" />}</div>
            </div>
        </>
    }, [updateContent, usefulLinksLoading]);

    return(
        <LandingContext.Provider value={{ racingData, marksixData, windowSize }}>
            <div className='ewinMainOuter'>
                <ImportantNotice product={curProduct} enable={true} />
                <div className="ewinMain">
                    {/* <Banner Product={curProduct} enable={true} /> */}
                    {!window.globalConfig.IS_EWIN2 && <NewsBanner/>}
                    {!window.globalConfig.IS_EWIN2 && <NewsSlider/>}
                    <HomeBlock />
                </div>
            </div>
        </LandingContext.Provider>
    )
}
export default LandingPage;