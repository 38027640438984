import { GetBetslipConfig } from '../../Common/ConfigHelper';

const getSession = (key) => {
    return sessionStorage.getItem(key);
};
const getSessionNumber = (key) => {
    const val = getSession(key);
    if (val) return Number(val);
};

const getEpsCharge = (eftMethod, accountTypeIndication, isFPSOnline, isSingleLeg) => {
    if (isFPSOnline && !accountTypeIndication.includes('EFT White-listed Account')) {
        if (eftMethod == 'deposit' && accountTypeIndication.includes('EFT Deposit Fee Enablement Indicator')) {
            return true;
        }

        if (eftMethod == 'withdrawal' && accountTypeIndication.includes('EFT Withdrawal Fee Enablement Indicator')) {
            return true;
        }
    }
    return false;
};

export const getNbaSessionStoragePara = () => {
    const FPSParams = JSON.parse(JSON.parse(getSession('FPSParams')).FPSParams);
    const deposit = {
        FPS: {
            perDepMinAmt: FPSParams.perDepMinAmt / 100,
            perDepMaxAmt: FPSParams.perDepMaxAmt / 100,
            serviceChargeAmount: FPSParams.perDepChargeAmt / 100,
            monthlyDepMaxCntNoCharge: FPSParams.monthlyDepMaxCntNoCharge,
            monthlyDepMaxCntChargeAmt: FPSParams.monthlyDepMaxCntChargeAmt / 100,
            timeout: getSessionNumber('FPSReplyTimeout'),
            linkDisabled: window.sessionStorage.getItem('FPSLinkBankAcc') == '1' ? false : true
        },
        EPS: {
            TBMinDeposit: getSessionNumber('TBMinDeposit'),
            TBMaxDeposit: getSessionNumber('TBMaxDeposit'),
            timeout: getSessionNumber('EFTReplyTimeout')
        }
    };
    const withdrawal = {
        FPS: {
            perWdrMinAmt: FPSParams.perWdrMinAmt / 100,
            perWdrMaxAmt: FPSParams.perWdrMaxAmt / 100,
            perWdrChargeAmt: FPSParams.perWdrChargeAmt / 100,
            dailyWdrMaxAmt: FPSParams.dailyWdrMaxAmt / 100,
            dailyWdrMaxCnt: FPSParams.dailyWdrMaxCnt,
            timeout: getSessionNumber('FPSReplyTimeout')
        },
        EPS: {
            TBMinWithdrawal: getSessionNumber('TBMinWithdrawal'),
            TBMaxWithdrawal: getSessionNumber('TBMaxWithdrawal'),
            timeout: getSessionNumber('EFTReplyTimeout'),
            charge: getSessionNumber('Charge')
        },
        AUTOPAY: {
            TBMinAutoWithdrawal: getSessionNumber('TBMinAutoWithdrawal'),
            TBMaxAutoWithdrawal: getSessionNumber('TBMaxAutoWithdrawal'),
            timeout: getSessionNumber('EFTReplyTimeout')
        }
    };
    return { deposit, withdrawal };
};

const maskBankAccount = (bankAccountNo) => {
    bankAccountNo = bankAccountNo + '';
    if (bankAccountNo) {
        const first = bankAccountNo.substr(0, 3);
        const last = bankAccountNo.substr(bankAccountNo.length - 2);
        const mask = bankAccountNo.substr(3, bankAccountNo.length - 3 - 2).replace(/\d/g, '*');

        return first + mask + last;
        // retrurn bankAccountNo.replace(/(?<=.{3})\d(?=.{2})/g, '*')
    }
    return '';
};

export const maskPhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
        const last = phoneNumber.substr(phoneNumber.length - 4);
        const mask = phoneNumber.substr(0, phoneNumber.length - 4).replace(/\d/g, '*');

        return mask + last;
    }
    return '';
};

export const getNbaData = ({ nbaData, FPSInfo, bankList, FPSErrorList }) => {
    const nba = {};
    const nbaConfig = getNbaSessionStoragePara();
    nba.deposit = {
        config: {
            ...nbaConfig?.deposit,
            FPS: {
                ...nbaConfig?.deposit?.FPS,
                currentMonthlyDepCnt: FPSInfo?.currentMonthlyDepCnt
            },
            PPS: {
                PpsCharge: getSessionNumber('PpsCharge'),
                TransferUpperRange: getSessionNumber('TransferUpperRange'),
                TransferLowerRange: getSessionNumber('TransferLowerRange'),
                MinutesBeforeEOD: getSessionNumber('MinutesBeforeEOD')
            },
            otherMeansEnabled: GetBetslipConfig().depositOtherMeansEnabled
        }
    };
    nba.withdrawal = {
        config: {
            ...nbaConfig?.withdrawal,
            otherMeansEnabled: GetBetslipConfig().withdrawalOtherMeansEnabled
        }
    };
    nba.link = {
        config: {
            timeout: getSessionNumber('FPSReplyTimeout'),
            disabled: window.sessionStorage.getItem('FPSLinkBankAcc') == '1' ? false : true
        }
    };
    nba.FPSErrorList = FPSErrorList?.errors;
    FPSInfo?.bankAccount?.forEach((item) => {
        const index = item.bankAccountType;
        const nbaNo = `nba${index}`;
        const bankInfo = getBankInfoFromSessionStorage(nbaNo); //bankList?.banks?.find((bank) => bank.code === item.bankCode);
        const showFPS =
            item.eDDAStatus === 'AP' && sessionStorage.getItem('EnabledFPS') == '1' && item.bankAccountNo.length > 0;
        const showEPS =
            nbaData.nba?.[`${nbaNo}ActivatedChannels`]?.filter((i) => i === 'IS' || i === 'TB').length === 2 &&
            item.bankAccountNo.length > 0;
        const showAUTOPAY = !!nbaData?.nba?.nba1BankAccountNumber;
        const otherMeans = showAUTOPAY && (showEPS || showFPS);
        const nbaItem = {
            otherMeans,
            bankInfo: {
                name_en: bankInfo?.name || item.bankCode,
                name_ch: bankInfo?.nameTC || item.bankCode,
                code: bankInfo?.code || item.bankCode,
                eDDADepositEnable: bankInfo?.eDDADepositEnable || false,
                withdrawalEnable: bankInfo?.withdrawalEnable || false
            },
            bankAccountNo: item.bankAccountNo,
            bankAccountType: item.bankAccountType,
            bankAccountNoMasked: maskBankAccount(item.bankAccountNo),
            EPS: {
                show: showEPS,
                hasDepositCharge: getEpsCharge(
                    'deposit',
                    nbaData?.information?.accountTypeIndication,
                    item.FPSPStatus == 1,
                    item.EDDASingleLegStatus == 1
                ),
                hasWithdrawalCharge: getEpsCharge(
                    'withdrawal',
                    nbaData?.information?.accountTypeIndication,
                    item.FPSPStatus == 1,
                    item.EDDASingleLegStatus == 1
                ),
                charge: nbaData?.information?.EFTServiceFee
            },
            FPS: {
                disabled: item.eDDAStatus == 'AP' && (item.FPSPStatus != 1 || bankInfo?.eDDADepositEnable != 1),
                show: showFPS,
                linkStatus: item.eDDAStatus,
                linkErrorType: item.errorType,
                linkErrorCode: item.errorCode,
                linkErrorSubCode: item.errorSubCode,
                notifyEDDAStatus: item.notifyEDDAStatus,
                notifyEDDASingleLegStatus: item.notifyEDDASingleLegStatus,
                notifyStatus: item.notifyEDDAStatus || item.notifyEDDASingleLegStatus,
                EDDASingleLegStatus: item.EDDASingleLegStatus,
                notifyEDDASingleLegErrorType: item.notifyEDDASingleLegErrorType,
                notifyEDDASingleLegErrorCode: item.notifyEDDASingleLegErrorCode,
                isSingleLeg: item.EDDASingleLegStatus == 1,
                FPSPStatus: item.FPSPStatus
            }
        };
        nba.deposit[nbaNo] = nbaItem;
        if (index === 1) {
            nba.withdrawal = {
                ...nba.withdrawal,
                nba1: {
                    ...nbaItem,
                    FPS: {
                        ...nbaItem.FPS,
                        show: showFPS,
                        /*(showFPS &&
                                (bankInfo?.withdrawalEnable != 1 || bankInfo?.eDDADepositEnable != 1 ? false : true)) ||
                            (showFPS &&
                                bankInfo?.eDDADepositEnable == 1 &&
                                bankInfo?.withdrawalEnable == 0 &&
                                showAUTOPAY),*/
                        disabled: item.eDDAStatus == 'AP' && (item.FPSPStatus != 1 || bankInfo?.withdrawalEnable != 1)
                    },
                    AUTOPAY: {
                        show: showAUTOPAY,
                        disabled: bankInfo?.autopayWithdrawalDisallowed == false ? false : true
                    }
                }
            };
        }

        nba.link[nbaNo] = nbaItem;
    });
    return nba;
};

const getBankInfoFromSessionStorage = (nbaNo) => {
    if (nbaNo == 'nba1') {
        return {
            name: sessionStorage.getItem('primarynba_bankname_en'),
            nameTC: sessionStorage.getItem('primarynba_bankname_ch'),
            code: sessionStorage.getItem('primarynba_bankcode'),
            eDDADepositEnable: isTrue(sessionStorage.getItem('primarynba_FPSeDDADepositEnable')),
            withdrawalEnable: isTrue(sessionStorage.getItem('primarynba_FPSwithdrawalEnable')),
            autopayWithdrawalDisallowed: isTrue(sessionStorage.getItem('primarynba_AutopayWithdrawalDisallowed'))
        };
    } else if (nbaNo == 'nba2') {
        return {
            name: sessionStorage.getItem('secondarynba_bankname_en'),
            nameTC: sessionStorage.getItem('secondarynba_bankname_ch'),
            code: sessionStorage.getItem('secondarynba_bankcode'),
            eDDADepositEnable: isTrue(sessionStorage.getItem('secondarynba_FPSeDDADepositEnable')),
            withdrawalEnable: isTrue(sessionStorage.getItem('secondarynba_FPSwithdrawalEnable')),
            autopayWithdrawalDisallowed:isTrue(sessionStorage.getItem('secondarynba_AutopayWithdrawalDisallowed'))
        };
    }
};

const isTrue = (val) => {
    if (val) {
        return val.toLowerCase() === 'true';
    }
    return false;
};

export const getFPSErrorMsg = (responseData, FPSErrorList, language) => {
    if (window.globalConfig.IS_IB) {
        return language === 'en' ? responseData.errorMsg : responseData.errorMsgTC;
    } else {
        if (!responseData.errorCode) return;
        let msgData = FPSErrorList?.find((i) => i.code === responseData.errorCode && i.type === responseData.errorType);

        return language === 'en' ? msgData?.userMessage : msgData?.userMessageTC;
    }
};

export const formatServiceCharge = (amount) => {
    if (!Number.isNaN(parseFloat(amount))) {
        return amount;
    }

    if (amount % 1 != 0) {
        return parseFloat(amount).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }
    return parseFloat(amount).toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 });
};

export const saveEpsNoticeCookie = (loginAccount) => {
    const dateInt = new Date().setFullYear(new Date().getFullYear() + 5); //cookie expire in 10 years
    document.cookie =
        'NoEpsNotice_' + loginAccount + '=true; expires=' + new Date(dateInt).toUTCString() + '; path=/; SameSite=lax';
};
