import React from 'react';
import { useTranslation } from 'react-i18next';
import { getThisYear } from '../Common/home-common';
import useProductName from '../Common/hooks/useProductName';

const Copyright = () => {
    const { t } = useTranslation();
    const productName = useProductName();
    return (
        <div className={window.globalConfig.IS_EWIN2 ? "ewinCopyRight" : "copyRight"}>
            <a rel="noopener noreferrer" href={t('URL_COPYRIGHT')} target="_blank">{t('LB_COPYRIGHT')}</a>&nbsp;© 2006-{getThisYear()} {name} {t('LB_HKJC_' + productName.toUpperCase())}
        </div>
    )
}
export default Copyright;