import DateRangePicker from '../../Common/DateRangePicker';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { getLastUpdate } from '../../Common/GlobalFunc';

const SearchBox = ({ searchParams, onChangeSearchParams, offsetDate,closeSearchBox, maxSelectDays, isSpeedbet, speedbetDayRangeDropdown, statementExtension, onStatementSearch, isShowResult, isShowSearchBox }) => {
    const { t, i18n } = useTranslation();
    const serverTime = getLastUpdate();
    const [maxDate, setMaxDate] = useState(serverTime);
    const [minDate, setMinDate] = useState(new Date(serverTime.getTime() - (maxSelectDays>30 ? maxSelectDays-1 : 29) * 24 * 60 * 60 * 1000));

    useEffect(()=>{
        if(offsetDate){
            setMaxDate(offsetDate)
            setMinDate(new Date(offsetDate.getTime() - (maxSelectDays>30 ? maxSelectDays-1 : 29) * 24 * 60 * 60 * 1000))
        }
    },[offsetDate])

    const handleTxnChangeSelect = (data) => {
        onChangeSearchParams({
            ...searchParams,
            transType: data,
        });
        // setTransType(data);
    };

    const handleChangeSearchType = (type) => {
        onChangeSearchParams({
            ...searchParams,
            searchType: type,
        });
    };

    const handleDisplayChangeSelect = (target) => {
        onChangeSearchParams({
            ...searchParams,
            displayType: {
                value: target.getAttribute('value'),
                text: target.innerText,
            },
        });
    };

    const searchStatement = () => {
        onStatementSearch(false);
    };

    const datePickerLabel = () => {
        return <>{t(isSpeedbet ? 'LB_BETSLIP_ACCOUNTRECORDS_RANGE_SPEEDBET' : 'LB_BETSLIP_ACCOUNTRECORDS_RANGE').replace('{0}', maxSelectDays)}</>;
    };

    const handleClickOverlay = (e) => {
        if(e.target.className === 'ARSearchBoxOverlay'){
            closeSearchBox();
        }
    };

    const calendarOpened = () => {
        handleChangeSearchType('range');
    };

    const handleClickLastDay = (val) => {
        onChangeSearchParams({
            ...searchParams,
            lastDay: val,
            searchType: 'last'
        });
    }

    const tranTypeConfig = [
        { value: '0', text: t('LB_BETSLIP_ACCOUNTRECORDS_BETTYPE_RC') },
        { value: '1', text: t('LB_BETSLIP_ACCOUNTRECORDS_BETTYPE_FB') },
        { value: '2', text: t('LB_BETSLIP_ACCOUNTRECORDS_BETTYPE_M6') },
        { value: '3', text: t('LB_BETSLIP_ACCOUNTRECORDS_BETTYPE_ALL') },
        { value: '4', text: t('LB_BETSLIP_ACCOUNTRECORDS_BETTYPE_OTHER') },
    ];

    return (
        <div className={isShowResult && isShowSearchBox ? 'ARSearchBoxOverlay' : ''} onClick={(e) => handleClickOverlay(e)}>
            <div className={`${isShowSearchBox ? 'ARSearchBox' : 'hide'}`} >
                <div className="ARSearchBox-l right-border">
                    {/* <div className="statementSelection_1st">
                        <input type="radio" name="statementRadio" className="statementRadio" checked={searchParams.searchType === 'today'} onChange={() => handleChangeSearchType('today')} />
                        <span>{t('LB_BETSLIP_ACCOUNTRECORDS_TODAY')}</span>
                    </div> */}
                    <div className="statementSelection dfc">
                        {isSpeedbet && <input type="radio" name="statementRadio" className="statementRadio" checked={searchParams.searchType === 'range'} onChange={() => handleChangeSearchType('range')} />}
                        <DateRangePicker
                            className="date_range_picker"
                            minDate={minDate}
                            maxDate={maxDate}
                            maxSelectDays={maxSelectDays}
                            defaultDate={{ ...searchParams }}
                            onChangeDate={onChangeSearchParams}
                            topValueLabel={datePickerLabel()}
                            calendarOpened={calendarOpened}
                        />
                    </div>
                    <div className="recordRange">{datePickerLabel()}</div>
                    {statementExtension ? (
                            <div className="statementSelection statementSelection-lastday ddNoRSpace">
                                    <input type="radio" name="statementRadio" className="statementRadio" checked={searchParams.searchType === 'last'} onChange={() => handleChangeSearchType('last')} />
                                    {t('LB_BETSLIP_ACCOUNTRECORDS_LAST')}
                                    <DropdownButton
                                    title={
                                        <>
                                            {searchParams.lastDay}
                                            <ArrowIcon direction='down' size={24}/>
                                        </>
                                    }
                                    align="end"
                                    className="statementDropdown speedbetStatementRange"
                                    size="sm"
                                >
                                    {speedbetDayRangeDropdown?.map((item) => {
                                            return <Dropdown.Item  onClick={()=>handleClickLastDay(item)} key={item} value={item}>{item}</Dropdown.Item >;
                                        })}

                                </DropdownButton>
                                {t('LB_BETSLIP_ACCOUNTRECORDS_DAYS')}
                            </div>
                    ) : (
                       null
                    )}
                </div>
                <div className="ARSearchBox-r">
                    <div className="statementSelection ddNoRSpace">
                        <div className='selectionLabel'>{t('LB_BETSLIP_ACCOUNTRECORDS_BETTYPE')}</div>
                        <DropdownButton
                            id={`tranType`}
                            title={
                                <>
                                    {searchParams.transType.text}
                                    <ArrowIcon direction='down' size={24}/>
                                </>
                            }
                            align="end"
                            className="statementDropdown"
                            size="sm"
                        >
                            {tranTypeConfig.map(item => {
                                if (searchParams.displayType.value == '0' && item.value == '4') return null
                                return <Dropdown.Item value={item.value} onClick={(e) => handleTxnChangeSelect(item)} key={item.value}>
                                    {item.text}
                                </Dropdown.Item>
                            })}
                        </DropdownButton>
                    </div>
                    <div className="statementSelection ddNoRSpace">
                        <div className='selectionLabel'>{t('LB_BETSLIP_ACCOUNTRECORDS_TXNTYPE')}</div>
                        <DropdownButton
                            id={`displayType`}
                            title={
                                <>
                                    {searchParams.displayType.text} <ArrowIcon direction='down' size={24}/>
                                </>
                            }
                            align="end"
                            className="statementDropdown"
                            size="sm"
                        >
                            {searchParams.transType.value != '4' && <Dropdown.Item value="0" onClick={(e) => handleDisplayChangeSelect(e.target)}>
                                {t("LB_BETSLIP_ACCOUNTRECORDS_TXNTYPE_BETS")}
                            </Dropdown.Item>}
                            <Dropdown.Item value="1" onClick={(e) => handleDisplayChangeSelect(e.target)}>
                                {t("LB_BETSLIP_ACCOUNTRECORDS_TXNTYPE_ALL")}
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                    <div className="statementSelection">
                        <div className="signIn alignButton cta_yellow" onClick={searchStatement}>
                            {t("LB_SEARCH_CONFIRM")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchBox;
