import * as forgerock from '@forgerock/javascript-sdk';
import { useEffect, useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { removeCurrentSessionRecords } from '../Common/CurrentSessionRecords';
import { glassboxSetUserInfo } from '../Common/utils/glassbox';
import { WATrackerTrackClickEvent } from '../Common/utils/wa';
import { set } from 'react-hook-form';
import { jwtDecode } from 'jwt-decode'
import { detectIncognito } from "detectincognitojs";
import { delay } from '../Common/CommonHooks';

const FATAL = 'Fatal';
const tempConfig = {
    AM_URL: 'https://auth02.ark.iuww.com/am',
    REALM_PATH: 'root',
    SCOPE: 'ls_profile',
    TIMEOUT: '60000',
    TREE: 'hkjcHSLogin-Web',
    WEB_OAUTH_CLIENT: 'JCBW',
    NX_NO_CLOUD: 'true'
}



forgerock.Config.set({
  clientId: tempConfig.WEB_OAUTH_CLIENT, // e.g. 'ForgeRockSDKClient'
  redirectUri: `${window.location.origin}/callback`, // e.g. 'https://sdkapp.example.com:8443/callback'
  scope: tempConfig.SCOPE, // e.g. 'openid profile email address phone'
  serverConfig: {
    baseUrl: tempConfig.AM_URL, // e.g. 'https://myorg.forgeblocks.com/am' or 'https://openam.example.com:8443/openam'
    timeout: parseInt(tempConfig.TIMEOUT), // 90000 or less
  },
  realmPath: tempConfig.REALM_PATH, // e.g. 'alpha' or 'root'
  tree: tempConfig.TREE, // e.g. 'sdkAuthenticationTree' or 'Login'
  type: 'code'
});

export const useLoginLogout_FR = (props) => {
    const { t, i18n } = useTranslation();
    const [isShowOTPAuth, setIsShowOTPAuth] = useState(false);
    const [invalidOTP, setInvalidOTP] = useState(false);
    const [currentStep, setCurrentStep] = useState();
    const [isShowEKBAQuestion, setIsShowEKBAQuestion] = useState(false);
    const [isShowTrustBrowser, setIsShowTrustBrowser] = useState(false);
    const [jwtToken, setJwtToken] = useState('');
    const [mobile, setMobile] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [isIncognito, setIsIncognito] = useState(false);
    // const [password, setPassword] = useState('');
    // const [loginIncorrectMsg, setLoginIncorrectMsg] = useState('');

    // const closeLoginIncorrectMsg = () => {
    //     setLoginIncorrectMsg(null);
    // };
    const {
        password,
        setPassword,
        loginIncorrectMsg,
        setLoginIncorrectMsg,
        closeLoginIncorrectMsg,
        checkAccountPassword,
        checkNewCIAMJwt,
        setLoginAPILoading,
        getEwinSessionID
    } = props;

    useEffect(() => {
        detectIncognito().then((result) => {
            console.log(result.browserName, result.isPrivate);
            setIsIncognito(result.isPrivate)
          });
    }, []);

    const next = async (step) =>{

        
        console.log('before next', step)
        
        const rsp = await forgerock.FRAuth.next(step);

        console.log('after next', rsp);

        return rsp;
    }
    
    // step 1 init
    const FR_initStep = async() => {
        
        console.log('FR_initStep...');

        let step = await next(null);

        if(step?.getStage() == "pre-namepass"){
            
            step = await getPreNamepass(step)
        }
        
        setCurrentStep(step);

        return step;
    }

    const getPreNamepass = async (step) => {

        const choiceCallback = step.getCallbackOfType('ChoiceCallback');
        console.log('choiceCallback', choiceCallback)
        choiceCallback.setChoiceIndex(0);
        
        const rsp = await next(step);

        return rsp;
    }

    // step 2 login with user name and password
    const FR_loginWithUsernamePassword = async (username, password) => {
        // for testing convenience only
        
        await FR_Logout();

        if (username === "test9999") {
            username = 'web0000999';
            password = 'Midships202!';
        } else if (username === 'errorinfo') {
            username = 'web0000777';
            password = 'Midships202!';
        } else if (['123123', 'W20005575', '00100038', '00100321', '01020079', '00010055'].includes(username)) {
            setIsShowOTPAuth(true);
            return;
        }

        if(step?.payload?.stage == 'errorinfo'){
            await FR_initStep();
        }

        let step = currentStep;
        let nextStep = currentStep;

        try{

            if(step?.payload?.status == "200" && step?.payload.stage != "otp"){
                const nameCallback = step.getCallbackOfType('NameCallback');
                const passwordCallback = step.getCallbackOfType('PasswordCallback');
                nameCallback.setName(username);
                passwordCallback.setPassword(password);
                nextStep = await next(step); //await forgerock.FRAuth.next(step);
            }

            if(nextStep?.payload?.status == "200" && nextStep?.payload.stage == "otp"){
                
                const otpCallback = JSON.parse(nextStep.getCallbackOfType('TextOutputCallback').getMessage())


                console.log('otpCallback', otpCallback)
                
                setMobile({
                    "mobileNo": otpCallback.mobile,
                    "timeout": otpCallback.timeout
                })
                closeLoginIncorrectMsg();
                setIsShowOTPAuth(true);
                WATrackerTrackClickEvent('eWin_CIAM_S1a-OTP_whitelistWithMobile');
                step = nextStep;
            } else if(nextStep?.type == "LoginSuccess"){
                setIsShowTrustBrowser(false);
                setCIAMLoginIncorrectMsg('')
                step = nextStep;

                await FR_LoginSuccess(step);
            } else if(nextStep?.payload?.status != "200" && nextStep?.type == "LoginFailure"){
                setIsShowTrustBrowser(false);
                setCIAMLoginIncorrectMsg('100103');
                await FR_Logout().then(FR_initStep());
            } else{
                
                const message = JSON.parse(nextStep.getCallbackOfType('TextOutputCallback').getMessage())
                
                const result = message.messageId?.replace('ERR', '');

                console.log('FR_loginWithUsernamePassword result', result)

                await FR_Logout();
                await FR_initStep();
                switch(result){
                    case '100011': //No mobile number available
                        await checkAccountPassword(username, password, true);
                        WATrackerTrackClickEvent('eWin_eKBA_whitelistWithoutMobile');
                        break;
                    case '100008':
                        setCIAMLoginIncorrectMsg(result);
                        break;
                    case '100103': 
                        setCIAMLoginIncorrectMsg('100103');
                        setIsShowOTPAuth(false);
                        break;
                    default:
                        setCIAMLoginIncorrectMsg(result);
                        break;

                }
            }

            setCurrentStep(step);
                
        }catch(err){
            
            setIsShowTrustBrowser(false);
            setCIAMLoginIncorrectMsg('100103')
            console.error(err)
            console.info(step)
        }
    };

    // step 3 login with OTP
    const FR_loginWithOTP = async (otp) => {
        
        // const step = await setNextStep({otp: otp})
        let step = currentStep;
        let message = "";
        try{
    
            // const message = JSON.parse(step.getCallbackOfType('TextOutputCallback').getMessage())
    
            console.log('before FR_loginWithOTP', step)
            const textInputCallback = step.getCallbackOfType('TextInputCallback');
            textInputCallback.setInputValue(otp);
            const rsp = await next(step); //await forgerock.FRAuth.next(step);

            if(rsp?.payload?.status == "200" && rsp?.type == "LoginSuccess"){
                setIsShowTrustBrowser(false);
                setCIAMLoginIncorrectMsg('')

                setCurrentStep(rsp);
                await FR_LoginSuccess(rsp);
            } else if(rsp?.type == "LoginFailure"){
                switch(rsp?.payload.detail.errorCode){
                    case "110":
                        setShowErrorPopup('session timeout');
                        break;
                    default:
                        setShowErrorPopup('otp failure');
                        break;
                }
            } else{

                console.log('after FR_loginWithOTP', rsp)

                switch(rsp?.payload.stage){
                    case "errorinfo":
                        message = JSON.parse(rsp.getCallbackOfType('TextOutputCallback').getMessage());
                        console.debug('message', message);
                        setInvalidOTP(message?.messageId);
                        // step = await next(step); //await forgerock.FRAuth.next(rsp);
                        // setCurrentStep(step);
                        break;
                    case "trust-browser-confirm":
                        await handleConfirmTrustBrowser(rsp);
                        // setCurrentStep(rsp);
                        // setIsShowOTPAuth(false);
                        // setIsShowTrustBrowser(true);
                        // WATrackerTrackClickEvent('eWin_CIAM_S2-Trust-overlay_whitelistWithMobile');
                        break;
                    default:
                        setIsShowOTPAuth(false);
                        setIsShowTrustBrowser(false);
                        break;
                }
                
                    
            }
        }catch(err){
            console.error(err)
        }
        
        return message;
    }

    const handleConfirmTrustBrowser = async (step) => {

        if(isIncognito){
            console.log('isIncognito', isIncognito)
            await FR_TurstBrowser(false, step).then(() => {
                setIsShowOTPAuth(false);
                setIsShowTrustBrowser(false);
            });
        }else{
            setCurrentStep(step);
            setIsShowOTPAuth(false);
            setIsShowTrustBrowser(true);
            WATrackerTrackClickEvent('eWin_CIAM_S2-Trust-overlay_whitelistWithMobile');
        }
    }

    // step 4 turst browser
    const FR_TurstBrowser = async(trust, preStep = null) => {

        let step = preStep ? preStep : currentStep;

        console.log('trust', trust)
        console.log('FR_TurstBrowser', step)
        
        const textInputCallback = step.getCallbackOfType('ConfirmationCallback');
        textInputCallback.setInputValue(trust ? 0 : 1);

        const rsp = await next(step);

        if(rsp.payload.status == 200){
            setCIAMLoginIncorrectMsg('')

            if(rsp?.type == "LoginSuccess"){

                await FR_LoginSuccess(rsp);
                
            }else{
                await FR_Logout().then(FR_initStep);
            }
        }else{
            setShowErrorPopup('trust browser failure');
        }
        
        setCurrentStep(rsp);

        return rsp;
    }

    const FR_ResendOTP = async () => {
        
        let step = currentStep;

        //"ConfirmationCallback"
        
        const confirmationCallback = step.getCallbackOfType('ConfirmationCallback');
        confirmationCallback.setOptionIndex(1);

        const nextStep = await next(step);
        
        if(nextStep?.payload?.status == "200" && nextStep?.payload.stage == "otp"){
                
            const otpCallback = JSON.parse(nextStep.getCallbackOfType('TextOutputCallback').getMessage())


            console.log('otpCallback', otpCallback)
            
            setMobile({
                "mobileNo": otpCallback.mobile,
                "timeout": otpCallback.timeout
            })
            closeLoginIncorrectMsg();
            setIsShowOTPAuth(true);
            WATrackerTrackClickEvent('eWin_CIAM_S1a-OTP-withMobile');
            step = nextStep;
            
        }else if(nextStep?.type == "LoginFailure"){
            switch(nextStep?.payload.detail.errorCode){
                case "110":
                    setShowErrorPopup('session timeout');
                    break;
                default:
                    setShowErrorPopup('otp resend failure');
                    break;
            }
            step = nextStep;
        }

        setCurrentStep(step);
    }

    const FR_LoginSuccess = async (step) => {
        
        const token = step.getSessionToken();
                
        console.log('tokens', token);
        
        const accessTokens = await refreshToken(token);

        const jwtDecodeStr = jwtDecode(accessTokens)

        console.log('jwtDecode', jwtDecodeStr)

        const sid = await getEwinSessionID();

        window.sessionStorage.setItem('session_id', sid)
        window.sessionStorage.setItem('account', jwtDecodeStr.betAcct)
        window.sessionStorage.setItem('gu_id', '')
        window.sessionStorage.setItem('sso_guid', '')
        
        await checkNewCIAMJwt(jwtToken, setLoginAPILoading)

        return jwtToken;
    }

    const FR_GetToken = async() =>{

        let step = currentStep;

        console.log('before FR_GetToken', step)

        const token = await refreshToken();
        console.log("token", token); 

        setJwtToken(token)

        return token;
    }
    
    const refreshToken = async (token) => {

        //authorize token
        const tokens = await forgerock.TokenManager.getTokens({
            forceRenew: true,
            login: 'embedded',
            csrf: token,
            decision: 'allow',
            logger: (l) => console.log('get token', l),
            // redirectUri: 'https://speedbet.iuww.com/callback'
        });
        console.log('tokens', tokens);

        const accessTokens = tokens.accessToken;

        setJwtToken(accessTokens);

        return accessTokens;
    }

    const FR_Logout = async () => {

        console.log('FR_Logout...')
        return await forgerock.FRUser.logout();
 
    }

    const setCIAMLoginIncorrectMsg = (error) => {

        if(error?.length > 0){
            setLoginIncorrectMsg(`CIAM_${error}`)
        }else{
            setLoginIncorrectMsg('')
        }
        
    }

    const FR_GetMobile = () => {
        return mobile;
    }

    return {
        FR_initStep,
        FR_loginWithUsernamePassword,
        FR_loginWithOTP,
        FR_TurstBrowser,
        FR_GetToken,
        isShowOTPAuth,
        setIsShowOTPAuth,
        invalidOTP, 
        setInvalidOTP,
        password,
        setPassword,
        loginIncorrectMsg,
        setLoginIncorrectMsg,
        closeLoginIncorrectMsg,
        isShowEKBAQuestion,
        setIsShowTrustBrowser,
        isShowTrustBrowser,
        jwtToken,
        FR_Logout,
        FR_GetMobile,
        FR_ResendOTP,
        showErrorPopup,
        setShowErrorPopup,
    };
}