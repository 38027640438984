export const leftMenuData = {
    en: {
        racing: {
            "item": {
                "children": [
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_All Up Cross Pool All Up",
                        "itemName": {
                            "value": "All Up/ Cross Pool All Up"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "cross_alup"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Win Place",
                        "itemName": {
                            "value": "Win / Place"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "wp"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": "New"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Quinella Quinella Place",
                        "itemName": {
                            "value": "Quinella / Quinella Place"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "wpq"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_AUTO_CWA_MENU",
                        "itemName": {
                            "value": "3 Pick 1 (Composite Win)"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": ""
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": [
                            {
                                "key": "Int_Single",
                                "itemName": {
                                    "value": "Single"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "cwa"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": {
                                    "value": ""
                                },
                                "altTxt": {
                                    "value": ""
                                },
                                "itemEnable": {
                                    "value": ""
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": {
                                    "value": "CWA"
                                },
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_All Up Individual Race View",
                                "itemName": {
                                    "value": "All Up - Individual Race View"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "cwaalup"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": {
                                    "value": ""
                                },
                                "altTxt": {
                                    "value": "New"
                                },
                                "itemEnable": {
                                    "value": ""
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": {
                                    "value": ""
                                },
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_All Up All Races View",
                                "itemName": {
                                    "value": "All Up - All Races View"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "cwaallalup"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": {
                                    "value": ""
                                },
                                "altTxt": {
                                    "value": ""
                                },
                                "itemEnable": {
                                    "value": ""
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": {
                                    "value": ""
                                },
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            }
                        ]
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Forecast",
                        "itemName": {
                            "value": "Forecast"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "fct"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "Jackpot"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Tierce",
                        "itemName": {
                            "value": "Tierce"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "tce"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "Jackpot"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Trio",
                        "itemName": {
                            "value": "Trio"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "tri"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "Jackpot"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_First 4",
                        "itemName": {
                            "value": "First 4"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "ff"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "Jackpot"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Quartet",
                        "itemName": {
                            "value": "Quartet"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "qtt"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "Jackpot"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Double",
                        "itemName": {
                            "value": "Double"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "dbl"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Treble",
                        "itemName": {
                            "value": "Treble"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "tbl"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Double Trio",
                        "itemName": {
                            "value": "Double Trio"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "dt"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "Jackpot"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Triple Trio",
                        "itemName": {
                            "value": "Triple Trio"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "tt"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "jackpot"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Six Up",
                        "itemName": {
                            "value": "Six Up"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "6up"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "Jackpot"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Jockey Challenge",
                        "itemName": {
                            "value": "Jockey Challenge"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "jkc"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Trainer Challenge",
                        "itemName": {
                            "value": "Trainer Challenge"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "tnc"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_AUTO_CWB_CWC_MENU",
                        "itemName": {
                            "value": "Special Composite Win"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": [
                            {
                                "key": "Int_AUTO_CWB_MENU",
                                "itemName": {
                                    "value": "Winning Trainer"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "cwb"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": {
                                    "value": ""
                                },
                                "altTxt": {
                                    "value": ""
                                },
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": {
                                    "value": "CWB - CWC"
                                },
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_AUTO_CWC_MENU",
                                "itemName": {
                                    "value": "Winning Region"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "cwc"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": {
                                    "value": ""
                                },
                                "altTxt": {
                                    "value": ""
                                },
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": {
                                    "value": "CWB - CWC"
                                },
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            }
                        ]
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Jockey Trainer Combo",
                        "itemName": {
                            "value": "Jockey Trainer Combo"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "jtcombo"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Progressive Win Odds",
                        "itemName": {
                            "value": "Progressive Win Odds"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "pwin"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Pool Investment",
                        "itemName": {
                            "value": "Pool Investment"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "turnover"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    }
                ]
            }
        },
        football: {
            "item": {
                "children": [
                    {
                        "key": "all_menu",
                        "enable": {
                            "value": "1"
                        },
                        "list": {
                            "children": [
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "UCL_Tou_Red",
                                    "itemName": {
                                        "value": "UCL"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "tournament/50000112"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": "Adhoc Tournament"
                                    },
                                    "predefinedColor": {
                                        "targetItem": {
                                            "optionValue": {
                                                "value": "#FF0000"
                                            }
                                        }
                                    },
                                    "frontendID": {
                                        "value": "FB3348"
                                    },
                                    "startDatetime": {
                                        "value": "2023-10-04T16:29:55"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Early Settlement",
                                    "itemName": {
                                        "value": "Early Settlement"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "cashout"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": {
                                            "optionValue": {
                                                "value": "#FF0000"
                                            }
                                        }
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2023-02-21T16:05:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Same Game All Up",
                                    "itemName": {
                                        "value": "Same Game All Up"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "sga"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": {
                                            "optionValue": {
                                                "value": "#FF0000"
                                            }
                                        }
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:40:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_In Play Betting",
                                    "itemName": {
                                        "value": "In Play Betting"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "inplay"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-08-01T00:00:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_All Up Cross Bet Type All Up",
                                    "itemName": {
                                        "value": "All Up / Cross Bet Type All Up"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "mixallup"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:40:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Home Away Draw",
                                    "itemName": {
                                        "value": "Home/Away/Draw"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "had"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:40:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_To Qualify",
                                    "itemName": {
                                        "value": "To Qualify"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "tql"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:41:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Half HAD",
                                    "itemName": {
                                        "value": "First Half HAD"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fha"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:41:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Handicap HAD",
                                    "itemName": {
                                        "value": "Handicap HAD"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "hha"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:41:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Handicap",
                                    "itemName": {
                                        "value": "Handicap"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "hdc"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2023-06-21T16:30:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Half Handicap",
                                    "itemName": {
                                        "value": "First Half Handicap"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fhh"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2024-06-19T15:17:24"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_HiLo",
                                    "itemName": {
                                        "value": "HiLo"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "hil"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-10-11T15:03:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Half HiLo",
                                    "itemName": {
                                        "value": "First Half HiLo"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fhl"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:41:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Corner Taken HiLo",
                                    "itemName": {
                                        "value": "Corner Taken HiLo"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "chl"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:41:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Corner Handicap",
                                    "itemName": {
                                        "value": "Corner Handicap"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "chd"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2024-06-19T15:51:02"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Half Corner Handicap",
                                    "itemName": {
                                        "value": "First Half Corner Handicap"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fhc"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2024-06-19T15:48:51"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Specials",
                                    "itemName": {
                                        "value": "Specials"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "msp"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:41:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Correct Score",
                                    "itemName": {
                                        "value": "Correct Score"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "crs"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:41:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Half Correct Score",
                                    "itemName": {
                                        "value": "First Half Correct Score"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fcs"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:41:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Team to Score",
                                    "itemName": {
                                        "value": "First Team to Score"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fts"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:42:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Total Goals",
                                    "itemName": {
                                        "value": "Total Goals"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "ttg"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:42:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Odd Even",
                                    "itemName": {
                                        "value": "Odd/Even"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "ooe"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:42:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Scorer",
                                    "itemName": {
                                        "value": "First Scorer"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fgs"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:42:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_HaFu",
                                    "itemName": {
                                        "value": "HaFu"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "hft"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:42:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Champion",
                                    "itemName": {
                                        "value": "Champion"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "chp"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:42:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "EPL_Tou_black",
                                    "itemName": {
                                        "value": "Eng Premier"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "tournament/50000051"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": "Adhoc Tournament"
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": "FB3192"
                                    },
                                    "startDatetime": {
                                        "value": "2023-09-13T22:25:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_AUTO_FINALIST",
                                    "itemName": {
                                        "value": "Finalists"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "finalist"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": "Adhoc Tournament"
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2023-05-30T14:35:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_AUTO_WINNING_COUNTRY",
                                    "itemName": {
                                        "value": "Winning Country"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "wincnty"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2023-05-23T17:13:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_AUTO_WINNING_CONTINENT",
                                    "itemName": {
                                        "value": "Winning Continent"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "wincntt"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2023-05-23T17:13:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_AUTO_TOURN_LIST",
                                    "itemName": {
                                        "value": "Auto_tourn_list"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": ""
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": "Remove Tournament"
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": "50000112"
                                    },
                                    "startDatetime": {
                                        "value": "2023-09-21T15:50:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_All Odds",
                                    "itemName": {
                                        "value": "All Odds"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "allodds"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:42:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                }
                            ]
                        }
                    }
                ]
            }
        },
        marksix: {
            "item": {
                "children": [
                    {
                        "sectionTitle": {
                            "value": "Next Draw"
                        },
                        "sectionEnabled": {
                            "value": "1"
                        },
                        "sectionIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "key": "Int_AUTO_NEXT_DRAW",
                        "itemName": null,
                        "itemRedirection": null,
                        "itemLink": null,
                        "itemPage": null,
                        "itemIcon": null,
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": null,
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": null,
                        "predefinedColor": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": [
                            {
                                "key": "Int_Self Select Single",
                                "itemName": {
                                    "value": "Self Select Single"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": "https://special.hkjc.com/e-win/en-US/betting-info/marksix/types-of-entry/#single"
                                },
                                "itemPage": {
                                    "value": "Single"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_Self Select Multiple",
                                "itemName": {
                                    "value": "Self Select Multiple"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": "https://special.hkjc.com/e-win/en-US/betting-info/marksix/types-of-entry/#multiple"
                                },
                                "itemPage": {
                                    "value": "Multiple"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_Self Select Banker",
                                "itemName": {
                                    "value": "Self Select Banker"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": "https://special.hkjc.com/e-win/en-US/betting-info/marksix/types-of-entry/#banker"
                                },
                                "itemPage": {
                                    "value": "Banker"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_Quick Pick",
                                "itemName": {
                                    "value": "Quick Pick"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": "https://special.hkjc.com/e-win/en-US/betting-info/marksix/types-of-entry/#random"
                                },
                                "itemPage": {
                                    "value": "Random"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            }
                        ]
                    },
                    {
                        "sectionTitle": {
                            "value": "Snowball"
                        },
                        "sectionEnabled": {
                            "value": "1"
                        },
                        "sectionIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "key": "Int_AUTO_SNOWBALL_DRAW",
                        "itemName": null,
                        "itemRedirection": null,
                        "itemLink": null,
                        "itemPage": null,
                        "itemIcon": null,
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": null,
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": null,
                        "predefinedColor": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": [
                            {
                                "key": "Int_ Self Select Single",
                                "itemName": {
                                    "value": "Self Select Single"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "singlesnowball"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_ Self Select Multiple",
                                "itemName": {
                                    "value": "Self Select Multiple"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "multiplesnowball"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_ Self Select Banker",
                                "itemName": {
                                    "value": "Self Select Banker"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "bankersnowball"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_ Quick Pick",
                                "itemName": {
                                    "value": "Quick Pick"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "randomsnowball"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            }
                        ]
                    }
                ]
            }
        },
    },
    ch: {
        racing: {
            "item": {
                "children": [
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_All Up Cross Pool All Up",
                        "itemName": {
                            "value": "過關 / 混合過關"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "cross_alup"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Win Place",
                        "itemName": {
                            "value": "獨贏 / 位置"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "wp"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": "新"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Quinella Quinella Place",
                        "itemName": {
                            "value": "連贏 / 位置Q"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "wpq"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_AUTO_CWA_MENU",
                        "itemName": {
                            "value": "3揀1 (組合獨贏)"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": ""
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": [
                            {
                                "key": "Int_Single",
                                "itemName": {
                                    "value": "單場"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "cwa"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": {
                                    "value": ""
                                },
                                "altTxt": {
                                    "value": ""
                                },
                                "itemEnable": {
                                    "value": ""
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": {
                                    "value": "CWA"
                                },
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_All Up Individual Race View",
                                "itemName": {
                                    "value": "過關 - 個別場次版"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "cwaalup"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": {
                                    "value": ""
                                },
                                "altTxt": {
                                    "value": "新"
                                },
                                "itemEnable": {
                                    "value": ""
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": {
                                    "value": ""
                                },
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_All Up All Races View",
                                "itemName": {
                                    "value": "過關 - 所有場次版"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "cwaallalup"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": {
                                    "value": ""
                                },
                                "altTxt": {
                                    "value": ""
                                },
                                "itemEnable": {
                                    "value": ""
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": {
                                    "value": ""
                                },
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            }
                        ]
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Forecast",
                        "itemName": {
                            "value": "二重彩"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "fct"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "多寶"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Tierce",
                        "itemName": {
                            "value": "三重彩"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "tce"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "多寶"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Trio",
                        "itemName": {
                            "value": "單T"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "tri"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "多寶"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_First 4",
                        "itemName": {
                            "value": "四連環"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "ff"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "多寶"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Quartet",
                        "itemName": {
                            "value": "四重彩"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "qtt"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "多寶"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Double",
                        "itemName": {
                            "value": "孖寶"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "dbl"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Treble",
                        "itemName": {
                            "value": "三寶"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "tbl"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Double Trio",
                        "itemName": {
                            "value": "孖T"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "dt"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "多寶"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Triple Trio",
                        "itemName": {
                            "value": "三T"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "tt"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "多寶"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Six Up",
                        "itemName": {
                            "value": "六環彩"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "6up"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": "1"
                        },
                        "altTxt": {
                            "value": "多寶"
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Jockey Challenge",
                        "itemName": {
                            "value": "騎師王"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "jkc"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Trainer Challenge",
                        "itemName": {
                            "value": "練馬師王"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "tnc"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_AUTO_CWB_CWC_MENU",
                        "itemName": {
                            "value": "特別組合獨贏項目"
                        },
                        "itemRedirection": {
                            "value": ""
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": ""
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": [
                            {
                                "key": "Int_AUTO_CWB_MENU",
                                "itemName": {
                                    "value": "勝出練馬師"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "cwb"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": {
                                    "value": ""
                                },
                                "altTxt": {
                                    "value": ""
                                },
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": {
                                    "value": "CWB - CWC"
                                },
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_AUTO_CWC_MENU",
                                "itemName": {
                                    "value": "勝出地區"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "cwc"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": {
                                    "value": ""
                                },
                                "altTxt": {
                                    "value": ""
                                },
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": {
                                    "value": "CWB - CWC"
                                },
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            }
                        ]
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Jockey Trainer Combo",
                        "itemName": {
                            "value": "騎練配對"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "jtcombo"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Progressive Win Odds",
                        "itemName": {
                            "value": "獨贏賠率走勢"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "pwin"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    },
                    {
                        "sectionTitle": null,
                        "sectionEnabled": null,
                        "sectionIcon": null,
                        "key": "Int_Pool Investment",
                        "itemName": {
                            "value": "投注額"
                        },
                        "itemRedirection": {
                            "value": "JCBW page"
                        },
                        "itemLink": {
                            "value": ""
                        },
                        "itemPage": {
                            "value": "turnover"
                        },
                        "itemIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "jpFlag": {
                            "value": ""
                        },
                        "altTxt": {
                            "value": ""
                        },
                        "itemEnable": {
                            "value": "1"
                        },
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": {
                            "value": ""
                        },
                        "predefinedColor": {
                            "targetItem": null
                        },
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": []
                    }
                ]
            }
        },
        football: {
            "item": {
                "children": [
                    {
                        "key": "all_menu",
                        "enable": {
                            "value": "1"
                        },
                        "list": {
                            "children": [
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "UCL_Tou_Red",
                                    "itemName": {
                                        "value": "歐洲聯賽冠軍盃"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "tournament/50000112"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": "Adhoc Tournament"
                                    },
                                    "predefinedColor": {
                                        "targetItem": {
                                            "optionValue": {
                                                "value": "#FF0000"
                                            }
                                        }
                                    },
                                    "frontendID": {
                                        "value": "FB3348"
                                    },
                                    "startDatetime": {
                                        "value": "2023-10-04T16:37:03"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Early Settlement",
                                    "itemName": {
                                        "value": "派彩快"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "cashout"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": {
                                            "optionValue": {
                                                "value": "#FF0000"
                                            }
                                        }
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2023-02-22T10:26:40"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Same Game All Up",
                                    "itemName": {
                                        "value": "同場過關"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "sga"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": {
                                            "optionValue": {
                                                "value": "#FF0000"
                                            }
                                        }
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:43:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_In Play Betting",
                                    "itemName": {
                                        "value": "即場投注"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "inplay"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:43:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_All Up Cross Bet Type All Up",
                                    "itemName": {
                                        "value": "過關/混合過關"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "mixallup"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:43:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Home Away Draw",
                                    "itemName": {
                                        "value": "主客和"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "had"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-10-04T22:43:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_To Qualify",
                                    "itemName": {
                                        "value": "晉級隊伍"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "tql"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:44:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Half HAD",
                                    "itemName": {
                                        "value": "半場主客和"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fha"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:44:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Handicap HAD",
                                    "itemName": {
                                        "value": "讓球主客和"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "hha"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:44:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Handicap",
                                    "itemName": {
                                        "value": "讓球"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "hdc"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:44:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Half Handicap",
                                    "itemName": {
                                        "value": "半場讓球"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fhh"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2024-06-19T15:17:24"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_HiLo",
                                    "itemName": {
                                        "value": "入球大細"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "hil"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:44:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Half HiLo",
                                    "itemName": {
                                        "value": "半場入球大細"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fhl"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:44:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Corner Taken HiLo",
                                    "itemName": {
                                        "value": "開出角球大細"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "chl"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:44:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Corner Handicap",
                                    "itemName": {
                                        "value": "開出角球讓分"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "chd"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2024-06-19T15:51:02"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Half Corner Handicap",
                                    "itemName": {
                                        "value": "半場開出角球讓分"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fhc"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2024-06-19T15:48:51"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Specials",
                                    "itemName": {
                                        "value": "特別項目"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "msp"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:44:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Correct Score",
                                    "itemName": {
                                        "value": "波膽"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "crs"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:44:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Half Correct Score",
                                    "itemName": {
                                        "value": "半場波膽"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fcs"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:44:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Team to Score",
                                    "itemName": {
                                        "value": "第一隊入球"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fts"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:44:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Total Goals",
                                    "itemName": {
                                        "value": "總入球"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "ttg"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:44:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Odd Even",
                                    "itemName": {
                                        "value": "入球單雙"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "ooe"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:45:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_First Scorer",
                                    "itemName": {
                                        "value": "首名入球"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "fgs"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:45:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_HaFu",
                                    "itemName": {
                                        "value": "半全場"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "hft"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:45:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_Champion",
                                    "itemName": {
                                        "value": "冠軍"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "chp"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:45:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "EPL_Tou_black",
                                    "itemName": {
                                        "value": "英超"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "tournament/50000051"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": "Adhoc Tournament"
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": "FB3192"
                                    },
                                    "startDatetime": {
                                        "value": "2023-09-25T12:31:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_AUTO_FINALIST",
                                    "itemName": {
                                        "value": "決賽組合"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "finalist"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": "Adhoc Tournament"
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2023-05-30T14:35:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_AUTO_WINNING_COUNTRY",
                                    "itemName": {
                                        "value": "勝出國家"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "wincnty"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2023-05-23T17:13:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_AUTO_WINNING_CONTINENT",
                                    "itemName": {
                                        "value": "勝出洲份"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "wincntt"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2023-05-23T17:20:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_AUTO_TOURN_LIST",
                                    "itemName": {
                                        "value": "Auto_Tourn_List"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": ""
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": "Adhoc Tournament"
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2023-05-17T18:36:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                },
                                {
                                    "sectionTitle": null,
                                    "sectionEnabled": null,
                                    "sectionIcon": null,
                                    "key": "Int_All Odds",
                                    "itemName": {
                                        "value": "所有賠率"
                                    },
                                    "itemRedirection": {
                                        "value": "JCBW page"
                                    },
                                    "itemLink": {
                                        "value": ""
                                    },
                                    "itemPage": {
                                        "value": "allodds"
                                    },
                                    "itemIcon": {
                                        "src": null,
                                        "width": null,
                                        "height": null
                                    },
                                    "jpFlag": null,
                                    "altTxt": null,
                                    "itemEnable": {
                                        "value": "1"
                                    },
                                    "redirectMethod": null,
                                    "pH": null,
                                    "pW": null,
                                    "pX": null,
                                    "pY": null,
                                    "specialtag": {
                                        "value": ""
                                    },
                                    "predefinedColor": {
                                        "targetItem": null
                                    },
                                    "frontendID": {
                                        "value": ""
                                    },
                                    "startDatetime": {
                                        "value": "2022-09-21T22:45:00"
                                    },
                                    "noOfDays": null,
                                    "frontEndIds": null,
                                    "sNoOfDays": null,
                                    "moHead": null,
                                    "moText": null,
                                    "moImage": null,
                                    "iconM": null,
                                    "pCardEnable": null,
                                    "GlassboxCustomEventName": null,
                                    "GlassboxCustomEventValue": null,
                                    "GlassboxTaggingEnabled": null,
                                    "children": []
                                }
                            ]
                        }
                    }
                ]
            }
        },
        marksix: {
            "item": {
                "children": [
                    {
                        "sectionTitle": {
                            "value": "下期攪珠"
                        },
                        "sectionEnabled": {
                            "value": "1"
                        },
                        "sectionIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "key": "Int_AUTO_NEXT_DRAW",
                        "itemName": null,
                        "itemRedirection": null,
                        "itemLink": null,
                        "itemPage": null,
                        "itemIcon": null,
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": null,
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": null,
                        "predefinedColor": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": [
                            {
                                "key": "Int_Self Select Single",
                                "itemName": {
                                    "value": "自選單式"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": "https://special.hkjc.com/e-win/zh-HK/betting-info/marksix/types-of-entry/#single"
                                },
                                "itemPage": {
                                    "value": "Single"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_Self Select Multiple",
                                "itemName": {
                                    "value": "自選複式"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": "https://special.hkjc.com/e-win/zh-HK/betting-info/marksix/types-of-entry/#multiple"
                                },
                                "itemPage": {
                                    "value": "Multiple"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_Self Select Banker",
                                "itemName": {
                                    "value": "自選膽拖"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": "https://special.hkjc.com/e-win/zh-HK/betting-info/marksix/types-of-entry/#banker"
                                },
                                "itemPage": {
                                    "value": "Banker"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_Quick Pick",
                                "itemName": {
                                    "value": "運財號碼"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": "https://special.hkjc.com/e-win/zh-HK/betting-info/marksix/types-of-entry/"
                                },
                                "itemPage": {
                                    "value": "Random"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            }
                        ]
                    },
                    {
                        "sectionTitle": {
                            "value": "金多寶"
                        },
                        "sectionEnabled": {
                            "value": "1"
                        },
                        "sectionIcon": {
                            "src": null,
                            "width": null,
                            "height": null
                        },
                        "key": "Int_AUTO_SNOWBALL_DRAW",
                        "itemName": null,
                        "itemRedirection": null,
                        "itemLink": null,
                        "itemPage": null,
                        "itemIcon": null,
                        "jpFlag": null,
                        "altTxt": null,
                        "itemEnable": null,
                        "redirectMethod": null,
                        "pH": null,
                        "pW": null,
                        "pX": null,
                        "pY": null,
                        "specialtag": null,
                        "predefinedColor": null,
                        "frontendID": null,
                        "startDatetime": null,
                        "noOfDays": null,
                        "frontEndIds": null,
                        "sNoOfDays": null,
                        "moHead": null,
                        "moText": null,
                        "moImage": null,
                        "iconM": null,
                        "pCardEnable": null,
                        "GlassboxCustomEventName": null,
                        "GlassboxCustomEventValue": null,
                        "GlassboxTaggingEnabled": null,
                        "children": [
                            {
                                "key": "Int_ Self Select Single",
                                "itemName": {
                                    "value": "自選單式"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "singlesnowball"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_ Self Select Multiple",
                                "itemName": {
                                    "value": "自選複式"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "multiplesnowball"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_ Self Select Banker",
                                "itemName": {
                                    "value": "自選膽拖"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "bankersnowball"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            },
                            {
                                "key": "Int_ Quick Pick",
                                "itemName": {
                                    "value": "運財號碼"
                                },
                                "itemRedirection": {
                                    "value": "JCBW page"
                                },
                                "itemLink": {
                                    "value": ""
                                },
                                "itemPage": {
                                    "value": "randomsnowball"
                                },
                                "itemIcon": {
                                    "src": null,
                                    "width": null,
                                    "height": null
                                },
                                "jpFlag": null,
                                "altTxt": null,
                                "itemEnable": {
                                    "value": "1"
                                },
                                "redirectMethod": null,
                                "pH": null,
                                "pW": null,
                                "pX": null,
                                "pY": null,
                                "specialtag": null,
                                "predefinedColor": {
                                    "targetItem": null
                                },
                                "frontendID": null,
                                "startDatetime": null,
                                "noOfDays": null,
                                "frontEndIds": null,
                                "sNoOfDays": null,
                                "moHead": null,
                                "moText": null,
                                "moImage": null,
                                "iconM": null,
                                "pCardEnable": null,
                                "GlassboxCustomEventName": null,
                                "GlassboxCustomEventValue": null,
                                "GlassboxTaggingEnabled": null
                            }
                        ]
                    }
                ]
            }
        },
    },
}