import React, { useState, useContext, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { handleClickMenuItem, MenuIcon } from './MenuCommon';
import { getSiteCoreImagePath } from "../Common/home-common";
import { Accordion } from 'react-bootstrap';
import { use2ndLvlMenuQuery } from '../Home/Data/SitecoreDataHooks';
import { useLoginLogout } from '../BetSlip/LoginHooks';
import { useLoginLogout_IB } from '../BetSlip/LoginIBHooks';
import { GlobalContext } from '../../contexts/GlobalContext';

const MobileSecondLvlMenu = ({loginLogoutHook}) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    // const { loginLogoutHook } = useContext(GlobalContext)
    const [menuLoading, menuData] = use2ndLvlMenuQuery();
    const { isPCardUser } = loginLogoutHook

    const handleOnPageChange = (url) => {
        navigate(url);
    }

    const MobileIcon = ({menu}) => {
        return <div className="menu-icon">
            {menu.iconM?.src && menu.iconM?.src!=="" ? <img src={getSiteCoreImagePath(menu.iconM.src, i18n.language)} title={menu.itemName.value} /> : null}
        </div>
    }

    const Menu = () => {
        return menuData.map((menu, idx) => {
            if ( menu?.itemEnable?.value!=="1") {
                return null;
            }
            else if ( menu?.pCardEnable?.value==="1" && !isPCardUser ) {
                return null;
            }
            else if (menu.children.filter(x=> x.itemEnable.value==="1").length > 0 ) {
                return <Accordion.Item eventKey={menu.key} key={menu.itemName.value}>
                    <Accordion.Header><MobileIcon menu={menu} />{menu.itemName.value}<MenuIcon menu={menu} /></Accordion.Header>
                    <Accordion.Body>{menuItem(menu)}</Accordion.Body>
                </Accordion.Item>;
            }
            else {
                return <Fragment key={menu.itemName.value}><MobileIcon menu={menu} />{menu.itemName.value}<MenuIcon menu={menu} /></Fragment>
            }
        });
    }

    function menuItem(menu) {
        return menu.children.map((_cItem, _cIndex) => {
            if ( _cItem.itemEnable.value!=="1" ) {
                return null;
            }
            else {
                return <div onClick={(e) => handleClickMenuItem(e, _cItem, handleOnPageChange)} key={_cItem.itemName.value}>
                    <p>{_cItem.itemName.value}<MenuIcon menu={_cItem} /></p>
                </div>
            }
        });
    }


    return !menuLoading ? <Accordion><Menu /></Accordion> : null;
}

export default MobileSecondLvlMenu;