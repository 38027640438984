export const parseFoSell = (foPool, message) => {
    if ( message.id ) {
        foPool.poolId = message.id;
    }
    if (message.expStartSellTm) {
        foPool.expStartDateTime = message.expStartSellTm;
    }
    if (message.expStopSellTm) {
        foPool.expStopDateTime = message.expStopSellTm;
    }
    if (message.maxRaNoStoppedSell) {
        foPool.raceStopSellNo = message.maxRaNoStoppedSell.raNo ;
        foPool.raceStopSellStatus = message.maxRaNoStoppedSell.st.toString(); 
    }
    if (message.chlInfo) {
        foPool.sellStatus = message.chlInfo.filter(x=> x.chl === "InteractiveService")[0].st ;
    }
}