import PropTypes from "prop-types";
import React,{ useEffect, useRef, useState, useContext } from 'react';
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next';
import ArrowIcon from '../Common/ui-components/ArrowIcon';
import '../../css/date_range_picker.scss'
import { GlobalContext } from '../../contexts/GlobalContext';

const ReactDateRangePicker = ({onChangeDate,windowSize, showMonthAndYearDropdown,defaultDate, minDate, maxDate=new Date(), className, getDateCache, topValueLabel, calendarOpened, maxSelectDays, maxSelectMonths}) => {
    const {t} = useTranslation()
    const globalContext = useContext(GlobalContext);
    const [isMobile, setIsMobile] = useState(false)
    const [dateCache,setDateCache] = useState({
        startDate: null,
        endDate: null
    })
    const serverTime = globalContext?.sessionServerTimeStamp || (new Date()).getTime();
    const [mouseHoverDate, setMouseHoverDate] = useState()
    const [curMaxDate, setCurMaxDate] = useState(new Date(serverTime));
    const [curMinDate, setCurMinDate] = useState(new Date(serverTime - 29 * 24 * 60 * 60 * 1000));
    const [showMonthOrYearDropdown, setShowMonthOrYearDropdown] = useState('')
    const [calendarOpen, setCalendarOpen] = useState(false)
    const datePickerRef = useRef(null)
    const size = document.documentElement.clientWidth
    useEffect(()=> {

        if(windowSize){
            setIsMobile(windowSize?.isMobileSize)
        }else if(size <768){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    },[windowSize, size])
    useEffect(()=>{
        setCurMaxDate(maxDate);
        setCurMinDate(minDate);
        setDateCache({
            startDate: defaultDate?.startDate || null,
            endDate: defaultDate?.endDate || null
        })

        // document.body.addEventListener('click', handleCloseDropdown)
        // return ()=>{
        //     document.body.removeEventListener('click', handleCloseDropdown)
        // }
    },[])

    useEffect(() => {
        if (dateCache.startDate && !dateCache.endDate && (maxSelectDays || maxSelectMonths)) {
            let date
            let prevDate
            let curRangeTime = (maxSelectDays - 1) * 24 * 60 * 60 * 1000
            if(maxSelectDays){
                date = new Date(dateCache.startDate.getTime() + curRangeTime);
                prevDate = new Date(dateCache.startDate.getTime() - curRangeTime)
            }else if(maxSelectMonths){
                date = new Date(dateCache.startDate)
                prevDate = new Date(dateCache.startDate)
                date.setMonth(date.getMonth() + maxSelectMonths)
                prevDate.setMonth(prevDate.getMonth() - maxSelectMonths)
                date.setDate(date.getDate() - 1)
                prevDate.setDate(prevDate.getDate() + 1)
            }
            let maxEndDate = maxDate < date ? maxDate : date;
            let minEndDate = minDate > prevDate ? minDate : prevDate


            setCurMinDate(minEndDate);
            setCurMaxDate(maxEndDate);
        } else {
            setCurMaxDate(maxDate);
            setCurMinDate(minDate);
        }
    }, [dateCache, maxSelectDays,minDate,maxDate, maxSelectMonths]);


    const handleCloseDropdown = (e) =>{
        if(e.target.className != 'dateRangePickerMonth' && e.target.className != 'dateRangePickerYear' && e.target.parentElement.className != 'monthDropdown'){
            setShowMonthOrYearDropdown('')
        }

        // e?.stopPropagation()
        // e.preventDefault()
    }



    const handleClickDatePick = () => {
        document.querySelector('.date-input').click()
    }

    const handleChangeDate = (date) => {

        // setDateCache({
        //     startDate: date[0],
        //     endDate: date[1]
        // })
        // getDateCache && getDateCache({
        //     startDate: date[0],
        //     endDate: date[1]
        // })
    }

    const handleCalendarClose = () => {
        const portal = document.querySelector('.react-datepicker__portal')
        setTimeout(()=>{
            document.querySelector('.date-input').removeAttribute('disabled')
            document.querySelector('.date-input').blur()
        },200)
        if(portal){
            portal.removeEventListener('touchstart',handleClickDatePickerPortal)
            portal.removeEventListener('click',handleClickDatePickerPortal)
        }

        setShowMonthOrYearDropdown('')
        if(!dateCache.endDate && dateCache.startDate){
            const curDate = {
                endDate: dateCache.startDate,
                startDate:  dateCache.startDate,
            }
            setDateCache(curDate)
            onChangeDate(curDate)
            getDateCache && getDateCache(curDate)
            return
        }
        onChangeDate({...dateCache})
    }

    const handleCalendarOpen = () => {
        document.querySelector('.date-input').blur()
        document.querySelector('.date-input').setAttribute('disabled', true)
        const datePicker =  document.querySelector('.react-datepicker')
        const confirmContainer = document.createElement('div')
        const confirmBtn = document.createElement('div')
        document.querySelector('.react-datepicker').removeEventListener('click', handleCloseDropdown)
        document.querySelector('.react-datepicker').addEventListener('click', handleCloseDropdown)
        confirmBtn.innerHTML = t('LB_FB_RESULT_CONFIRM')
        confirmBtn.className = 'datePickerConfirmBtn'
        confirmBtn.addEventListener('click',handleClickDateConfirm)
        confirmContainer.className = 'datePickerConfirm'
        confirmContainer.appendChild(confirmBtn)
        datePicker.appendChild(confirmContainer)
        calendarOpened && calendarOpened(true);
        const portal = document.querySelector('.react-datepicker__portal')
        if(portal){
            portal.addEventListener('touchstart',handleClickDatePickerPortal)
            portal.addEventListener('click',handleClickDatePickerPortal)
        }
    }

    const handleClickDatePickerPortal = (e) => {
        if(e.target.className === 'react-datepicker__portal'){
            e.stopPropagation();
        }
    }

    const handleClickDateConfirm = () => {
        onChangeDate({...dateCache})
        setTimeout(()=> datePickerRef.current.setState({open: false}))
    }

    const formatWeekDay = (weekDay) => {
        const dayCode = weekDay.slice(0,3).toUpperCase()

        const day = t(`LB_SS_${dayCode}`)
        return <>{day}</>
    }

    const handleClickDate = (date) => {

        let startDate = null
        let endDate = null
        if(dateCache.startDate && dateCache.endDate){
            setDateCache({
                startDate:null,
                endDate:null
            })
            startDate = date
        }else if(dateCache.startDate && !dateCache.endDate){
            if(date >= dateCache.startDate){
                startDate = dateCache.startDate
                endDate = date
            }else{
                startDate = date
                endDate = dateCache.startDate
            }
        }else{
            startDate = date
        }
        setDateCache({
            startDate,
            endDate
        })
    }

    const checkIsDisabledDate = (date) => {
        let maxDate = new Date(curMaxDate.toDateString())
        let minDate = new Date(curMinDate.toDateString())

        if(date.getTime() > maxDate.getTime() || date.getTime() < minDate.getTime()) return true
    }

    const onMouseLeaveDate = () => {
        setMouseHoverDate(dateCache.startDate)
    }
    const onMouseOverDate = (date) => {
        setMouseHoverDate(date)
    }

    const renderDayContents = (day,date) => {


        const isHoverDay = checkIsSameDay(mouseHoverDate, date)
        const isSelect = dateCache.startDate &&  dateCache.endDate == null && mouseHoverDate && ((mouseHoverDate > date && dateCache.startDate < date) || (mouseHoverDate < date && dateCache.startDate > date))
        const currentSelect = dateCache.startDate &&  dateCache.endDate && date < dateCache.endDate && date > dateCache.startDate
        const isStartDay = checkIsSameDay(dateCache.startDate, date)
        const isEndDay =  checkIsSameDay(dateCache.endDate, date)
        const isDisabled = checkIsDisabledDate(date)
        const isToday = checkIsSameDay(new Date(serverTime), date)
        let className = ''
        if(isSelect || isHoverDay){
            className = 'isRangeDay'
        }
        if(currentSelect){
            className =  'currentRangeDay'
        }
        if (isStartDay ) {
            className =  'selectedDay selectedDay_startDate'
        }
        if (isEndDay ) {
            className =  'selectedDay selectedDay_endDate'
        }
        if(isDisabled){
            className = ''
            if(isHoverDay){
                className = 'isRangeDay'
            }
        }
        if(isToday){
            className += ' isToday'
        }

        return <div className={className} onMouseLeave={()=>!isDisabled && onMouseLeaveDate()} onMouseOver={()=> !isDisabled && onMouseOverDate(date)} onClick={()=> !isDisabled && handleClickDate(date)}>
            <div className="datePickerDay">{day}</div>
            </div>
    }

    const checkIsSameDay = (date1, date2) => {
        if(!date1 || !date2) return false
        let flag = true
        if(date1.getFullYear() != date2.getFullYear()){
            flag = false
        }else if(date1.getMonth() != date2.getMonth()){
            flag = false
        }else if( date1.getDate() != date2.getDate()){
            flag = false
        }
        return flag
    }

    const handleClickMonth = () =>{
        setShowMonthOrYearDropdown('MONTH')
    }

    const handleClickYear = () =>{
        setShowMonthOrYearDropdown('YEAR')
    }

    const checkIsSameMonth = (date1, date2) => {
        if(!date1 || !date2) return false
        let flag = true
        if(date1.getFullYear() != date2.getFullYear()){
            flag = false
        }else if(date1.getMonth() != date2.getMonth()){
            flag = false
        }

        return flag
    }

    const checkisDisabledMonth = (minDate, maxDate, curDate) => {
        if(!minDate || !maxDate || !curDate) return false
        let flag =  true
        if(curDate > maxDate){
            if(curDate.getFullYear() === maxDate.getFullYear() && curDate.getMonth() === maxDate.getMonth()){
                flag = false
            }

        }else if (curDate < minDate){
            if(curDate.getFullYear() === minDate.getFullYear() && curDate.getMonth() === minDate.getMonth()){
                flag = false
            }
        }else {
            flag = false
        }
        return flag
    }

    const customHeader = (args) => {
        const month = args.monthDate.getMonth()
        const year = args.monthDate.getFullYear()
        const onChangeMonth = (action) => {
            switch(action){
                case 'PREV':
                    args.changeMonth(month-1)
                    return
                case 'NEXT':
                    args.changeMonth(month +1)
                    return
                default:
                    return
            }
        }

        const onSelectedMonth = (month) =>{
            setShowMonthOrYearDropdown('')
            args.changeMonth(month)
        }
        const onSelectedYear = (year) =>{
            setShowMonthOrYearDropdown('')
            args.changeYear(year)
            if(dateCache.startDate && !dateCache.endDate){
                for(let m =0; m <  12; m++){
                    if( !checkisDisabledMonth(curMinDate,curMaxDate, new Date(`${year}-${m+1}-1`))) {
                      args.changeMonth(m)
                      break
                    }
                  }
            }

        }

        const getAvailableYears = (date) =>{
            const currentYear = maxDate.getFullYear()
            let minYear = minDate.getFullYear()
            const yearList = []
            while(minYear <= currentYear){
                yearList.push(minYear)
                minYear ++
            }
            return yearList
        }

        const startDate = dateCache.startDate ? `${dateCache.startDate.getDate()}/${dateCache.startDate.getMonth()+1}` :''
        const endDate = dateCache.endDate ? `${dateCache.endDate.getDate()}/${dateCache.endDate.getMonth()+1}` :''
        const topDivText =  `${startDate} - ${endDate}`

        const disabledPrevMon = checkIsSameMonth(curMinDate,args.monthDate)
        const disabledNextMon = checkIsSameMonth(curMaxDate,args.monthDate)

        return<>
            <div className="dateRangePickerValueContainer">
                {topDivText}
                {topValueLabel && <div className="date-picker-top-label">{topValueLabel}</div>}
            </div>
            <div className="react-datepicker__current-month">
                <ArrowIcon className="cursor-point prev-month" onClick={()=> !disabledPrevMon && onChangeMonth('PREV')} direction='left' size={30}/>
                <div>
                    <span className="dateRangePickerMonth" onClick={() => showMonthAndYearDropdown && handleClickMonth()}>{t(`LB_MONTH_${month+1}`)}</span> <span onClick={() => showMonthAndYearDropdown && handleClickYear()} className="dateRangePickerYear">{year}</span>
                </div>
                   <div className="monthDropdownContainer">
                    {showMonthOrYearDropdown === 'MONTH' &&<div  className="monthDropdown">
                        { Array(12).fill([]).map((i,index)=>{
                            const disbaleMonth = checkisDisabledMonth(curMinDate,curMaxDate, new Date(`${year}-${index+1}-1`))
                            return disbaleMonth ? null :  <div key={index} onClick={()=> onSelectedMonth(index)} className={`${month === index ? 'activeMonth' : ''} ${disbaleMonth ? 'disabled' : ''}`}>{t(`LB_MONTH_${index+1}`)}</div>
                            })}
                    </div>}
                    {showMonthOrYearDropdown === 'YEAR' && <div className="monthDropdown">
                        { getAvailableYears().map((i,index)=>{
                            let disbaleYear = true
                            for(let m =0; m <  12; m++){
                              if( !checkisDisabledMonth(curMinDate,curMaxDate, new Date(`${i}-${m+1}-1`))) {
                                disbaleYear = false
                                break
                              }
                            }
                            return disbaleYear ? null : <div onClick={()=> onSelectedYear(i)} key={i} className={year == i ? 'activeMonth' : ''}>{i}</div>
                            })}
                    </div>}
                   </div>
                <ArrowIcon className="cursor-point next-month" onClick={()=> !disabledNextMon && onChangeMonth('NEXT')} direction='right' size={30}/>

            </div>
        </>
    }

    return <div className={`date-picker-container ${className}`} onClick={handleClickDatePick}>
    <div className='date-picker-from'>{t('LB_FB_RESULT_DATE')}</div>
    <DatePicker
    selectsRange={true}
    startDate={dateCache.startDate}
    endDate={dateCache.endDate}
    className={'date-input cp'}
    onChange={handleChangeDate}
    onChangeRaw={(e) => e.preventDefault()}
    dateFormat={'dd/MM/yyyy'}
    minDate={curMinDate}
    maxDate={curMaxDate}
    showDisabledMonthNavigation
    disabledKeyboardNavigation
    ref={datePickerRef}
    renderCustomHeader={customHeader}
    yearDropdownItemNumber={30}
    scrollableYearDropdown
    formatWeekDay={formatWeekDay}
    renderDayContents={renderDayContents}
    onCalendarOpen={handleCalendarOpen}
    onCalendarClose={handleCalendarClose}
    withPortal={isMobile}
    shouldCloseOnSelect
    />
    <ArrowIcon className='date-dd-arrow' direction='down' size={24}/>
</div>
}

ReactDateRangePicker.propTypes = {
    onChangeDate: PropTypes.func,
    windowSize: PropTypes.object,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    date: PropTypes.object,
    calendarOpened: PropTypes.func,
}

export default ReactDateRangePicker
