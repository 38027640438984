import React, { useContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../CommonHooks';
import { getRefreshTime } from '../../../Racing/Common/Common';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { RacingContext } from '../../../Home/RacingPage';
import { FootballContext } from '../../../Home/FootballPage';
import './index.scss';

const hasBorderBottom = ['DT', 'TBL', 'TT', '6UP','JTCOMBO', 'PWIN']

const RefreshTime = ({product, onClickRefreshButton}) => {
    const { t } = useTranslation();
    const { isMobileSize } = useWindowSize();
    const globalContext = useContext(GlobalContext);
    const racingContext = useContext(RacingContext);
    const footballContext = useContext(FootballContext);

    const getTime = () => {
        const newTime = globalContext?.sessionServerTimeStamp || new Date().getTime();
        switch (product) {
            case "racing":
                return getRefreshTime(racingContext.content.meeting, racingContext, true);
            case "football":
                const curTime = moment(newTime).add(footballContext?.content?.offset || 0, 'seconds');
                return isMobileSize ? curTime.format("DD/MM/YYYY HH:mm") : curTime.format("HH:mm");
            case "ewin":
                return isMobileSize ? moment(newTime).format("DD/MM/YYYY HH:mm") : moment(newTime).format("HH:mm");
        }
        return "";
    }

    const refresh = () => {
        switch (product) {
            case "racing":
                return racingContext?.handleRefreshButton?.();
            case "football":
                return footballContext?.state?.reloadPage?.();
            case "ewin":
                return onClickRefreshButton();
        }
        return "";
    }

    const isShowRefreshBtn = product =='ewin' || product == 'racing' && racingContext.pageConfig.showRefreshBtn || product == 'football' && footballContext.content.pageConfig?.showRefreshBtn;
    const isShowRefreshTime = product =='ewin' || product == 'racing' && racingContext.pageConfig.showRefreshTime || product == 'football' && footballContext.content.pageConfig?.showRefreshTime;

    return isMobileSize ? <div className={`mobileRefreshTime ${product === 'racing' && hasBorderBottom.includes(globalContext.globalState.betType) ? 'hasBorderBottom' : ''}`}>

        {isShowRefreshTime && `${t('LB_LASTUPDATE')}  ${getTime()}`}
        {isShowRefreshBtn?
            <div className="inline-flex refresh-odds" onClick={refresh}>
                <div id="refreshButton" />
            </div>
        :null}
    </div>
    : (product==="ewin" ? <div className="homeRefreshTime">
        {t('LB_RC_REFRESH_AT')} {getTime()}
        <div className="inline-flex refresh-odds" onClick={refresh}>
            <div id="refreshButton" />
            <label id="refreshButtonTxt">{t('LB_RC_REFRESH_ODDS')}</label>
        </div>
    </div> : null);
};

export default RefreshTime;