import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AmountInput from '../../Common/ui-components/AmountInput/index';
import Select from '../../Common/ui-components/Select-bootstrap';
import { ExpandContainer, Footer, Radio } from './common';

import { useCookie } from '../../Common/hooks';
import { obj2cookie, cookie2obj } from '../../Common/utils';
import defaultValue, { settings } from './config';
import { IsBetTypeEnabled } from '../../Common/ChannelParaFunc';
import './scss/index.scss';

const { racing: racingSettings, football: footballSettings } = settings || {};

let selectOption = [
    { label: '---', value: '1' }
    // { label: '5', value: '5' },
    // { label: '10', value: '10' },
    // { label: '20', value: '20' },
    // { label: '30', value: '30' },
    // { label: '---', value: 1 },
];

for (let i = 5; i <= 30; i++) {
    selectOption.push({ label: i, value: i });
}

const cookieStr = obj2cookie(defaultValue);

const Personalsettings = () => {
    const { t } = useTranslation();
    const [personalSettings, setPersonalSettings] = useCookie('personalSettings', cookieStr, { expires: 365 });
    const { register, control, handleSubmit, reset } = useForm(cookie2obj(personalSettings));

    const restoreFn = useCallback(() => {
        // setPersonalSettings(cookieStr, { expires: 365 });
        reset(defaultValue);
    }, [reset, setPersonalSettings]);

    const {
        racing,
        football,
        m6MulDrow,
        accountInfo: { accountName, accountBalance },
        racingNotes,
        showBetlineDetail = '0',
        allUpSetting
    } = cookie2obj(personalSettings);
    const [c1, c2] = t('LB_BS_SET_M6_C').split('[dropdown]');

    return (
        <div className="personal-settings">
            <header>{t('LB_BS_SET')}</header>
            <form
                onSubmit={handleSubmit((d) => {
                    const str = obj2cookie(d)
                    if(personalSettings !== str) {
                        alert(t('LB_BS_SET_SAVE_SUC'));
                    }
                    setPersonalSettings(str, { expires: 365 });

                })}
            >
                <main>
                    <ExpandContainer title={t('LB_BS_SET_RC_T')}>
                        <div className="racing-c">
                            {racingSettings.map((item) => {
                                const display = item.betType == '' || IsBetTypeEnabled(item.betType);
                                    return (
                                        <label key={item.key} className="item" style={display ? {} : {display:'none'}}>
                                            {t(item.lang)}
                                            <Controller
                                                key={item.key}
                                                name={`racing.${item.key}`}
                                                control={control}
                                                defaultValue={racing?.[item.key]}
                                                render={({ field }) => {
                                                    const { onChange, value } = field;
                                                    return (
                                                        <AmountInput
                                                            min={item.min}
                                                            minErrMsg={t('LB_BS_SET_INVAILD')
                                                                .replace('{bettype}', t(item.lang))}
                                                            className="input-c"
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    );
                                                }}
                                            />
                                        </label>
                                    );
                            })}
                        </div>
                    </ExpandContainer>

                    <ExpandContainer title={t('LB_BS_SET_FB_T')}>
                        <div className="football-c">
                            {footballSettings.map((item) => {
                                if (item.betType == '' || IsBetTypeEnabled(item.betType)) {
                                    return (
                                        <label key={item.key} className="item">
                                            {t(item.lang)}
                                            <Controller
                                                key={item.key}
                                                name={`football.${item.key}`}
                                                control={control}
                                                defaultValue={football?.[item.key]}
                                                render={({ field }) => {
                                                    const { onChange, value } = field;
                                                    return (
                                                        <AmountInput
                                                            min={item.min}
                                                            minErrMsg={t('LB_BS_SET_INVAILD')
                                                                .replace('{bettype}', t(item.lang))}
                                                            className="input-c"
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    );
                                                }}
                                            />
                                        </label>
                                    );
                                }
                            })}
                        </div>
                    </ExpandContainer>

                    <ExpandContainer title={t('LB_BS_SET_M6_T')}>
                        <div className="m6-mul-drow-c">
                            <span>{c1}</span>
                            <Controller
                                name="m6MulDrow"
                                control={control}
                                defaultValue={m6MulDrow}
                                render={({ field }) => {
                                    const { onChange, value } = field;
                                    return <Select selectOption={selectOption} onChange={onChange} value={value} />;
                                }}
                            />

                            <span>{c2}</span>
                        </div>
                    </ExpandContainer>

                    <ExpandContainer title={t('LB_BS_SET_AI_T')}>
                        <div className="account-balance-c">
                            <span className="text">{t('LB_BS_SET_BL')}:</span>
                            <Radio
                                title={t('LB_BS_SET_S')}
                                value="0"
                                defaultValue={String(accountBalance)}
                                register={register}
                                path="accountInfo.accountBalance"
                            />
                            <Radio
                                title={t('LB_BS_SET_H')}
                                value="1"
                                defaultValue={String(accountBalance)}
                                register={register}
                                path="accountInfo.accountBalance"
                            />
                        </div>
                    </ExpandContainer>

                    <ExpandContainer title={t('LB_BS_SET_AP_T')}>
                        <div className="all-up-setting-c">
                            <span className="text">{t('LB_BS_SET_AP_C')}:</span>
                            <Radio
                                title={t('LB_BS_SET_AP_K')}
                                value="1"
                                defaultValue={String(allUpSetting)}
                                register={register}
                                path="allUpSetting"
                            />
                            <Radio
                                title={t('LB_BS_SET_AP_D')}
                                value="2"
                                defaultValue={String(allUpSetting)}
                                register={register}
                                path="allUpSetting"
                            />
                        </div>
                    </ExpandContainer>

                    <ExpandContainer title={t('LB_BS_SET_SHOW_DETAIL_T')}>
                        <div className="all-up-setting-c">
                            <span className="text">{t('LB_BS_SET_SHOW_DETAIL_C')}</span>
                            <Radio
                                title={t('LB_BS_SET_SHOW_DETAIL_ON')}
                                value="1"
                                defaultValue={String(showBetlineDetail)}
                                register={register}
                                path="showBetlineDetail"
                            />
                            <Radio
                                title={t('LB_BS_SET_SHOW_DETAIL_OFF')}
                                value="0"
                                defaultValue={String(showBetlineDetail)}
                                register={register}
                                path="showBetlineDetail"
                            />
                        </div>
                    </ExpandContainer>

                    <ExpandContainer title={t('LB_RC_ODDS_TABLE_RCNOTE')}>
                        <div className="racing-notes-c">
                            <Radio
                                title={t('LB_BS_SET_RC_NOTES_ON')}
                                value="1"
                                defaultValue={String(racingNotes)}
                                register={register}
                                path="racingNotes"
                            />
                            <Radio
                                title={t('LB_BS_SET_RC_NOTES_OFF')}
                                value="0"
                                defaultValue={String(racingNotes)}
                                register={register}
                                path="racingNotes"
                            />
                        </div>
                    </ExpandContainer>
                </main>
                <Footer restoreFn={restoreFn} t={t} />
            </form>
        </div>
    );
};

export default Personalsettings;
