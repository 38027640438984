import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const FHH = () => {
    return (
        <section className='FHH'>
            <MatchTable betType='FHH' />
            <Remark betType='FHH'/>
        </section>
    )
}
export default FHH;