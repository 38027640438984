import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const CHD = () => {
    return (
        <section className='CHD'>
            <MatchTable betType='CHD' etBetType='ECD'/>
            <Remark betType='CHD'/>
        </section>
    )
}
export default CHD;