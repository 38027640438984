import React, { useContext, useMemo,useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { getRacingTopics } from './common';
import { parseTopic } from './parseTopic';

const useRacingPush = (contentRef) => { 
    const { t } = useTranslation();
    const globalContext = useContext(GlobalContext);
    const { pushState, isPushing, resubscribeTopic, pubCacheTopic, clearMessage } = globalContext.mqttClient;
    const enableOddsPushRef = useRef(globalContext.globalState.commonCtrl.enableOddsPushButton)
    enableOddsPushRef.current = globalContext.globalState.commonCtrl.enableOddsPushButton && window.globalConfig.RC_ODDS_PUSH

    const resubscribe = () => {
        if ( enableOddsPushRef.current ) {
            const topics = getRacingTopics(contentRef.current)
            clearMessage()
            pubHRCacheTipic(topics)
            resubscribeTopic(topics);
        }
    }
    const pubHRCacheTipic = (topics) =>{
        let cacheTopics = []
        topics.forEach(topic =>{
            let cacheTopicInfo = parseTopic(topic);
            if(cacheTopicInfo.raceStatus || cacheTopicInfo.displayType == "detail"){
                cacheTopics.push({
                    recoveryTopic: topic.replace(/\+/g,'*'),
                    requestAllIndicator: 1
                })
            }
            else{
                cacheTopics.push({
                    recoveryTopic: topic.replace(/\+/g,'*'),
                    requestAllIndicator: 0
                })
            }    
        })
        pubCacheTopic(cacheTopics)
    }

    useEffect(()=> {
        if ( isPushing && window.globalConfig.RC_ODDS_PUSH ) {
            resubscribe();
        }
    }, [isPushing,contentRef.current.venue])


    const topicInfo = useMemo(()=> {parseTopic(pushState.current.topic)}, [pushState.current.topic])

    return {
        topic: pushState.current.topic,
        message: pushState.current.message,
        topicInfo,
        isPushing,
        resubscribe
    };
    
}

export default useRacingPush