import React from 'react';
import MatchTable from '../Common/MatchTable';
import { Remark } from '../Common/MatchBase';

const FHC = () => {
    return (
        <section className='FHC'>
            <MatchTable betType='FHC' />
            <Remark betType='FHC'/>
        </section>
    )
}
export default FHC;