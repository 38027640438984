import { useContext, useRef } from 'react';
import { Query, abortFBMatch, apiErrorCallback } from '../../../Common/graphqlFetch'
import { useApolloClient } from '@apollo/client';
import { getMatchQuery, getMatchResultQuery, getFgsResultQuery, getQueryVariables } from '../DataQuery';
import { setMatchDataOddsDiff } from '../useFBDataRefresh/setRefreshDataOddsDiff';
import { parseMessage } from '../useFootballPush/parseMessage';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { useParams } from "react-router-dom";
import {isFeaturedMatch} from '../../Common/Common'
import { setAllUpCalEntries, getAllUpCalEntries } from '../../Common/AddBet'
import moment from 'moment';

const useGetMatches = (contentRef, setContent) => {
    // const apolloClient = useApolloClient();
    const globalContext = useContext(GlobalContext);
    const { replayMsgQueue, isPushing } = globalContext.mqttClient;
    const params = useParams();
    const matchIdRef = useRef()
    matchIdRef.current = params.matchId
    const enableOddsPushRef = useRef(globalContext.globalState.commonCtrl.enableOddsPushButton)
    enableOddsPushRef.current = globalContext.globalState.commonCtrl.enableOddsPushButton && window.globalConfig.FB_ODDS_PUSH

    const loadMatchQuery = async (paras, callbackFunc, option) => {
        globalContext.globalRef.current.isLoadingData = true;
        abortFBMatch()
        let controller = !option?.disabledController ? window.FBMatchController : null
        await Promise.all([Query({ 
            query : getMatchQuery(),
            variables: getQueryVariables(paras),
            controller:  controller
        })])
        .then(([results]) => {
            if ( callbackFunc ) {
                callbackFunc(results, option,paras);
            }
            else {
                setMatchQueryDataToContent(results, option, paras);
            }
            globalContext.globalRef.current.isLoadingData = false;
        }).catch(error => apiErrorCallback(error, ()=>{ loadMatchQuery(paras, callbackFunc, option)}));
    }

    const setMatchQueryDataToContent = (queryResult, option={}, paras) => {
        if(!queryResult || !queryResult.data) {
            if(!option.refetchCount  || option.refetchCount < 1 ){
                let refetchCount = option.refetchCount ? option.refetchCount + 1 : 1
                loadMatchQuery(paras, null, {...option,refetchCount})
            }else{
                contentRef.current.isLoading = false;
                contentRef.current.isApiErr = false;
                contentRef.current.matchData = {matches: []}
            }
            return
        }
        contentRef.current.isLoading = false;
        contentRef.current.isApiErr = false;
        const isAllMatches = contentRef.current.selection.type === 'ALL' || (contentRef.current.selection.type === 'SEARCH' && contentRef.current.selection.value.length == 0)
        if(contentRef.current.pageNum > 1 && isAllMatches ){
            const newMatches = [...contentRef.current.matchData.matches]
            queryResult.data.matches.forEach((match)=>{
                if(!newMatches.some(i => i.id === match.id)){
                    newMatches.push(match)
                }
            })
            contentRef.current.matchData = {matches: newMatches};
            // setLoadMoreLoading(false)
        }else{
            let matches = [...queryResult.data.matches]
            if(matches.length === 0 && contentRef.current.config.AllOddsPages.includes(contentRef.current.page)){
                const match = contentRef.current.rawMatchList.find(m => m.id ===  matchIdRef.current)
                if(!contentRef.current.listData.matches.find(m => m.id ===  matchIdRef.current)&& match){
                    contentRef.current.listData.matches.push(match)
                }
                matches = match ?  [match] : []
            }
            if( contentRef.current.page === 'OFM' && !option.isNewFeatureMatch){
                matches = matches.filter(match => isFeaturedMatch(match, contentRef.current.offset))
            }
            contentRef.current.matchData = {matches : matches};
        }
        if(!option?.disabledMsgCache) replayMsgCache();
        setContent({ ...contentRef.current });
        // setOpenTournIdsCache(false)
    }

    const loadMatchResultQuery = (paras) => {
        globalContext.globalRef.current.isLoadingData = true;
        abortFBMatch()
        Promise.all([Query({ 
            query : getMatchResultQuery(),
            variables: paras,
            controller: window.FBMatchController
        })])
        .then(([results]) => {
            setMatchResultQueryDataToContent(results);
            globalContext.globalRef.current.isLoadingData = false;
        }).catch(error => apiErrorCallback(error, ()=>{loadMatchResultQuery(paras)}));
    }

    const setMatchResultQueryDataToContent = (queryResult) => {
        if(!queryResult || !queryResult.data) return;
        contentRef.current.isLoading = false;
        contentRef.current.isApiErr = false;
        contentRef.current.isSearching = false;
        contentRef.current.matchData = {...queryResult.data};
        setContent({ ...contentRef.current });
    }

    const loadFgsResultQuery = () => {
        abortFBMatch()
        Promise.all([Query({ 
            query : getFgsResultQuery(),
            controller: window.FBMatchController
        })])
        .then(([results]) => {
            setMatchResultQueryDataToContent(results);
        }).catch(error => apiErrorCallback(error, ()=>{loadFgsResultQuery()}));
    }

    const reloadMatchQuery = (paras) => {
        loadMatchQuery(paras, resetMatchQueryDataToContent);
    }

    const resetMatchQueryDataToContent = (queryResult, option={}, params={}) => {
        if(!queryResult || !queryResult.data) return
        contentRef.current.isLoading = false;
        setMatchDataOddsDiff(contentRef, queryResult.data.matches);
        let newMatchList = [...queryResult.data.matches]
        if(params.inplayOnly){
            newMatchList = contentRef.current.matchData.matches.map(match =>{
                const curMatch = queryResult.data.matches.find(item => item.id ==match.id)
                if(curMatch) return curMatch
                return match
            })
        }
        if(queryResult.data.matches.length === 0 && contentRef.current.config.AllOddsPages.includes(contentRef.current.page)){
            const match = contentRef.current.rawMatchList.find(m => m.id ===  matchIdRef.current)
            newMatchList = match ?  [match] : []
        }
        contentRef.current.matchData = {matches : newMatchList};
        setContent({ ...contentRef.current });
        if (enableOddsPushRef.current && isPushing) replayMsgCache()
    }

    const replayMsgCache = () => {
        replayMsgQueue.current.forEach(({topic, message}) => {
            parseMessage(topic, message, contentRef, loadMatchQuery, [...replayMsgQueue.current], {isReplay: true});
        });
    }

    const updateAllUpCalCookies = (queryResult, option={}, paras) =>{
        let allUpCalData =  getAllUpCalEntries()
        allUpCalData = allUpCalData.map(item => {
            let curMatch = queryResult.data.matches.find(match => match.id == item.id)
            if(curMatch){
                let oldOdds = item.oddsVal
                let now = moment()
                let curPool = curMatch.foPools.find(pool => pool.id === item.poolId)
                let curLine = curPool?.lines?.find(line => line.lineId == item.lineId)
                let curComb = curLine?.combinations?.find(comb => comb.combId == item.combId)
                let oddsDiff = curComb?.currentOdds ? parseFloat(curComb.currentOdds) - parseFloat(oldOdds) : 0
                console.log(oddsDiff)
                item.matchInfo = {
                    ...curMatch,
                    foPools: []
                }
                item.foPool = curPool || item.foPool
                if(curPool && curLine && curComb){
                    item = {
                        ...item,
                        oddsVal: curComb.currentOdds,
                        oddUpdTs: oddsDiff ? now : item.oddUpdTs,
                        oddsDiff: oddsDiff || item.oddsDiff
                    }
                }
            }
            return item
        })
        setAllUpCalEntries([...allUpCalData])
    }
    
    const reloadCalMatchQuery = (ids) => {
        loadMatchQuery({matchIds: ids, page: 'ALLODDS'}, updateAllUpCalCookies, {disabledController: true})
    }

    return {
        loadMatchQuery,
        loadMatchResultQuery,
        loadFgsResultQuery,
        reloadMatchQuery,
        reloadCalMatchQuery
    }
}

export default useGetMatches

