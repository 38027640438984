import { getDeviceType } from './components/Common/CommonHooks';

const getInfo = () => {

    const deviceInfo = getDeviceType();

    deviceInfo.width = window.screen.width;
    deviceInfo.height = window.screen.height;
    deviceInfo.userAgent = navigator.userAgent;
    deviceInfo.platform = navigator.platform;    
    
    return JSON.stringify(deviceInfo);
}

const ShowDeviceInfo = () => {
    return getInfo();
}
export default ShowDeviceInfo;