
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import {updateOriginLocation} from '../Common/ui-components/AddToSlipAnimation';
import { glassboxCustomEvent } from "../Common/utils/glassbox";
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../contexts/GlobalContext';

const AddSlipBtn = ({product, enable}) => {
    const globalContext = useContext(GlobalContext);
    const { t } = useTranslation();
    const handleClickAddToSlip = () => {
        let addClass = ''
        let AddToSlip = null
        switch(product.toUpperCase()){
            case 'RACING':
                addClass = 'rcMain'
                AddToSlip =  document.querySelector(`.${addClass} .AddToSlip`)
                break
            case 'FOOTBALL':
                addClass = 'fbMain'
                AddToSlip =  document.querySelector(`.${addClass} .fb-bottom-betslip .AddToSlip`)

                break
            case 'MARKSIX':
                addClass = 'marksixMain'
                AddToSlip =  document.querySelector(`.${addClass} .AddToSlip`)
                break
            default:
                break
        }
        updateOriginLocation(`.bottom-menu .AddToSlip`);
        AddToSlip.click()
    }

    return (
        <div className={`AddToSlip ${globalContext.bottomMenuAddSlipButtonDisable ? 'disabled': ''}`} onClick={handleClickAddToSlip}>
            {t("LB_ADDTOSLIP")}
        </div>

    );
}

export default AddSlipBtn;