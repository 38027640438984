import React, { useEffect, useContext, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { FBMixAllUpAddtoSlipBtn} from '../../BetSlip/AddSlipBtn';
import { Remark, MatchInfo, getFoPool } from '../Common/MatchBase';
import { SingleMatchTable } from '../Common/MatchTable';
import { alupFormulaItem } from '../../Common/GlobalFunc';
import { GetChannelPara } from '../../Common/ChannelParaFunc';
import { getExtraTimeBetType, getQueryDateRange, getQueryTorunIds } from '../Common/Common';
import AllupBetTypeBox from '../Common/AllupBetTypeBox';
import AllupDetailCalPanel from '../Common/AllupDetailCalPanel';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import Sticky from 'react-stickynode';
import { useWindowSize } from '../../Common/CommonHooks';
import RefreshTime from '../../Common/ui-components/RefreshTime';

import { Cookies } from '../../Common/CookieHelper';
import { FBMatchTypeTab, allMatchTimeTypes } from '../../Common/ui-components/FBMatchTypeTab';

const MIXALLUP_DETAIL = () => {
    const context = useContext(FootballContext);
    const { t } = useTranslation();
    const AllOddsOrder = context.content.config.AllOddsOrder;
    const COOKIE_NAME_SELECT_BETTYPES = 'mixAllupSelected';
    const { isMobileSize } = useWindowSize();

    const [allupData, setAllupData] = useState({
        unitBet: 10,
        noOfComb: '-',
        dividend: '-',
        netReturn: '-',
        alupFormula: ''
    });

    const [selectedOddsType, setSelectedOddsType] = useState([]);

    useEffect(() => {
        let sTypes = Cookies.getDataArray(COOKIE_NAME_SELECT_BETTYPES);
        if (sTypes.length == 0) {
            sTypes = ['HAD', 'EHA'];
            Cookies.setDataArray(COOKIE_NAME_SELECT_BETTYPES, sTypes);
        }
        allupData.unitBet = context.content.mixAllUpUnitBet;
        setAllupData({...allupData});
        setSelectedOddsType([...sTypes]);
    }, []);

    useEffect(() => {
        allupData.alupFormula = context.content.mixAllUpFormula;
        allupData.unitBet = context.content.mixAllUpUnitBet;
        calculate();
        setAllupData({...allupData});
    }, [context.content.mixAllUpUnitBet, context.content.mixAllUpFormula]);

    useEffect(() => {
        let sels = context.content.oddsSelection.filter(x=>x.checked);

        let alupSize = (new Set(sels.map(x => x.matchId))).size;
        let alupCnt = context.content.mixAllUpFormula!='' ? parseInt(context.content.mixAllUpFormula.split('x')[0]) : 0;
        if (alupSize > 1 && alupCnt!=alupSize ) {
            allupData.alupFormula = context.content.config.AllupFormula[alupSize][0];
        }
        else if (alupSize <= 1) {
            allupData.alupFormula = '';
            allupData.noOfComb = '-';
            allupData.dividend = '-';
            allupData.netReturn = '-';
        }
        calculate();
        context.state.onMixAllUpContentUpdate({ alupFormula: allupData.alupFormula });
        setAllupData({...allupData});
    },  [context.content.oddsSelection]);

    const handleClickBack = () => {
        let type = context.content.selection.type;
        let val = null;
        if ( type==="DATE") {
            val = getQueryDateRange(context.content.listData.matches, context.contentRef.current.offset, context.content);
        }
        if ( type==="TOURN" ) {
            val = getQueryTorunIds(context.content.listData.matches, context.content);
        }
        if ( type==="SEARCH" ) {
            val = context.content.selection.value?.[0]=='OTHER'?null:context.content.selection.value;
            context.contentRef.current.selection.isAllupDetailBack=true;
        }
        context.clearOddsSelection(true)
        context.state.onSelectionChange(type, val, false);
    }

    const handleClickOddsType = (_type) => {
        let newSelection = []
        if(_type === 'clear') {
            // newSelection = []
        } else if(_type === 'all') {
            const mixAllupPools = context.content.config.MixAllupPools;
            mixAllupPools.map((_oddsType) => {
                let etBetType = getExtraTimeBetType(_oddsType);
                newSelection.push(_oddsType);
                if ( etBetType )
                newSelection.push(etBetType);
            })
        } else {
            newSelection = [...selectedOddsType];
            let etBetType = getExtraTimeBetType(_type);
            if (selectedOddsType.includes(_type)) {
                newSelection = newSelection.filter(x=> x!==_type && x!==etBetType);
                context.contentRef.current.oddsSelection = context.content.oddsSelection.filter(x => x.oddsType!==_type && x.oddsType!==etBetType);
                context.setContent({...context.contentRef.current})
            } else {
                newSelection.push(_type);
                if ( etBetType )
                   newSelection.push(etBetType);
            }
        }
        Cookies.setDataArray(COOKIE_NAME_SELECT_BETTYPES, newSelection)
        setSelectedOddsType([...newSelection])
    }

    const handleSelectFormula = (item) => {
        context.state.onMixAllUpContentUpdate({ alupFormula: item });
    }

    const handleBetUnitChange = (event) => {
        const tranValue = event.target.value.replace(/[\,\$]/g, '')
        if (tranValue!='' && tranValue >= 0) {
            context.state.onMixAllUpContentUpdate({ unitBet: parseInt(tranValue) });
        }
        else {
            context.state.onMixAllUpContentUpdate({ unitBet: -1 });
        }
    }

    const quickUnitBetChange = (amt) => {
        let newAmt = allupData.unitBet + amt;
        if ( newAmt < 10 ) {
            newAmt = 10;
        }
        else if ( newAmt > parseInt(GetChannelPara('MaxBetUnit')) ) {
            newAmt = parseInt(GetChannelPara('MaxBetUnit'));
        }
        context.state.onMixAllUpContentUpdate({ unitBet: newAmt });
    }

    const checkBetUnit = (event) => {
        if (event.target.value < 10) {
            alert(t('LB_FB_AMOUNTERROR'))
            context.state.onMixAllUpContentUpdate({ unitBet: 10 });
        }
    }

    const resetAllupCal = () => {
        allupData.noOfComb = '-';
        allupData.dividend = '-';
        allupData.netReturn = '-';
        allupData.alupCnt = 0;
        allupData.alupFormula = '';
        setAllupData({...allupData});
        context.state.clearAllupSelection()
        context.state.onMixAllUpContentUpdate({ unitBet: 10, alupFormula: '' });
    }

    function calMultiSel(noOfSelArr, maxOddsArr) {
        const allSelection = alupFormulaItem[allupData.alupFormula];

        let totalSel = 0;
        let tmpSel = 0;
        let tmpMaxDiv = 0;
        let estMaxDiv = 0;

        for (let i = 0; i < allSelection.split("#").length; i++) {    //allSelection == 1#2#3#12#13#23#123
            tmpSel = 1;
            tmpMaxDiv = 1;
            let tvalue = allSelection.split("#")[i];  //tvalue == 1, tvalue == 2, tvalue == 3, tvalue == 12

            if (tvalue.length == 1) {
                tmpSel = noOfSelArr[tvalue - 1];
                tmpMaxDiv = maxOddsArr[tvalue - 1];
            } else {
                for (var j = 0; j < tvalue.length; j++) {
                    tmpSel *= noOfSelArr[tvalue.substr(j, 1) - 1];
                    tmpMaxDiv *= maxOddsArr[tvalue.substr(j, 1) - 1];
                }
            }
            totalSel += tmpSel;
            estMaxDiv += tmpMaxDiv;
        }

        estMaxDiv *= allupData.unitBet;
        let netRtn = Math.round(estMaxDiv * 10) / 10 - allupData.unitBet * totalSel;

        allupData.noOfComb = totalSel;
        allupData.dividend = estMaxDiv;
        allupData.netReturn = netRtn;
    }

    const calculate = () => {

        let alupSize = allupData.alupFormula!='' ? parseInt(allupData.alupFormula.split('x')[0]) : 0;
        if(allupData.unitBet=='' || alupSize<2)
            return;

        let sels = context.content.oddsSelection.filter(x => x.checked);
        let selsByMatch = sels.reduce((init, next) => {
            let key = next.matchId;
            let [ tempOddsType, line, combId, _, comb ] = next.id.split("_");
            init[key] = init[key] || [];
            init[key].push({type: tempOddsType, line: next.lineNum, combId: combId, comb: comb, odds: parseFloat(next.oddsVal)});
            return init;
        }, {});

        let noSelArr = [];
        let maxOddsArr = [];
        const linePools = context.content.config.GoalLinePools;

        for ( let idx in selsByMatch ) {
            if (selsByMatch.hasOwnProperty(idx)) {
                selsByMatch[idx].sort((a,b) => {
                    if(!a.line || !b.line) return 0
                    let aLines = a.line.split('/');
                    let bLines = b.line.split('/');
                    if (parseFloat(aLines[0]) != parseFloat(bLines[0]))
                        return parseFloat(aLines[0]) - parseFloat(bLines[0]);
                    else if (aLines.length==1 && bLines.length > 1)
                        return -1;
                    else if (aLines.length>1 && bLines.length==1)
                        return 1;
                    return parseInt(a.combid) - parseInt(b.combid);
                });
                noSelArr.push(selsByMatch[idx].length);
                var maxodds = Math.max(...selsByMatch[idx].map(x=> x.odds))
                if (linePools.includes(selsByMatch[idx][0].type)) {
                    // find all possible sum of odds, only H>L
                    // all Low odds
                    var lOdds = 0;
                    for (var j = 0; j < selsByMatch[idx].length; j++) {
                        if (selsByMatch[idx][j].comb === "L")
                            lOdds += selsByMatch[idx][j].odds;
                    }
                    maxodds = lOdds;

                    // cross
                    var hOdds = 0;
                    for (var j = 0; j < selsByMatch[idx].length; j++) {
                        if (selsByMatch[idx][j].comb === "H" ) {
                            hOdds += selsByMatch[idx][j].odds;
                        }
                        var tmpOdds = 0;
                        for (var k = j + 1; k < selsByMatch[idx].length; k++) {
                            if (selsByMatch[idx][k].line != selsByMatch[idx][j].line
                                && selsByMatch[idx][k].comb === "L" ) {
                                tmpOdds += selsByMatch[idx][k].odds;
                            }
                        }
                        if ((hOdds + tmpOdds) > maxodds)
                            maxodds = hOdds + tmpOdds;
                    }
                }
                maxOddsArr.push(maxodds);
            }
        }

        calMultiSel(noSelArr, maxOddsArr);
    }

    const MatchContent = ({singleMatch, context, AllOddsOrder, selectedOddsType }) => {
        const [matchTimeType, setMatchTimeType] = useState(allMatchTimeTypes?.[0]?.key)
        return <section className="MIXALLUP_DETAIL_SINGLE">
                <MatchInfoContent singleMatch={singleMatch} context={context} />
                {/* <FBMatchTypeTab allMatchTimeTypes={allMatchTimeTypes} selected={matchTimeType} setSelectd={setMatchTimeType} match={singleMatch} selectedOddsType={selectedOddsType}/> */}
                <LoadSingleMatchTable matchTimeType={matchTimeType} singleMatch={singleMatch} AllOddsOrder={AllOddsOrder} selectedOddsType={selectedOddsType}/>
            </section>
    }
    const AllMatchTable = useCallback(({context, AllOddsOrder, selectedOddsType, selectedMatch}) => {
        let matches = context.content.matchData.matches;
        return matches.map((_singleMatch, i) => {
            if(isMobileSize && selectedMatch !==i) {
                return null
            } else {
                return <MatchContent  key={_singleMatch.id} singleMatch={_singleMatch} context={context} AllOddsOrder={AllOddsOrder} selectedOddsType={selectedOddsType}/>
            }
        })
    },[])

    const MatchInfoContent = useCallback(({ singleMatch, context }) => {
        return <MatchInfo context={context} singleMatch={singleMatch} />
    },[])

    const LoadSingleMatchTable = useCallback(({ singleMatch, AllOddsOrder, selectedOddsType, matchTimeType }) => {
        let curAllOddsOrder = AllOddsOrder;
        if(matchTimeType != 'ALL'){
            const pools = window.fbConfig[matchTimeType == 'FH' ? 'FirstHalfPools': 'FullTimePools']
            curAllOddsOrder = curAllOddsOrder.filter(i => pools.includes(i))
        }
        return curAllOddsOrder.map(betType => {
            if ( selectedOddsType.includes(betType) ) {
                let foPool = getFoPool(singleMatch, betType, null);
                return foPool!=null ? <SingleMatchTable betType={betType} match={singleMatch} foPool={foPool} key={betType}/> : null;
            }
            return null;
        })
    },[])

    const mixAllUpParams = {
        selectedFormula: allupData.alupFormula,
        betUnit: allupData.unitBet,
        noOfComb: allupData.noOfComb
    }

    const disabledResetBtn = !context.content.oddsSelection.find(i => i.checked)
    const disabledAddBtn = [...new Set(context.content.oddsSelection.filter(i => i.checked).map(i => i.matchId))].length < 2
    const [selectedMatch, setSelectedMatch] = useState(0)
    return <>
        <div className='mixallup-detail-calculator'>
        <Sticky innerZ={200}>
            <div className='header'>
                <a className='left dfc' onClick={() => handleClickBack()}>
                    <ArrowIcon className='m-hueRotate' direction='left' size={24}/>
                    <div className="title-m">{t('LB_FB_TITLE_MIXALLUP')}</div>
                </a>

                <div className='right hideM'>
                    <FBMixAllUpAddtoSlipBtn
                    disabled={disabledAddBtn}
                    isMixAllUp
                    MixAllUpParams ={mixAllUpParams} />
                </div>
            </div>
            {isMobileSize && <Matchs {...{selectedMatch, setSelectedMatch, context, AllOddsOrder, selectedOddsType}}/>}
        </Sticky>
            <AllupBetTypeBox selectedOddsType={selectedOddsType} handleClickOddsType={handleClickOddsType} />
            <div className='refresh-time-container'>{isMobileSize && <RefreshTime product="football" />}</div>
            <AllupDetailCalPanel allupData={allupData} handleBetUnitChange={handleBetUnitChange}
                checkBetUnit={checkBetUnit} quickUnitBetChange={quickUnitBetChange}
                handleSelectFormula={handleSelectFormula}
                stickyBottom = {isMobileSize}
                isShow={!disabledAddBtn}
                >
                <div className={`${isMobileSize ? 'text-button' : 'btnReset cta_fb'} ${disabledResetBtn ? 'disabled' : ''}`} onClick={() => !disabledResetBtn && resetAllupCal()}>{t('LB_FB_RESET')}</div>
                <FBMixAllUpAddtoSlipBtn isMixAllUp MixAllUpParams={mixAllUpParams} disabled={disabledAddBtn}/>
            </AllupDetailCalPanel>
        </div>

        <AllMatchTable selectedMatch={selectedMatch} context={context} AllOddsOrder={AllOddsOrder} selectedOddsType={selectedOddsType}/>

        <Remark betType={'MIXALLUP'} />

        <div className="fb-bottom-betslip">
        <FBMixAllUpAddtoSlipBtn
        isMixAllUp
        disabled={disabledAddBtn}
        MixAllUpParams={mixAllUpParams} />
        </div>
    </>
}


const Matchs = ({selectedMatch, setSelectedMatch, context, AllOddsOrder, selectedOddsType }) => {
    let matches = context.content.matchData.matches;
    const oddsSelectionChecked = [...new Set(context.content.oddsSelection.filter(i => i.checked).map(i => i.matchId))]
    const { t, i18n } = useTranslation();
    return <div className='matches'>
            {
                matches.map((_singleMatch, i) => {
                    let homeTeam = _singleMatch.homeTeam['name_' + i18n.language];
                    let awayTeam = _singleMatch.awayTeam['name_' + i18n.language];
                    return <div className={`match ${selectedMatch === i ? 'selected-match' : ''}`} onClick={() => setSelectedMatch(i)}>
                        <div className='match-name'>
                            <span className='single-text-ellipsis'>{homeTeam}</span>
                            <span className='single-text-ellipsis'>{awayTeam}</span>
                        </div>
                        <div className={`match-tick ${oddsSelectionChecked.includes(_singleMatch.id) ? 'selected' : ''}`}></div>

                    </div>
                })
            }
        </div>
}
export default MIXALLUP_DETAIL;

