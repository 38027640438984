import React, { useContext, useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CashOutImportantNotice from './CashOutImportantNotice'
import CashOutMain from './CashOutMain'
import CashOutSettled from './CashOutSettled'
import { CashoutTitleUI } from './CashOutBase'
import { CashOutMsgDialog, CashOutTabComp, CashOutAllBtnDialog, CashOutLogoutUI, CashOutErrorUI, haveKickoffESMatch, haveMatchEndedESMatch,CashOutDisabledUI } from './CashOutBase'
import { ConfigContext } from '../../Common/ConfigHelper'
import { isEarlySettlementEanbled } from '../../Football/Common/Common';
import { useWindowSize } from '../../Common/CommonHooks';
import { FootballContext } from '../FootballPage';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { isLogonHigh } from '../../BetSlip/LoginHooks';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import RefreshTime from '../../Common/ui-components/RefreshTime';
import Loading from '../../Common/ui-components/Loading';
import { Cookies } from "../../Common/CookieHelper";
import { isCombESTempNotOffer } from '../../Football/Common/MatchBase';


const CashOut = () => {
    const { t, i18n } = useTranslation()
    const { betslipMenu } = useContext(ConfigContext);
    const globalContext = useContext(GlobalContext);
    const fbContext = useContext(FootballContext);
    const { setCashoutInProcess } = globalContext;
    const { isMobileSize } = useWindowSize();
    const navigate = useNavigate();
    const CashOutLoadingSeconds = betslipMenu?.CashOutLoadingSeconds
    const [curTab, setCurTab] = useState('ALL') //ALL LIVE WINNING SETTLED
    const [isLoading, setIsLoading] = useState(false)
    
    const tabSliderRef = useRef()
    const timer = useRef(null);
    const [startLoadTime, setStartLoadtime] = useState('')
    const [showAllBtnDialog, setShowAllBtnDialog] = useState(false)

    const [showMoreBtn, setShowMoreBtn] = useState({})
    const [showSettledMoreBtn, setShowSettledMoreBtn] = useState({})

    // dataSource->item.state : begins preview loading  successful rejected unknow
    const loading = globalContext.esHook?.esLoading;
    const cashoutData = globalContext.esHook?.data || [];
    const esValues = globalContext.esHook?.esValues || [];
    const esStates = globalContext.esHook?.esStates || {};
    const esSettleds = globalContext.esHook?.esSettleds || [];
    const esErrorMsg = globalContext.esHook?.esErrorMsg;
    const esApiError = globalContext.esHook?.esApiError;
    const isShowAllupTab = globalContext.globalState?.commonCtrl?.earlySettlementFilterDisplayEnabled ;
    const singleBetData = cashoutData.filter(item=> !(item.allUpLevel>1));
    const allupBetData = cashoutData.filter(item=> item.allUpLevel>1);
    const liveBetData = cashoutData.filter(item => haveKickoffESMatch(item));
    const winningBetData = cashoutData.filter(item => {
        let esValue = esValues.filter(x=> x.betId===item.uniqueTicketId)[0];
        const isTempNotOffer = isCombESTempNotOffer(item.legs)
        if ( esValue ) {
            return !isTempNotOffer && !esValue.blockedForSell && (item.allUpLevel > 1 ? esValue.cashoutValue > (item.remainingBetTotal / 100) : esValue.cashoutValue > (item.ticketCostAmt / 100) );
        }
        return false;
    });
    const frontTab = isShowAllupTab ? [
        { tabName: 'SINGLE', count: singleBetData.length },
        { tabName: 'ALLUP', count: allupBetData.length },
    ]:[
        { tabName: 'ALL', count: cashoutData.length },
    ];
    const tabsConfig = [
        ...frontTab,
        { tabName: 'LIVE', count: liveBetData.length },
        { tabName: 'WINNING', count: winningBetData.length },
        { tabName: 'SETTLED', count: esSettleds.length }
    ];
    
    const onClickTab = (newTab, e) => {
        console.log(globalContext, isESDisabled, cashoutData)
        if (newTab === curTab) return
        if ( newTab.tabName!="SETTLED" ) {
            fbContext.state.reloadPage(true);
        }
        globalContext.esHook.clearEsState();
        setCurTab(newTab)
        // globalContext.esHook.parseEsMessage('acpt_upd', {
        //     resultStatus: "COS_BET_ALREADY_RESULTED",
        //     accountNumber: "20052425",
        //     betId: "31914682021",
        //     collationId:1,
        //     earlySettleTime:"2020-08-25 06:42:31.697",
        //     soldFor:9
        // });
    }

    const handleClickCashOutAll = () => {
        setShowAllBtnDialog(true);
        glassboxCustomEvent("Cash_Out_All");
    }

    const onConfirmDialog = () => {
        setShowAllBtnDialog(false)
        glassboxCustomEvent("Cash_Out_All_OK");
    }

    const onCancelDialog = () => {
        setShowAllBtnDialog(false);
        glassboxCustomEvent("Cash_Out_All_Cancel");
    }

    const jumpUrl = (type) => {
        if(cashoutData?.length > 0){
            findInitTab();
        }else {
            navigate(`/${i18n.language}/football/home`)
        }
    }

    const findInitTab = () => {
        let tabInitIndex = 0;
        if(isShowAllupTab && singleBetData.length<1 && allupBetData.length>0){
            tabInitIndex = 1;
        }
        setCurTab(tabsConfig[tabInitIndex].tabName)
    }

    useEffect(() => {
        Cookies.setData('esShortcutCloseClick','1')
    }, [])

    // useEffect(() => {
    //     if (!loading) {
    //         findInitTab();
    //     }
    // }, [isShowAllupTab, loading]);
    useEffect(() => {
            findInitTab();
    }, [isShowAllupTab]);

    useEffect(() => {
        if (  !globalContext.globalState.commonCtrlLoading && !isEarlySettlementEanbled(globalContext) ) {
            navigate(`/${i18n.language}/football/home`);
        }
    }, [globalContext.globalState.commonCtrlLoading]);

    useEffect(() => {
        if (startLoadTime) {
            setCashoutInProcess(true);
            disDomFn('add')
            setIsLoading(true)
            timer.current !== null && clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setIsLoading(false)
                setStartLoadtime('')
                setCashoutInProcess(false);
                disDomFn('minus')
            }, CashOutLoadingSeconds * 1000);
        }
        return () => {
            timer.current !== null && clearTimeout(timer.current);
        }
    }, [startLoadTime]);

    const disDomFn = (type) => {
        const mobileDisDomArr = ['.fMenuTR-mobile']
        const desktopDisDomArr = ['.betslip-menu', '.logout', '.leftMenuMain', '.fMenuTR', '.topInfoMenu', '.important-notice', '.topMenu', '.footer', '.refresh-icon']
        const disDomArr = isMobileSize ? mobileDisDomArr : desktopDisDomArr;
        for (let i = 0; i < disDomArr.length; i++) {
            toggleClassN(document.querySelector(`${disDomArr[i]}`), type)
        }
    }

    const toggleClassN = (ele, type) => {
        if (!ele) { return console.log('error', ele); }
        const className = `cashoutInProcess`
        let classString = ele.className
        const nameIndex = classString.indexOf(className)
        if (nameIndex < 0 && type == 'add') {
            classString += ' ' + className
        } else if (nameIndex >= 0 && type == 'minus') {
            classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
        }
        ele.className = classString
    }

    const recalcLoadingTime = () => {
        const dateNow = Date.now();
        setStartLoadtime(dateNow)
    }

    const handleClickBack = () => {
        navigate(-1);
    }

    const refreshPageFn=()=>{
        console.error('refresh');
    }

    const onClickMoreButton = (accountTranNo, e) => {
        let newData = {...showMoreBtn}
        newData[accountTranNo] = !showMoreBtn[accountTranNo]
        setShowMoreBtn(newData)
    }

    const onClickSettledMoreButton = (accountTranNo, e) => {
        let newData = {...showSettledMoreBtn}
        newData[accountTranNo] = !showSettledMoreBtn[accountTranNo]
        setShowSettledMoreBtn(newData)
    }

    const props = { recalcLoadingTime, curTab, cashoutData, esValues, esStates, esSettleds, esApiError, jumpUrl, showMoreBtn, isShowAllupTab, 
        singleBetData, allupBetData, liveBetData, winningBetData, onClickMoreButton }
    const tabProps = { tabsConfig, onClickTab, curTab, isLoading }
    const titleProps = {isLoading, handleClickBack};
    const isESDisabled = globalContext.globalState.commonCtrl.enableEarlySettlementUIMessage

    return !isLogonHigh() ?
        <section className='CASHOUT'>
            <CashoutTitleUI {...titleProps} />
            { isESDisabled ? <CashOutDisabledUI /> : <CashOutLogoutUI isMobileSize={isMobileSize} />}
        </section >
        :
        (esErrorMsg!='' ?
            <section className='CASHOUT'>
                <CashoutTitleUI {...titleProps} />
                <CashOutErrorUI />
            </section >
            : (cashoutData ?
                <section className='CASHOUT'>
                    <CashoutTitleUI {...titleProps} /> 
                    { isESDisabled ?<CashOutDisabledUI /> :<>
                        <CashOutTabComp {...tabProps} />
                        {curTab === 'SETTLED' ?
                            <CashOutSettled {...props}  isMobileSize={isMobileSize} showSettledMoreBtn={showSettledMoreBtn} onClickSettledMoreButton={onClickSettledMoreButton} />
                            : <>
                                <CashOutImportantNotice handleClickCashOutAll={handleClickCashOutAll} isLoading={isLoading} tabShowData={cashoutData} esApiError={esApiError} esStates={esStates} />
                                {(isMobileSize && cashoutData.length > 0) && <div className='refreshContainer'><RefreshTime product={'football'}/></div>}
                                <CashOutMain {...props} isLoading={isLoading} />
                            </>
                        }
                    </>}
                    <CashOutMsgDialog />
                    <CashOutAllBtnDialog showAllBtnDialog={showAllBtnDialog} onCancelDialog={onCancelDialog} onConfirmDialog={onConfirmDialog} />
                </section>
                : <Loading/>)
            )
}

export default CashOut
