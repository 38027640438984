import React, { useEffect, useState, createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateObj } from '../../Common/GlobalFunc';
import CheckResultSelectedNumTable from '../Common/CheckResultSelectedNumTable';
import { CheckResultMyFav } from '../Common/CheckResultMyFav';
import CheckResultsTable from '../Common/CheckResultsTable'
import ResultDetail from '../Common/ResultDetail'
import { useQuery, useLazyQuery } from '@apollo/client';
import { getMarksixResultQuery } from '../Data/DataQuery';
import { MarksixContext } from '../../Home/MarksixPage';
import { getM6RespDate } from '../Common/Common';
import {MyFavouritesContext} from '../Common/MyFavourites';
import { GlobalContext } from '../../../contexts/GlobalContext';

export const RrawResultContext = createContext();

const CheckDrawResult = (props) => {
    const [isShowCheckTable, setIsShowCheckTable ] = useState(false)
    const {t, i18n} = useTranslation()
    const context = useContext(MarksixContext);
    const {sessionServerTimeStamp} = useContext(GlobalContext);
    const {  favInitCheckState, setFavInitCheckState } = useContext(MyFavouritesContext);
    const [detailId, setDetailId] = useState(null)
    const [detailInfo, setDetailInfo] = useState({})
    const [getResultInfo,setGetResultInfo]=useState([])
    const [apiErr,setApiErr]=useState(false);
    const initDefaultUnitBet = sessionStorage.getItem('Mk6DefaultUnitBetAmount')?Number(sessionStorage.getItem('Mk6DefaultUnitBetAmount')):10;
    const [unitBet, setUnitBet] = useState(initDefaultUnitBet)
    const [selectedTableData, setSelectedTableData] = useState([{ numList: [], bankerList: [], isFav: false, chance: 0, unitBet }])
    const { checkRrawNumber, setCheckRrawNumber } = useContext(RrawResultContext)
    const [mySelectData, setMySelectData] = useState({ sinceNumber: false, timersNumber: false, isMultiCheck: true })
    const [betTypeSelect, setBetTypeSelect] = useState([
        { type: "banker", name1: 'LB_M6_BANKERLABEL', disabled: false, checked: true },
        { type: "leg", name1: 'LB_M6_LEGLABEL', disabled: false, checked: false },
      ]);
    const [initPage, setInitPage] = useState(0)

    useEffect(() => {
        setInitPage(1)
    }, [context.pageName])

    
    useEffect(() => {
        if(checkRrawNumber.checked && initPage){
            onChangeCheckTable(false)
            const {bankerList, numList} = checkRrawNumber;
            let newTableData=JSON.parse(JSON.stringify(selectedTableData));
            if(bankerList?.length>0){
                newTableData[0].bankerList=bankerList;
                if(!betTypeSelect[1].checked){
                    let newBetArr=[...betTypeSelect];
                    newBetArr.map(item => {
                        item.checked = !item.checked;
                      })
                    setBetTypeSelect(prev => { return [...newBetArr] });
                }
                setMySelectData({...mySelectData,isMultiCheck:false})
            }else{
                newTableData[0].bankerList=[];
            }
            newTableData[0].numList=numList;
            onChangeCheckTable(true)
            setSelectedTableData(newTableData)
        }
    }, [checkRrawNumber])

    let toDate = new Date(sessionServerTimeStamp);
    toDate.setTime(toDate.getTime() + (context.gqlVars?.data?.timeOffset?.m6 || 0) * 1000);
    let ts=context.gqlVars?.data?.timeOffset?.ts;
    if(ts){
        toDate=getM6RespDate(ts);
    }
    let fromDate = new Date(toDate);
    fromDate.setDate(fromDate.getDate()- 60);
    const [ loadQuery, resGqlVars ] = useLazyQuery(getMarksixResultQuery(), {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: data=> { 
            if ( data.lotteryDraws!=null ) {
                setGetResultInfo(data.lotteryDraws)
            }
            setApiErr(false);
        },
        onError:err=>{
            setApiErr(true);
        }
    });

    const handleDrawnNumberCheck = () => {
        loadQuery({ variables: {
            "startDate": formatDateObj(fromDate)[0].replace(/-/g, ""),
            "endDate":  formatDateObj(toDate)[0].replace(/-/g, ""),
            "drawType": "All"
        } });
    }

    useEffect(() => {
        handleDrawnNumberCheck();
    }, []);

    const onChangeCheckTable = (bool)=>{
        setIsShowCheckTable(bool)
    }

    const handleResultDtls = (id) => {
        setDetailId(id)
        let getDetailObj = getResultInfo.find(item => item.id === id)
        setDetailInfo(getDetailObj)
    }

    const cancelFavCheck = () => {
        if(!checkRrawNumber.checked) return ;
        const dateNow = Date.now();
        setFavInitCheckState(dateNow)
        setTimeout(() => {
            setFavInitCheckState('')
        }, 300);
    }

    const CDRProps = {
        onChangeCheckTable, 
        unitBet, 
        setUnitBet, 
        selectedTableData, 
        setSelectedTableData,
        betTypeSelect, 
        setBetTypeSelect,
        mySelectData, 
        setMySelectData,
        cancelFavCheck,
      }

    return (
        <section className={context.pageName}>
            {detailId ?
                <ResultDetail handleClickBack={() => setDetailId('')} draw={detailInfo} />
                : <>
                    <header className='result-header-m check-draw-header'>{t('LB_M6_CHECKDRAWRESULT')}</header>
                    <CheckResultSelectedNumTable {...CDRProps} />
                    {isShowCheckTable &&
                        <CheckResultsTable onclickResultDtls={handleResultDtls} getInfo={getResultInfo} apiErr={apiErr} selectedTableData={selectedTableData} />
                    }
                    <CheckResultMyFav />
                </>}
        </section>
    );
}

export default CheckDrawResult