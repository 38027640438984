import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Swipe } from './swipe';
import { loadBanner } from './bannerAdScript';
import './index.scss';

const News = () => {
    const { i18n } = useTranslation();
    const bannerProcess = useRef(0);
    const tmpBanner = useRef([]);
    const [bannerArr, setBannerArr] = useState([]);

    useEffect(()=> {
        let lang = i18n.language==="ch" ? "C" : "E";
        tmpBanner.current = [];
        bannerProcess.current = 0;
        setBannerArr([]);
        window.bannerAdIds["GL"].forEach((item, idx) => {
            loadBanner(item + lang).onload = () => {
                let bannerAdList = window['sdceo_getZoneBanner_' + item + lang]?.(1)?.BannerAdList;
                if ( bannerAdList.length > 0 ) {
                    bannerAdList[0].idx = idx;
                }
                tmpBanner.current.push(...bannerAdList);
                bannerProcess.current++;
                if ( bannerProcess.current===window.bannerAdIds["GL"].length ) {
                    setBannerArr(tmpBanner.current);
                }
            };
        });
    }, [i18n.language]);

    const Banner = useCallback(()=> {
        return <Swipe key={JSON.stringify(bannerArr)} bannerArr={bannerArr} />
    }, [bannerArr])

    return <Banner />
};

export default News;
