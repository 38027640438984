import React, { useEffect, useContext, useRef, useState, useCallback, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { FootballContext } from '../../Home/FootballPage';
import { TournamentSlider } from '../../Common/Slider';
import { getDayOfWeekShort } from '../../Common/home-common';
import FBSearchButton, {
     FBSearchInput
     } from './FBSearchButton'
import { isPoolDefined, getQueryDateRange, getQueryTorunIds, isTournamentPage, hasAnyFeaturedMatch } from './Common';
import MobileMenu from '../../Menu/MobileMenu';
import CheckBox from '../../Common/ui-components/CheckBox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { useFBSearch } from './FBSearchHooks'
import Sticky from 'react-stickynode';
import moment from 'moment';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { useNavigate } from "react-router-dom";
import { scrollTo } from '../../Common/GlobalFunc';


const DateTab = ({ changeDate }) => {
    const context = useContext(FootballContext);
    const isMobile = context.state.windowSize.isMobileSize;
    const { t, i18n } = useTranslation();
    const dateList = context.content.selection.tabs;
    const SliderContentRef = useRef();
    const [settings, setSettings] = useState('unslick');
    const [isload, setIsload] = useState(false);
    const timer = useRef(null);
    useEffect(() => {
        const handleResizeHandle = () => {
            timer.current !== null && clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setIsload(true);
                let width = 0;
                let count = 0;
                dateList.forEach((item, index) => {
                    if (item.isOther && item.date === item.firstOtherDate) {
                        width += 90;
                        count++;
                    } else if (item.isOther && item.date !== item.firstOtherDate) {
                    } else {
                        width += 60;
                        count++;
                    }
                });
                if(!SliderContentRef.current) return;
                const offsetWidth = SliderContentRef.current.offsetWidth;
                const show = Math.floor((offsetWidth - 40) / 60) - 1;
                if (offsetWidth < width) {
                    setSettings({
                        dots: false,
                        infinite: false,
                        speed: 10,
                        initialSlide: 0,
                        // swipeToSlide: true,
                        variableWidth: true,
                        arrows: true,
                        slidesToScroll: show,
                        slidesToShow: show
                        // nextArrow: <NextArrow />,
                        // prevArrow: <PrevArrow />
                    });
                } else {
                    setSettings('unslick');
                }
            }, 250);
        };
        handleResizeHandle();
        window.addEventListener('resize', handleResizeHandle);
        return () => window.removeEventListener('resize', handleResizeHandle);
    }, [dateList]);

    const dateListUi = useMemo(()=>dateList.map((item, index) => {
        let selected = context.content.selection.value[0] == item.date;
        let dtCurr = moment(item.date).toDate();
        if (item.isOther && item.date === item.firstOtherDate) {
            return (
                <div id="tabOther" className={`other ${selected ? 'active' : ''}`} onClick={() => changeDate(item.date, true)} key={'other'}>
                    {t('LB_FB_OTHER')}
                </div>
            );
        } else if (item.isOther && item.date !== item.firstOtherDate) {
            return null;
        } else {
            return (
                <div style={{ width: 60 }} key={item.date}>
                    <div id={`tab${item.date.replace(/-/g, "")}`} className={`date ${selected ? 'active' : ''}`} onClick={() => changeDate(item.date, false)}>
                        <span>
                            {dtCurr.getDate()}/{dtCurr.getMonth() + 1}
                        </span>
                        <span>({getDayOfWeekShort(item.date, t)})</span>
                    </div>
                </div>
            );
        }
    }),[dateList,context]);

    const isUnslick = settings === 'unslick' || isMobile;
    return (
        <div
            ref={SliderContentRef}
            style={{
                padding: isUnslick ? '' : '0 20px',
                display: isUnslick ? 'inline-flex' : '',
                position: 'relative'
            }}
            id="dateListUi"
        >
            {isload &&
                (isUnslick ? (
                    dateListUi
                ) : (
                    <>
                        <Slider {...settings}>{dateListUi}</Slider>
                        <div className="gradient"></div>
                    </>
                ))}
        </div>
    );
};

const TournamentTab = ({expandTournPanel,selectedTournament, context, expand, submitTournamentChange,
    TournamentTabMobile, maxTournamentTab, changeTournament, onClickHandleTournReset,
    onClickHandleTournExpand, onClickHandleTournAll}) => {
    const {t, i18n} = useTranslation()
    let isMobile = context.state.windowSize.isMobileSize;
    let tournamentList = context.content.selection.tabs;
    return (
        <>
            {(!isMobile || expandTournPanel) &&<div className="control">
                <div className="left" onClick={() => onClickHandleTournReset(tournamentList[0].code)}>
                    <span id='tournamentTabReset'>
                        {t('LB_FB_RESET')}
                    </span>
                </div>
                <div className="right" onClick={() => onClickHandleTournAll(tournamentList)}>
                    <span id='tournamentTabSelectAll'>
                        {t('LB_FB_SELECT_ALL')}
                    </span>
                </div>
            </div>}

            {!isMobile ? (
                <>
                    <div>
                        {tournamentList.map((item, index) => {
                            let selected = selectedTournament.filter((x) => item.code === x).length > 0;
                            let isSecondLevel = index > maxTournamentTab - 1;
                            return (
                                <div className={`tourn ${isSecondLevel && !expand ? 'hide' : ''}`} key={item.name_en}>
                                    <CheckBox
                                        size={18}
                                        noBorder
                                        product='football'
                                        id={`tabTourn${item.code}`}
                                        className="tourn-input"
                                        control
                                        checked={selected}  arrNumStayOne={selectedTournament.length} onClick={() => changeTournament(item.code)}
                                    />
                                    <label onClick={() => changeTournament(item.code)}>
                                        {item['name_' + i18n.language]}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                    {tournamentList.length > maxTournamentTab && (
                        <div className="more">
                            <span onClick={onClickHandleTournExpand}>
                                {expand ? t('LB_FB_HIDE') : t('LB_FB_SHOW_ALL')}
                                <ArrowIcon direction='down' change={expand} changeDirection='up' size={24} />
                            </span>
                        </div>
                    )}
                </>
            ) :
               <>{expandTournPanel && <>
                    <TournamentSlider>{TournamentTabMobile(tournamentList)}</TournamentSlider>
                    {<div className="more"></div>}
                </>}</>
            }

            {/* {(!isMobile || expandTournPanel) &&<div className="control">
                <div className="left">
                    <button className="btn-selectAll" onClick={() => onClickHandleTournAll(tournamentList)}>
                        {t('LB_FB_SELECT_ALL')}
                    </button>
                    <button className="btn-reset" onClick={() => onClickHandleTournReset(tournamentList[0].code)}>
                        {t('LB_FB_RESET')}
                    </button>
                </div>
                <div className="right">
                    <button onClick={submitTournamentChange} className="btn-submit">
                        {t('LB_FB_APPLY_FILTER')}
                    </button>
                </div>
            </div>} */}
        </>
    );
};

const DateTournSelection = (props) => {
    const context = useContext(FootballContext);
    const globalContext = useContext(GlobalContext);
    const { t, i18n } = useTranslation();
    const selectedTab = context.content.selection.type;
    const [expandTournPanel, setExpandTournPanel] = useState(true)
    const navigate = useNavigate();
    const {
        activeSearch,
        searchText,
        showSearchHistory,
        showClearSearchResultBtn,
        inputFocus,
        searchItem,
        searchResults,
        showMobileInputBox,
        handleClickClearResults,
        setShowMobileInputBox,
        setSearchItem,
        setSearchText,
        setActiveSearch,
        onInputSearchText,
        onBlurSearchInput,
        onFocusSearchInput,
        handleClickTeamName: realHandleClickTeamName,
        handleClickDeleteHistoryBtn,
        handleClickSearchResultMask,
        onKeyDownEnterKey
    } = props.fbSearchHooks

    const ignoreCheckedLines = () => {
        if(!context?.content?.config.MixAllUpPages.includes(context?.content?.page))
        {
            let checkedLinesList = context?.content?.oddsSelection?.filter((item) => {
                return item.checked
            })
            if(checkedLinesList.length > 0) {
                if(!confirm(t('LB_FB_UNSAVE_BET_ALERT'))){
                    return false;
                } else {
                    context?.clearOddsSelection?.()
                }
            }
        }
        return true;
    }

    const handleClickTeamName = (...params) => {
        if(!ignoreCheckedLines()) return
        realHandleClickTeamName(...params)
    }

    const getTournCodeList = () => {
        let codes = [];
        if (selectedTab == 'TOURN') {
            context.content.selection.value.forEach((item) => {
                let tourn = context.content.selection.tabs.find((x) => x.tournIds.includes(item));
                if (tourn != null && !codes.includes(tourn.code)) codes.push(tourn.code);
            });
        }
        return codes;
    };

    const [selectedTournament, setSelectedTournament] = useState(getTournCodeList());
    const [prevSelectTourn,setPrevSelectTourn]=useState(selectedTournament);
    const [expand, setExpand] = useState(false);

    useEffect(() => {
        setSelectedTournament([...getTournCodeList()]);
        setPrevSelectTourn([...getTournCodeList()]);
    }, [context.content.selection.value]);

    const maxTournamentTab = 16;

    const resetSelection = () =>{

        navigate(window.location.pathname)
        context.contentRef.current.selection.tournAll = false
    }

    const handleClickTab = (val, skipeCheckAction) => {
        if(context?.content?.isLoading) return
        if(!ignoreCheckedLines()) return
        const byDate = () => {
            const availMatches = context.content.listData.matches;
            const offset = context.contentRef.current.offset;
            context.contentRef.current.selection.tournAll = false
            context.state.onSelectionChange(val, getQueryDateRange(availMatches, offset, context.content), false);
        };

        const byTourn = () => {
            setExpand(false);
            if(isTournamentPage(context?.content.page)){
                context.state.onSelectionChange(val, [null], false);
                return
            }
            const availMatches = context.content?.listData?.matches || [];
            let tournIds = getQueryTorunIds(availMatches, context.content);
            context.state.onSelectionChange(val, tournIds, false);
        };

        const byFeaturedMatch = () => {
            context.state.onSelectionChange(val, null, false);
            resetSelection()
        };

        const bySearch = () =>{
            context.state.onSelectionChange(val,context.content.selection.type === 'ALL' ? null : ['OTHER'], false);
            setActiveSearch(true)
            resetSelection()
        }

        const byAll = () =>{
            context.state.onSelectionChange('ALL', null, false);
            resetSelection()
        }
        if (selectedTab !== val || skipeCheckAction) {
            switch (val) {
                case 'SEARCH':
                    bySearch()
                    return
                case 'DATE':
                    byDate();
                    break;
                case 'TOURN':
                    byTourn();
                    break;
                case 'OFM':
                    byFeaturedMatch();
                    break;
                default:
                    byDate();
                    break;
            }
            setActiveSearch(false)
            if(searchText === searchItem?.label){
                setSearchText('')
            }
            setExpandTournPanel(true)
            setSearchItem(null)
        }

        const deselectSearchModle = !searchItem?.label && ((isMobile && showMobileInputBox) || !isMobile)

        if(selectedTab === val && (selectedTab !='SEARCH' || deselectSearchModle) ){

            byAll()
        }
    };

    const handleClickSearchBtn = () => {
        handleClickTab('SEARCH')
        const top =getOffsetTop(document.querySelector('.date-competition-selection'))
            scrollTo(0, top)
        setShowMobileInputBox(isShow => !isShow)
    }

    const getOffsetTop = (el) => { //获取当前element距离html顶部的距离
        let topH = el.offsetTop
        if(el&&el.nodeName != 'HTML'){
            el = el.parentElement
            while(el&&el.nodeName != 'HTML'){
                if(getComputedStyle(el).position !== 'static'){
                    topH += el.offsetTop
                }
                el = el.parentElement
            }
        }
        return topH
    }



    const FeaturedMatchTab = useCallback(() => {
        let dateList = context.content.selection.tabs;
        return (
            <div>
                {dateList.map((item, index) => {
                    let selected = context.content.selection.value[0] == item.date;
                    let dtCurr = moment(item.date).toDate();
                    if (item.isOther && item.date === item.firstOtherDate) {
                        return (
                            <div
                                className={`other ${selected ? 'active' : ''}`}
                                onClick={() => changeDate(item.date, true)}
                            >
                                {t('LB_FB_OTHER')}
                            </div>
                        );
                    } else if (item.isOther && item.date !== item.firstOtherDate) {
                        return null;
                    } else {
                        return (
                            <div
                                className={`date ${selected ? 'active' : ''}`}
                                onClick={() => changeDate(item.date, false)}
                            >
                                <span>
                                    {dtCurr.getDate()}/{dtCurr.getMonth() + 1}
                                </span>
                                <span>({getDayOfWeekShort(item.date, t)})</span>
                            </div>
                        );
                    }
                })}
            </div>
        );
    }, [context.content.selection.value, context.state.page]);

    const onClickHandleTournExpand = () => {
        setExpand(!expand);
    };
    const onClickHandleTournReset = (val) => {
        if(!ignoreCheckedLines()) return
        setExpand(false);
        const resetCode=[context.content.selection.tabs[0].code];
        // setSelectedTournament(resetCode);
        submitTournamentChange(resetCode);
    };
    const onClickHandleTournAll = (val) => {
        let newList = context.content.selection.tabs.map((v, i) => {
            return v.code;
        });

        setExpand(true);
        // setSelectedTournament(newList);
        submitTournamentChange(newList);

    };



    const TournamentTabMobile = (tournList) => {
        let maxItem = 12;
        let tabList = [];
        let tab = [];

        tournList.map((item, idx) => {
            let selected = selectedTournament.filter((x) => item.code === x).length > 0;
            let name = item['name_' + i18n.language];

            tab.push(
                <div className={`tourn-m`} key={item.name_en}>
                    <CheckBox control product='football' noBorder id={`tabTourn${item.code}`} checked={selected} arrNumStayOne={selectedTournament.length} onClick={() => changeTournament(item.code)}/>
                    <label onClick={() => changeTournament(item.code)}>{name}</label>
                </div>
            );

            if ((idx + 1) % maxItem == 0 || idx == tournList.length - 1) {
                // tabList.push(<div>{tab}</div>);
                tabList.push(<div>{tab}</div>);
                tab = [];
            }
        });

        return tabList;
    };

    const changeDate = useCallback((date, isOther) => {
        if(!ignoreCheckedLines()) return
        context.state.onSelectionChange(context.content.selection.type, [date], isOther);
    });

    const changeTournament = useCallback((tName) => {
        if(!ignoreCheckedLines()) return
        let isExistTourn = selectedTournament.filter((x) => x === tName)[0];
        let newTournList = [...selectedTournament];
        if (isExistTourn) {
            // if(!context?.content?.config.MixAllUpPages.includes(context?.content?.page))
            // {
            //     let checkedLinesList = context?.content?.oddsSelection?.filter((item) => item.checked)
            //     const tournamentOfMatch = checkedLinesList?.map((i) => {
            //         const match = context?.content?.listData?.matches?.find((j) => i.matchId === j.id)
            //         return match && match.tournament.code
            //     })
            //     if(tournamentOfMatch?.includes(tName)) {
            //         if(!confirm(t('LB_FB_UNSAVE_BET_ALERT'))){
            //             return;
            //         } else {
            //             context?.clearOddsSelection?.()
            //         }
            //     }
            // }
            newTournList = newTournList.filter((x) => x !== tName);
        } else {
            newTournList.push(tName);
        }
        if(newTournList.length==1){
            setPrevSelectTourn([...newTournList])
        }
        // setSelectedTournament([...newTournList]);
        submitTournamentChange([...newTournList]);
    });

    const submitTournamentChange = (newArr) => {
        if (newArr.length == 0) {
            setSelectedTournament([...prevSelectTourn]);
            alert(t('LB_FB_ERROR_SELECT_TOURNAMENT'));
        } else {
            setSelectedTournament([...newArr]);
            let newTournIds = [];
            // setExpandTournPanel(false)
            newArr.forEach((code) => {
                let tourn = context.content.selection.tabs.find((x) => x.code === code);
                if(tourn?.tournIds){
                    newTournIds = [...newTournIds, ...tourn?.tournIds];
                }
            });
            context.state.onSelectionChange(selectedTab, newTournIds, null);
        }
    };

    const tournTabProps = {
        expandTournPanel, selectedTournament, context, expand, submitTournamentChange,
        TournamentTabMobile, maxTournamentTab, changeTournament, onClickHandleTournReset,
        onClickHandleTournExpand, onClickHandleTournAll, prevSelectTourn
    }


    const content = () => {
        let tabContent = null;

        switch (context.content.selection.type) {
            case 'DATE':
                tabContent = <DateTab changeDate={changeDate} />;
                break;
            case 'TOURN':
                tabContent = <TournamentTab {...tournTabProps}/>;
                break;
            case 'OFM':
                tabContent = FeaturedMatchTab();
                break;
            default:
                break;
        }

        return <>{tabContent}</>;
    };

    let config = context.content.pageConfig;
    let enable = config?.showDateTab || config?.showTournTab;
    let poolDefined = isPoolDefined(context.content);
    let isMobile = context.state.windowSize.isMobileSize;
    let disabledTab = isTournamentPage(context.content.page);
    let disableDateOption =
        selectedTab === 'DATE' && context.content.config.MixAllUpPages.includes(context.content.page) && false;
    let showFeaturedTab = globalContext.globalState.commonCtrl.enableFeaturedMatches &&
        config?.showFeaturedTab &&
        hasAnyFeaturedMatch(context.content.listData?.matches, context.content.listData?.timeOffset?.fb || 0);

    return (
        <>
        <Sticky innerZ={999} enabled={isMobile}>
            <div className={`date-competition-selection ${isMobile || (enable && (poolDefined || props.showNoResults)) ? '' : 'hide'}`}>
                {
                    <>
                        <header>
                            <div className={` ${activeSearch ? 'activeFBSearch' : ''} ${activeSearch && showMobileInputBox ? 'flexbsAuto' : ''}`}>
                                {config?.showSearchTab &&  <FBSearchButton
                                key={'FBSearchButton'}
                                active={activeSearch}
                                isMobile={isMobile}
                                onClickSearchBtn={handleClickSearchBtn}
                                inputVal={searchText}
                                onInputVal={onInputSearchText}
                                searchResults={searchResults}
                                onBlurInput={onBlurSearchInput}
                                onFocusInput={onFocusSearchInput}
                                inputFocus={inputFocus}
                                onClickTeamName={handleClickTeamName}
                                showSearchHistory={showSearchHistory}
                                onClickDeleteBtn={ handleClickDeleteHistoryBtn}
                                clearSearchResults={handleClickClearResults}
                                showInputBox={showMobileInputBox}
                                onKeyDownEnterKey={onKeyDownEnterKey}
                                />}
                                {(poolDefined || activeSearch)? (
                                    <>
                                        {config?.showDateTab && (
                                            <nav id="tabDate"
                                                key="tabDate"
                                                className={`date-tab ${selectedTab === 'DATE' && !activeSearch && 'active'} ${
                                                    disabledTab && 'disabled'
                                                }`}
                                                onClick={() => !disabledTab && handleClickTab('DATE')}
                                            >
                                                <div className="ic-calender" />
                                                <span>{t('LB_FB_BY_DATE')}</span>
                                            </nav>
                                        )}

                                        {config?.showTournTab && (
                                            <nav id="tabTourn"
                                            key='tabTourn'
                                                className={`tourn-tab ${selectedTab === 'TOURN' && !activeSearch && 'active'}`}
                                                onClick={() => handleClickTab('TOURN')}
                                            >
                                                <div className="ic-leagues" />
                                                <span>{t('LB_FB_BY_TOURNAMENT')}</span>
                                            </nav>
                                        )}

                                        {showFeaturedTab && (
                                            <nav id="tabFeaturedMatches"
                                                key='tabFeaturedMatches'
                                                className={`featured-tab ${selectedTab === 'OFM' && !activeSearch && 'active'}`}
                                                onClick={() => handleClickTab('OFM')}
                                            >
                                                <div className="ic-featuredMatch" />
                                                <span>{t('LB_FB_BY_FEATUREDMATCH')}</span>
                                            </nav>
                                        )}
                                    </>
                                ) : null}
                            </div>
                            <div className={`${activeSearch && showMobileInputBox ? 'hideMenu' : ''}`}>
                                <nav>
                                    {context.state.windowSize.isMobileSize && (
                                        <MobileMenu
                                            product="football"
                                            onPageChange={(url, page) => {
                                                context.state.onPageChange(url, page, false);
                                            }}
                                        />
                                    )}
                                </nav>
                            </div>
                        </header>
                        {activeSearch && !isMobile && <FBSearchInput
                        searchText={searchText}
                        onInputSearchText={onInputSearchText}
                        onBlurSearchInput={onBlurSearchInput}
                        onFocusSearchInput={onFocusSearchInput}
                        searchResults={searchResults}
                        onClickTeamName={handleClickTeamName}
                        inputFocus={inputFocus}
                        showSearchHistory={showSearchHistory}
                        onClickDeleteBtn={ handleClickDeleteHistoryBtn}
                        clearSearchResults={handleClickClearResults}
                        onKeyDownEnterKey={onKeyDownEnterKey}
                        /> }
                        {poolDefined && !disableDateOption && (!activeSearch || isMobile) && (
                            <section className={`section-${selectedTab} ${enable ? '' : 'hide'} ${selectedTab== 'TOURN'? 'mobileHideTourn':''}`}>
                                {( !['OFM','TOURN'].includes(selectedTab) || (!isMobile && selectedTab == 'TOURN')) && content()}
                            </section>
                        )}
                    </>
                }

                {inputFocus && isMobile && activeSearch && (searchText || showSearchHistory) && <div className='searchResultsListMask' onClick={handleClickSearchResultMask}></div>}
            </div>
        </Sticky>
        {isMobile && selectedTab == 'TOURN' &&<div className={`date-competition-selection ${isMobile || (enable && (poolDefined || props.showNoResults)) ? '' : 'hide'}`}>
            <section className={`section-${selectedTab} ${enable ? '' : 'hide'}`}>
                {content()}
            </section>
        </div> }
        </>
    );
};

export default DateTournSelection;


