import React, { useState, useEffect, useLayoutEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { handleClickRacingLive } from '../Common/home-common';
import AddSlipBtn from '../Footer/AddSlipMobileBtn';
import MoreDialog from '../Menu/MoreDialog';
import { AllupCheckbox } from '../Common/AllupCheckbox';
import { ConfigContext } from '../Common/ConfigHelper';
import { GlobalContext } from '../../contexts/GlobalContext';
import { isLogonHigh } from '../BetSlip/LoginHooks';
import { BottomMenuIcon } from './MenuCommon';
import { callWebTV } from '../Football/Common/MatchBase';
import { isLiveRacingWhitelisted } from '../Racing/Common/Common';
import { isEarlySettlementEanbled } from '../Football/Common/Common';
import { useMobileBottomMenuQuery } from '../Home/Data/SitecoreDataHooks';
import { MyBetSlipContext } from '../BetSlip/BetSlipHooks';

const MobileBottomMenu = () => {
    const { t, i18n } = useTranslation();
    const { racingPages, footballPages, marksixPages } = useContext(ConfigContext);
    const { betBaskets } = useContext(MyBetSlipContext);
    const globalContext = useContext(GlobalContext);

    const [pageConfig, setPageConfig] = useState([]);
    const [cashoutInProcess, setCashoutInProcess] = useState(false);
    const [yellowCircleLocalLeft, setYellowCircleLocalLeft] = useState(-1);
    const [earlySettlementLocalLeft, setEarlySettlementLocalLeft] = useState(-1);
    // const [menuArrLength,setMenuArrLength]=useState(0);
    const [y, setY] = useState(window.scrollY);
    const [menuLoading, menuData] = useMobileBottomMenuQuery();
    const product = globalContext.globalState.product;
    useEffect(() => {
        if (product == null || globalContext.globalState.betType == null || !racingPages || !footballPages || !marksixPages) {
            setPageConfig(null)
            return;
        }
        let config = null;
        switch (product.toUpperCase()) {
            case 'RACING':
                config = racingPages[globalContext.globalState.betType]
                break;
            case 'FOOTBALL':
                config = footballPages[globalContext.globalState.betType]
                break;
            case 'MARKSIX':
                config = marksixPages[globalContext.globalState.betType]
                break;
            default:
                break;
        }
        setPageConfig(config)

    }, [globalContext.globalState.betType,globalContext.globalState.product, racingPages, footballPages, marksixPages])

    const getBetlineCount = () => {
        return betBaskets.reduce((p, c) => {
            return [...p, ...c];
        }, []).length;
    }

    const getMenus = () => {
        let menus = []
        switch (product.toUpperCase()) {
            case 'RACING':
                menus = menuData.filter(x => x.key === "Racing Menu options")[0]?.children.filter(x => x.itemEnable.value === "1") || [];
                break
            case 'FOOTBALL':
                menus = menuData.filter(x => x.key === "Football Menu options")[0]?.children.filter(x => x.itemEnable.value === "1") || [];
                break
            case 'MARKSIX':
                menus = menuData.filter(x => x.key === "Mark Six Menu options")[0]?.children.filter(x => x.itemEnable.value === "1") || [];
                break
            default:
                menus = menuData.filter(x => x.key === "eWin Menu options")[0]?.children.filter(x => x.itemEnable.value === "1") || []
                break;
        }

        // setMenuArrLength(menus.length)
        return menus;
    }

    const toProduct = (menu) => {
        switch (menu.key) {
            case "Int_Racing":
                return "racing";
            case "Int_Football":
                return "football";
            case "Int_Mark Six":
                return "marksix";
        }
        return menu.key;
    }


    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            if (y > window.scrollY + 20 || window.scrollY < 50) {
                globalContext.setIsShowMobileBottomMenu(true);
                if (document.querySelector('.multi-leg-slide-bottom-node') != null)
                    document.querySelector('.multi-leg-slide-bottom-node').style.bottom = "162px";
            } else if (y < window.scrollY) {
                globalContext.setIsShowMobileBottomMenu(false);
                if (document.querySelector('.multi-leg-slide-bottom-node') != null)
                    document.querySelector('.multi-leg-slide-bottom-node').style.bottom = "12px";
            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    useEffect(() => {
        if (globalContext.cashoutInProcess) {
            setCashoutInProcess(true)
        } else {
            setCashoutInProcess(false)
        }
    }, [globalContext.cashoutInProcess]);

    const onClickHandler = (item) => {
        globalContext.setIsShowMobileBottomMenu(false);
        document.getElementById('root').scrollIntoView();
        const Int_Football = 'Int_Football';
        const Int_Results = 'Int_Results'
        if([Int_Football, Int_Results].includes(item?.key)){
            globalContext.setJumpFbHomePage(Math.random());
        }
    }

    const handleClickBetslipBtn = () => {

        globalContext.setIsShowMobileBottomMenu(false);
        document.getElementById('betslip-container').style.display = 'inherit';
        document.getElementById("divBetSlipNormal").style.left = "calc(100vw - 90vw)";
        document.getElementById("betslip-over-lay").style.left = "0";
        document.body.style.overflow = "hidden";
    }

    let betlineCnt = getBetlineCount();
    const hasHeaderContent = pageConfig?.showAlupBtn || !(!(isLogonHigh()) || !pageConfig?.showLiveRacingBtn) || !(!isLogonHigh() || !pageConfig?.showWebTVIcon) || pageConfig?.showMobileFooterAddToSlip
    let className = ''
    // debugger
    if (hasHeaderContent) {
        className = globalContext.isShowMobileBottomMenu ? 'menu-up' : 'menu-down'
    } else {
        className = globalContext.isShowMobileBottomMenu ? 'menu-show' : 'menu-hidden'
    }
    const cashoutDataLength = globalContext.esHook.getEsData()?.length

    const newMenus = getMenus().filter(menu => {
        return window.sitecoreVars.Int_Early_Settlement != menu.key || isEarlySettlementEanbled(globalContext)
    })

    const menuSetProps = {
        globalContext, toProduct, cashoutInProcess, handleClickBetslipBtn,
        setYellowCircleLocalLeft, getMenus,
        newMenus,
        setEarlySettlementLocalLeft, onClickHandler
    }
    let divBottom = ''
    let safeAreaHeight = ''
    if (hasHeaderContent) {
        divBottom = 'safe-area-bottom'
        safeAreaHeight = 'safe-area-height'
    } else {
        if (globalContext.isShowMobileBottomMenu) {
            divBottom = 'safe-area-bottom'
            safeAreaHeight = 'safe-area-height'
        }
    }
    const yellowCirclePercentWidth= 1 / (newMenus?.length || 1) * 100;

    const showWebTVIcon =  isLogonHigh() && sessionStorage.getItem('webTV')==='true' && pageConfig?.showWebTVIcon

    const pCardTier =  window.globalConfig.IS_IB? sessionStorage.getItem('PCardTier') : sessionStorage.getItem('login_priorityCardTier');
    const showELVAIcon =  pageConfig?.showLiveRacingBtn && isLogonHigh() && isLiveRacingWhitelisted() && (pCardTier ||  window.globalConfig.IS_SPEEDBET)
    return <div>
        <div className={`bottom-menu ${className} ${divBottom}`}>
            <div id='footbal-allup-detail-cal-panel'></div>
            <header>
                {
                    pageConfig !== null ?
                        <>
                            <div className="left">
                                {<div className={`allupbtn-container-m ${pageConfig?.showAlupBtn ? '' : 'hide'}`}><AllupCheckbox val={t('LB_RC_ALLUP')} id="allupBtn" /></div>}
                                {<div className={`live-racing-icon-m cursor-pointer ${!showELVAIcon ? 'hide' : ''}`} onClick={()=> { handleClickRacingLive(i18n.language) }} />}
                                {<div className={`live-football-icon cursor-pointer ${!showWebTVIcon ? 'hide' : ''}`} onClick={() => { if (showWebTVIcon) { callWebTV(t); } }} />}
                            </div>
                            {pageConfig?.showMobileFooterAddToSlip ? <AddSlipBtn enable={true} product={product} /> : <div></div>}
                        </> : null
                }

            </header>
            {!menuLoading ? <div className='botMenuNavigBox'>
                <MenuSet {...menuSetProps} />
                {betlineCnt > 0 && yellowCircleLocalLeft > -1 && <div className="yellowCircleHL" style={{ left: `${yellowCircleLocalLeft * yellowCirclePercentWidth + yellowCirclePercentWidth / 2}%` }}>{betlineCnt}</div>}
                {product.toUpperCase() === 'FOOTBALL' && isLogonHigh() && cashoutDataLength > 0 && earlySettlementLocalLeft > -1 && <div className={`yellowCircleHL${cashoutDataLength > 99 ? ` moreThan99` : ''}`} style={{ left: `${earlySettlementLocalLeft * yellowCirclePercentWidth + yellowCirclePercentWidth / 2}%` }}>{cashoutDataLength > 99 ? `99+` : cashoutDataLength}</div>}
            </div> : null}
        </div>
        <div className={`${safeAreaHeight}`}></div>
    </div>;
}
export default MobileBottomMenu;

const MenuSet = (props) => {
    const globalContext = useContext(GlobalContext);
    const [curItemIndex, setCurItemIndex] = useState(-1);
    const { newMenus } = props;
    useLayoutEffect(() => {
        let active = -1;
        if (!['/en/home', '/ch/home'].includes(location.pathname)) {
            const product = globalContext.globalState.product;
            const Int_Racing = 'Int_Racing';
            const Int_Football = 'Int_Football';
            const Int_MarkSix = 'Int_Mark Six';
            const ProductNameList = [Int_Racing, Int_Football, Int_MarkSix];
            const isRacingPath = product === 'racing' ? '/' : '';
            newMenus?.map((item, index) => {
                const { BOTTOMMENU_BETSLIP, BOTTOMMENU_MORE } = window.sitecoreVars;
                const isNormalPage=![BOTTOMMENU_BETSLIP, BOTTOMMENU_MORE, ...ProductNameList].includes(item.key) && location.pathname.search(`${item.itemPage.value}${isRacingPath}`) > -1;
                const isFbResPage=['Int_Results'].includes(item.key) && product === 'football' && location.pathname.search('res')>14;
                if (isNormalPage || isFbResPage) {
                    active = index;
                }
            })
            if (active === -1) {
                newMenus?.map((item, index) => {
                    if (ProductNameList.includes(item.key)) {
                        active = index;
                    }
                })
            }
        }
        setCurItemIndex(active)
    }, [location.pathname, newMenus])
    return <div className='botMenuNavigContain'>
        {newMenus.map((menu, index) => {
            return <Item menu={menu} index={index} key={menu.key} curProductItems={newMenus} curItemIndex={curItemIndex} setCurItemIndex={setCurItemIndex}  {...props} />
        })}
    </div>
}

const Item = ({ menu, index, globalContext, toProduct, cashoutInProcess, handleClickBetslipBtn, setYellowCircleLocalLeft, setEarlySettlementLocalLeft, onClickHandler, curItemIndex }) => {
    const product = globalContext.globalState.product;
    let active = curItemIndex === index ? 'active' : '';
    const cashoutIP = cashoutInProcess ? 'cashoutInProcess' : '';
    if (window.sitecoreVars.BOTTOMMENU_MORE === menu.key) {
        return <MoreDialog active={active} cashoutIP={cashoutIP} handleClickBetslipBtn={handleClickBetslipBtn} iconMenu={menu} product={product} />
    } else if (window.sitecoreVars.BOTTOMMENU_BETSLIP === menu.key) {
        setYellowCircleLocalLeft(index)
        return <a id={`bottom-menu-${menu.key}`} role='button' aria-label={menu.itemName.value} className={`bottomMenuTab ${active} ${cashoutIP}`} onClick={handleClickBetslipBtn} >
            <div className="bottom-menu-icon-container">
                <div className="bottom-menu-icon">
                    <BottomMenuIcon menu={menu} />
                </div>
                <div>{menu.itemName.value}</div>
            </div>
        </a>;
    } else {
        let link = menu.itemLink.value;
        let target = "_self";
        if (menu.itemRedirection.value === window.sitecoreVars.REDIRECT_JCBW_PAGE) {
            link = menu.itemPage.value;
        }
        else {
            target = "_blank";
        }

        if (window.sitecoreVars.Int_Early_Settlement === menu.key) {
            setEarlySettlementLocalLeft(index)
        }

        return <Link role='button' to={link} id={`bottom-menu-${menu.key.replace(' ', '_')}`} className={`bottomMenuTab  bottom-menu-link ${active} ${cashoutIP}`} target={target} onClick={() => onClickHandler(menu)}>
            <div className="bottom-menu-icon-container">
                <div className="bottom-menu-icon"><BottomMenuIcon menu={menu} /></div>
                <div>{menu.itemName.value}</div>
            </div>
        </Link>;
    }
}