import React, { useCallback, useContext, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { SingleMatchTable } from '../Common/MatchTable';
import CashOutTable from '../Common/CashOutTable';
import { MatchDropdown, Remark, MatchInfo, getFoPool } from '../Common/MatchBase';
import { isLogonHigh } from '../../BetSlip/LoginHooks';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { hasMatchInfoUpdate, MatchUpdateMsg } from '../Common/Common';
import { useWindowSize } from '../../Common/CommonHooks';
import getDistanceOfPageTop from '../../Common/utils/getDistanceOfPageTop';
import { FBMatchTypeTab, allMatchTimeTypes, configKeyMap } from '../../Common/ui-components/FBMatchTypeTab';
import { scrollTo } from '../../Common/GlobalFunc';

const INPLAY_ALL = () => {
    const { t } = useTranslation();
    const context = useContext(FootballContext);
    const globalContext = useContext(GlobalContext);
    const singleMatch = context.content.matchData.matches?.[0];
    const AllOddsOrder = context.content.config.AllOddsOrder;
    const { isMobileSize } = useWindowSize()
    const [matchTimeType, setMatchTimeType] = useState(allMatchTimeTypes?.[0]?.key)

    const onChangeMatchTimeType = (value) =>{
        setMatchTimeType(value)
        // if(value == 'ALL') return
        // const isFT = value == 'FT'
        // const curPools = isFT ? window.fbConfig.FullTimePools : window.fbConfig.FirstHalfPools
        // context.contentRef.current.oddsSelection.forEach(item =>{
        //     if(!curPools.includes(item.oddsType)){
        //         item.checked = false
        //     }
        // })
        // context.setContent({...context.contentRef.current})
    }

    useLayoutEffect(() => {
        if ( window.location.hash!='' ) {
            setTimeout(() => {
                if(isMobileSize) {
                    window.scrollTo(0, getDistanceOfPageTop(document.querySelector(".coupon" + window.location.hash.substring(1))) - 60 - 156);
                } else {
                    scrollTo(0, (document.querySelector(".coupon" + window.location.hash.substring(1))?.offsetTop))
                }
            }, 500);
        } else {
            scrollTo(0, 0)
        }
    }, [window.location.hash])

    const MatchInfoContent = useCallback(({ singleMatch, context }) => {
        return <MatchInfo context={context} singleMatch={singleMatch} />
    }, [])

    const AllMatchTable = useCallback(({ singleMatch, matchTimeType, context, t }) => {
        if(hasMatchInfoUpdate(context.content)){
            return <MatchUpdateMsg/>
        }
        let curAllOddsOrder = AllOddsOrder;
        if(matchTimeType != 'ALL'){
            const pools = window.fbConfig[configKeyMap[matchTimeType]]
            curAllOddsOrder = curAllOddsOrder.filter(i => pools.includes(i))
        }
        return curAllOddsOrder.map(betType => {
            let foPool = getFoPool(singleMatch, betType, null);
            return foPool!=null ? <SingleMatchTable betType={betType} match={singleMatch} foPool={foPool} key={betType}/> : null;
        })
    }, [])

    return (
        <>
            <section className='INPLAY_ALL'>
                {singleMatch ? <>
                    {!context.state.windowSize.isMobileSize && <>
                        <div className='match-dropdown-container ddNoRSpace'><MatchDropdown singleMatch={singleMatch} /></div>
                        <MatchInfoContent singleMatch={singleMatch} context={context}/>
                    </>}
                    {/*globalContext.globalState.commonCtrl.earlySettlementEnabled && isLogonHigh() && <CashOutTable match={singleMatch} />*/}
                    {globalContext.globalState.commonCtrl.fchloFilterDisplayEnabled &&<FBMatchTypeTab allMatchTimeTypes={allMatchTimeTypes} selected={matchTimeType} onChange={onChangeMatchTimeType} match={singleMatch} context={context}/>}
                    <AllMatchTable matchTimeType={matchTimeType}  singleMatch={singleMatch}  context={context} t={t}/>
                </> : <MatchUpdateMsg/>}
            </section>
            <div className='mobileMarginLR'>
                <Remark betType={'INPLAY_ALL'} />
            </div>
        </>
    )
}
export default INPLAY_ALL;