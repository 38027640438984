import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../Common/CommonHooks';
import AddSlipBtn from '../Common/AddSlipBtn';
import OddsTable from '../Common/RCOddsTableCompact';
import OddsDropLegend from '../Common/OddsDropLegend';
import InvestmentCalculator from '../Common/InvestmentCalculator';
import Investment from '../Common/Investment';
import { getComingleMsgs, getMaxRunnerSize } from '../Common/Common';
import { isLocalMeeting } from '../../Common/home-common';
import MultiLegSlider from '../Common/MultiLegSlider';
import IWNOddsTable from '../Common/IWNOddsTable';
import { RacingContext } from '../../Home/RacingPage';
import {MobileCurrentOdds} from '../Common/CurrentOdds';
import Loading from '../../Common/ui-components/Loading';
import { isStartSellpmPool } from '../Common/Common';

const OddsDBL = (props) => {
    const { t, i18n} = useTranslation();
    const { isMobileSize } = useWindowSize();
    const context = useContext(RacingContext);

    const RCColumns = {
        no: true,
        banker1: false,
        banker2: false,
        banker3: false,
        leg: true,
        horseName: true,
        rcNoteM: false,
        win: true,
        place: false,
        field: true,
        banker1F: false,
        banker2F: false,
        banker3F: false
    }

    const RCColumnLbls = {
        leg: t('LB_RC_ODDS_SEL')
    }

    if (context == null) {
        return <Loading/>
    } else {
        const mtg = context.content.meeting;
        let pool =  mtg.pmPools.filter((data) => data.leg.races[0] == context.content.raceNo && ["DBLPre", "DBL"].includes(data.oddsType))[0];
        if ( pool==null )
            return null;

        let legList = pool.leg.races;
        const defTableSize = Math.max(14, getMaxRunnerSize(context, legList, 0, 3));
        let isStartSell = isStartSellpmPool(pool);//pool.status==="START_SELL";

        let tmpMsgs = getComingleMsgs(mtg, context.content.page, context.content.raceNo);
        let comingleMsg1 = [];
        let comingleMsg2 = [];
        let noteCnt = 1;
        if (tmpMsgs != null) {
            let comingleLang = i18n.language == "en" ? "E" : "C";
            if (tmpMsgs['NOTE1_' + comingleLang] != '')
                comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}{noteCnt++}: </span>{tmpMsgs['NOTE1_' + comingleLang]}</div>);
            if (tmpMsgs['NOTE2_' + comingleLang] != '')
                comingleMsg1.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}{noteCnt++}: </span>{tmpMsgs['NOTE2_' + comingleLang]}</div>);
            if (tmpMsgs['NOTE3_' + comingleLang] != '')
                comingleMsg2.push(<div className="rc-comingle-remarks"><span style={{ fontWeight: "bold" }}>{t('LB_RC_COMINGLE_NOTE')}{noteCnt++}: </span>{tmpMsgs['NOTE3_' + comingleLang]}</div>);
        }

        return (
            <section className={context.content.page}>
                <Investment betType={context.content.page} />
                <InvestmentCalculator  className='border-radius-down' enable={true} />
                <MobileCurrentOdds />
                <div className={isLocalMeeting(context.content.venue) ? "marginTop12": "dblTableTop"}><IWNOddsTable enableQuickBet={!isMobileSize && isStartSell} isStartSell={isStartSell} msg={comingleMsg2} legList={legList}/></div>
                <MultiLegSlider cnt={legList.length} className="multi-leg-2x-table">
                    {legList.map((_cItem, _cIndex) => {
                        return <OddsTable betType={context.content.page} columns={RCColumns} lbl={RCColumnLbls}
                            firstRace={legList[0]} raceNo={_cItem} startCnt={1}
                            selectedBetType={context.content.page} showRaceHeader="true" defTableSize={defTableSize} key={_cItem}/>
                    })}
                </MultiLegSlider>
                {comingleMsg1}
                {!isLocalMeeting(context.content.venue) ? <div className="dblTableBtm"><IWNOddsTable enableQuickBet={!isMobileSize && isStartSell} isStartSell={isStartSell} msg={comingleMsg2} legList={legList}/></div> : null}
                <OddsDropLegend />
                <div className="multi-leg-footer-betSlip">
                    <div><AddSlipBtn /></div>
                </div>
                <InvestmentCalculator  className='border-radius-down' enable={!context.windowSize.isMobileSize} />
            </section>
        );
    }
}
export default OddsDBL;