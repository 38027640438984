import React, { useState, useEffect, useRef, useContext, useCallback, useLayoutEffect } from 'react';
import { Button, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { ConfigContext } from '../Common/ConfigHelper';
import { GlobalContext } from '../../contexts/GlobalContext';
import tb_icon from '../../info/Include/images/trustbrowser.svg';
import { useWindowSize } from '../Common/CommonHooks';
import { WATrackerTrackClickEvent } from '../Common/utils/wa';


export const TrustBrowser = ({
    showMsg = false,
    FR_TurstBrowser = FR_TurstBrowser,
    isShowTrustBrowser = isShowTrustBrowser,
    setIsShowTrustBrowser = setIsShowTrustBrowser
}) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const { betslipMenu } = useContext(ConfigContext);
    const globalContext = useContext(GlobalContext);
    const [menuInfo, setMenuInfo] = useState(betslipMenu);

    const { isMobileSize } = useWindowSize();
    let mobileStyle = isMobileSize ? 'is-mobile' : 'is-desktop';
    useEffect(() => {
        if (isMobileSize) {
            mobileStyle = 'is-mobile'
        } else {
            mobileStyle = 'is-desktop';
        }
    }, [isMobileSize]);
    
    const handleTrustBtn = async () => {
        WATrackerTrackClickEvent('eWin_CIAM_S2-Trust-Yes_whitelistWithMobile');
        // trust the browser
        // close overlay modal
        await FR_TurstBrowser(true);
        setIsShowTrustBrowser(false);
        
    };

    const handleDontTrustBtn = async () => {
        WATrackerTrackClickEvent('eWin_CIAM_S2-Trust-No_whitelistWithMobile');
        await FR_TurstBrowser(false);
        setIsShowTrustBrowser(false);
        // close overlay modal
    };

    return (
        <Popup open={showMsg} closeOnDocumentClick={false} className={`comingSoonLogOutDialog logout-succ-msg trustbrowser ${mobileStyle}`}>
            <div className={`trustbrowser-container`} id='popup-trustbrowser-container'>
                <div className='trustbrowser-title-and-description'>
                    <div className="trustbrowser-title">{t('LB_TRUSTED_BROWSER_TITLE')}</div>
                    <div className="trustbrowser-image">
                        <img src={tb_icon} alt="trust browser img" />
                    </div>
                    <div className="trustbrowser-message" dangerouslySetInnerHTML={{ __html: t('LB_TRUSTED_BROWSER_MESSAGE') }}></div>
                </div>
                
                <div className="trustbrowser-btn-group">
                    <div className="trustbrowser-btn-donttrust" onClick={handleDontTrustBtn}>{t('LB_TRUSTED_BROWSER_DONT_TRUST')}</div>
                    <div className="trustbrowser-btn-trust" onClick={handleTrustBtn}>{t('LB_TRUSTED_BROWSER_TRUST')}</div>
                </div>

            </div>
        </Popup>
    );
};